import type { StateCreator } from "zustand";

import type { Coupon } from "@/domains/billing/models";

export interface CheckoutSlice {
  coupon?: Coupon;
  setCoupon: (coupon: Coupon) => void;
}

export const createCheckoutSlice: StateCreator<
  CheckoutSlice,
  [],
  [],
  CheckoutSlice
> = (set) => ({
  setCoupon: (coupon) => set({ coupon }),
});
