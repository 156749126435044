import type { ComponentPropsWithoutRef } from "react";
import type { Control } from "react-hook-form";
import { t } from "ttag";

import type { UserAttributes } from "@/shared/models";
import { GENDER } from "@/shared/models";
import { Form, GenderIcon, Select } from "@/ui";

interface FieldValue {
  gender: UserAttributes["gender"];
}

type GenderControl<T> = T extends FieldValue ? Control<T, "gender"> : never;

type GenderFieldProps<T extends FieldValue> = ComponentPropsWithoutRef<
  typeof Form.Item
> & {
  control: GenderControl<T>;
  onGenderChange?: (gender: UserAttributes["gender"]) => void;
};

export const GenderField = <T extends FieldValue>({
  control,
  onGenderChange,
  ...props
}: GenderFieldProps<T>) => {
  const OPTIONS = [
    { value: GENDER.MALE, label: t`Male` },
    { value: GENDER.FEMALE, label: t`Female` },
  ] as const;

  return (
    <Form.Field
      control={control}
      name="gender"
      render={({ field: { onChange, ...field } }) => (
        <Form.Item {...props}>
          <Form.Label aria-required>{t`Sex assigned at birth`}</Form.Label>
          <Form.Control>
            <Select
              options={OPTIONS}
              placeholder={t`Select a sex`}
              onChange={(value) => {
                onChange(value);
                onGenderChange?.(value);
              }}
              leftIcon={<GenderIcon />}
              {...field}
            />
          </Form.Control>
          <Form.Message compact={false} />
        </Form.Item>
      )}
    />
  );
};
