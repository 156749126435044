import { t } from "ttag";

import { useBoolean } from "@/hooks";
import { ErrorModal } from "@/modals";
import { usePortalUserStore } from "@/stores";
import { CalendarFixedIconMono, Dialog, RadioGroupCards } from "@/ui";
import { useDelayedRefillRequests } from "../hooks";
import { DelayedRefillRequestsSelection } from "./DelayedRefillRequestsSelection";

export const PendingRefillRequestModal = () => {
  const askForMedicationStatus = usePortalUserStore((state) =>
    Boolean(state.portalUser?.askForMedicationStatus),
  );

  const { value: show, setFalse: onClose } = useBoolean(askForMedicationStatus);

  const {
    data: delayedRefillRequests,
    isSuccess,
    isLoading,
    isError,
  } = useDelayedRefillRequests();

  if (isError) {
    return <ErrorModal show={show} onClose={onClose} />;
  }

  return (
    <Dialog.Root open={show}>
      <Dialog.Content size="modal" withCloseButton={false}>
        <div className="flex h-full flex-col">
          <Dialog.Header className="pb-6">
            <Dialog.Icon>
              <CalendarFixedIconMono color="salmon" />
            </Dialog.Icon>

            <Dialog.Title>{t`Have you received your medication?`}</Dialog.Title>

            <Dialog.Description>
              {t`We haven't seen any recent activity on your refill request. Please check which medications you have received, and we'll request a follow-up for the rest.`}
            </Dialog.Description>
          </Dialog.Header>

          {isLoading && <RadioGroupCards.Skeleton />}

          {isSuccess && (
            <DelayedRefillRequestsSelection
              refillRequests={delayedRefillRequests}
              onSuccess={onClose}
            />
          )}
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
