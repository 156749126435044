import type { ComponentPropsWithoutRef } from "react";
import { useWatch } from "react-hook-form";
import type { Control } from "react-hook-form";
import { t } from "ttag";

import type { UserAttributes } from "@/shared/models";
import { AGE_GROUP, GENDER } from "@/shared/models";
import { Form, RadioGroupCards } from "@/ui";

const getAgeGroupOptions = (gender: UserAttributes["gender"]) => {
  switch (gender) {
    case GENDER.MALE:
      return [
        {
          value: AGE_GROUP.YOUNGER_THAN_18,
          label: t`Younger than 18`,
        },
        {
          value: AGE_GROUP.BETWEEN_18_AND_74,
          label: t`18-74 years`,
        },
        {
          value: AGE_GROUP.OLDER_THAN_74,
          label: t`75 years or older`,
        },
      ];
    case GENDER.FEMALE:
      return [
        {
          value: AGE_GROUP.YOUNGER_THAN_18,
          label: t`Younger than 18`,
        },
        {
          value: AGE_GROUP.BETWEEN_18_AND_49,
          label: t`18-49 years`,
        },
        {
          value: AGE_GROUP.BETWEEN_50_AND_74,
          label: t`50-74 years`,
        },
        {
          value: AGE_GROUP.OLDER_THAN_74,
          label: t`75 years or older`,
        },
      ];
    default:
      return [];
  }
};

type FieldValue = UserAttributes;

type AgeRangeControl<T> = T extends FieldValue
  ? Control<T, "ageRange" | "gender">
  : never;

type AgeRangeFieldProps<T extends FieldValue> = ComponentPropsWithoutRef<
  typeof Form.Item
> & {
  control: AgeRangeControl<T>;
};

export const AgeGroupField = <T extends FieldValue>({
  control,
  ...props
}: AgeRangeFieldProps<T>) => {
  const gender = useWatch({
    control,
    name: "gender",
  });

  const options = getAgeGroupOptions(gender);

  if (!options.length) {
    return null;
  }

  return (
    <Form.Field
      control={control}
      name="ageGroup"
      render={({ field }) => (
        <Form.Item {...props}>
          <Form.Label aria-required>{t`Age range`}</Form.Label>
          <Form.Control>
            <RadioGroupCards.Root
              {...field}
              className="sm:grid-cols-2 lg:flex"
              color="nature"
              onValueChange={field.onChange}
              value={field.value ?? ""}
            >
              {options.map(({ value, label }) => (
                <RadioGroupCards.Item
                  key={value}
                  id={value}
                  value={value}
                  className="flex-row items-center gap-2 lg:grow"
                >
                  <RadioGroupCards.ItemIndicator />
                  <RadioGroupCards.ItemLabel className="font-medium">
                    {label}
                  </RadioGroupCards.ItemLabel>
                </RadioGroupCards.Item>
              ))}
            </RadioGroupCards.Root>
          </Form.Control>
          <Form.Message compact={false} />
        </Form.Item>
      )}
    />
  );
};
