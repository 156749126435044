import { Link } from "react-router-dom";
import { t } from "ttag";

import { EXTERNAL_LINK } from "@/router";
import { FullScreenAlert, HeartsCircleFixedIconMono } from "@/ui";

interface PaymentErrorAlertProps {
  open: boolean;
}

export const PaymentErrorAlert = ({ open }: PaymentErrorAlertProps) => {
  return (
    <FullScreenAlert.Root open={open}>
      <FullScreenAlert.Content>
        <FullScreenAlert.Header>
          <HeartsCircleFixedIconMono className="size-16 md:size-20" />
          <FullScreenAlert.Title>{t`Oops! There was an error`}</FullScreenAlert.Title>
        </FullScreenAlert.Header>

        <FullScreenAlert.Description>{t`Oops! It looks like there's been a hiccup. Not to worry, though! We've already let our technical support team know, and they'll be reaching out to you with a solution. Thanks for your understanding!`}</FullScreenAlert.Description>

        <FullScreenAlert.Footer>
          <FullScreenAlert.Cancel asChild>
            <Link to={EXTERNAL_LINK.HOME}>{t`Back to Fridays`}</Link>
          </FullScreenAlert.Cancel>

          <FullScreenAlert.Action asChild>
            <Link to={EXTERNAL_LINK.FAQ}>{t`Contact support`}</Link>
          </FullScreenAlert.Action>
        </FullScreenAlert.Footer>
      </FullScreenAlert.Content>
    </FullScreenAlert.Root>
  );
};
