import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { t } from "ttag";
import { z } from "zod";

import { FullScreenLoader } from "@/components";
import { useBoolean } from "@/hooks";
import { usePortalUserStore } from "@/stores";
import { Button, Dialog, DocumentAddFixedIconMono, Form } from "@/ui";
import { useSetUserGender } from "../hooks";
import { GENDER } from "../models";
import { GenderField } from "./GenderField";

const formSchema = z.object({
  gender: z.nativeEnum(GENDER),
});

export const MissingGenderModal = () => {
  const askForGender = usePortalUserStore(
    ({ portalUser }) =>
      !portalUser?.gender || !Object.values(GENDER).includes(portalUser.gender),
  );

  const { value: show, setFalse: onClose } = useBoolean(askForGender);

  const setUserGenderMutation = useSetUserGender();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const handleSubmit = form.handleSubmit((values) => {
    void setUserGenderMutation.mutate(values, { onSuccess: onClose });
  });

  return (
    <Dialog.Root open={show}>
      <Dialog.Content size="modal" withCloseButton={false}>
        <Dialog.Header className="pb-6">
          <Dialog.Icon>
            <DocumentAddFixedIconMono color="salmon" />
          </Dialog.Icon>

          <Dialog.Title>{t`Something is missing...`}</Dialog.Title>

          <Dialog.Description>
            {t` Before we get started, we just need one quick detail. Please select your sex at birth to help us personalize your experience.`}
          </Dialog.Description>
        </Dialog.Header>

        <Form.Provider {...form}>
          <form onSubmit={handleSubmit} className="flex flex-col gap-2">
            <GenderField control={form.control} />

            <Button type="submit" size="lg" className="self-center">
              {t`Save to profile`}
            </Button>
          </form>
        </Form.Provider>

        {setUserGenderMutation.isPending && <FullScreenLoader />}
      </Dialog.Content>
    </Dialog.Root>
  );
};
