import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { t } from "ttag";

import { CATEGORY_SLUG } from "@/domains/products/models";
import type { Category } from "@/domains/products/models";
import { Alert, Badge, CheckboxIndicator, ShowcaseCard } from "@/ui";
import { tw } from "@/utils";

type CategoryCardProps = ComponentPropsWithoutRef<
  typeof CheckboxPrimitive.Root
> & {
  category: Category;
};

export const CategoryCard = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  CategoryCardProps
>(({ category, checked, ...props }, ref) => {
  const hasDisclaimer = category.slug === CATEGORY_SLUG.HAIR_GROWTH && checked;

  return (
    <CheckboxPrimitive.Root ref={ref} {...props}>
      <ShowcaseCard.Root>
        <ShowcaseCard.Header className="flex-row items-center gap-4">
          <div className="flex grow items-center gap-2">
            <ShowcaseCard.Title>{category.name}</ShowcaseCard.Title>
            {category.isNew && (
              <Badge
                rounded="3xl"
                className={tw(
                  "border-nature-09 bg-nature-09 text-brown-01",
                  checked && "border-transparent bg-nature-03 text-nature-10",
                )}
              >
                {t`New!`}
              </Badge>
            )}
          </div>

          <CheckboxIndicator size="md" />
        </ShowcaseCard.Header>

        <ShowcaseCard.Content>
          <ShowcaseCard.Description>
            {category.description}
          </ShowcaseCard.Description>
        </ShowcaseCard.Content>

        {hasDisclaimer && (
          <ShowcaseCard.Footer className="w-full">
            <Alert className="bg-nature-03 text-xs">
              {t`Our customized hair formulations are compounded products that
                have not been evaluated or approved by the FDA. The FDA does not
                assess the safety or effectiveness of compounded medications.
                Individual outcomes may vary.`}
            </Alert>
          </ShowcaseCard.Footer>
        )}
      </ShowcaseCard.Root>
    </CheckboxPrimitive.Root>
  );
});
CategoryCard.displayName = "CategoryCard.Item";
