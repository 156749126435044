import type { ServiceResponse } from "@/api";
import { privateAPI } from "@/api";
import type { Question } from "@/shared/models";

export const getInitialQuestion = async (bmi: number) => {
  const { data } = await privateAPI.get<ServiceResponse<Question>>(
    "/answer-groups",
    { params: { bmi } },
  );

  return data.data;
};

export const getQuestion = async (questionId: Question["id"]) => {
  const { data } = await privateAPI.get<ServiceResponse<Question>>(
    `/answer-groups/${questionId}`,
  );

  return data.data;
};
