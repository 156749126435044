import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import { skipToken } from "@tanstack/react-query";

import type { UserAttributes } from "@/shared/models";
import type { Category } from "../models";
import {
  getPatientCategories,
  getPatientProductsByCategory,
  getProductCategories,
  getProductsByCategory,
} from "./category";
import { getAvailableProductsToSubscribe } from "./subscription";

export const productsQueriesStore = createQueryKeyStore({
  products: {
    category: {
      queryKey: null,
      contextQueries: {
        list: (params?: UserAttributes) => ({
          queryKey: [{ params }],
          queryFn: params
            ? () => getProductCategories(params)
            : (skipToken as never),
        }),

        products: (categoryId?: Category["id"], params?: UserAttributes) => ({
          queryKey: [categoryId, { params }],
          queryFn:
            categoryId && params
              ? () => getProductsByCategory(categoryId, params)
              : (skipToken as never),
        }),
      },
    },

    patient: {
      queryKey: null,
      contextQueries: {
        categories: {
          queryKey: null,
          queryFn: getPatientCategories,
        },

        products: (categoryId?: Category["id"]) => ({
          queryKey: [categoryId],
          queryFn: categoryId
            ? () => getPatientProductsByCategory(categoryId)
            : (skipToken as never),
        }),
      },
    },

    available: {
      queryKey: null,
      queryFn: getAvailableProductsToSubscribe,
    },
  },
});
