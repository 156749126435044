import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getUpcomingConsultationsQuery } from "@/api";
import {
  CalendarFixedIconMono,
  CardPrimitive as Card,
  CardSkeleton,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  EmptyState,
  LegacyCarousel,
} from "@/ui";
import { ConsultationCard } from "./ConsultationCard";

export const UpcomingConsultations = () => {
  const {
    data: upcomingConsultations,
    isLoading,
    isSuccess,
  } = useQuery(getUpcomingConsultationsQuery());

  return (
    <Card.Root className="max-w-[calc(100vw-3rem)] bg-brown-02 pr-0 md:max-w-[calc(100vw-24.75rem)]">
      <Card.Title>{t`Upcoming Video Consultations`}</Card.Title>

      <Card.Content>
        {isLoading && (
          <CardSkeleton className="h-48 w-4/5 border border-brown-05 bg-transparent xl:w-3/5" />
        )}

        {isSuccess && !upcomingConsultations.length && (
          <EmptyState.Root className="mr-4 border-brown-02">
            <CalendarFixedIconMono className="h-24" />
            <EmptyState.Description>
              {t`There are no upcoming consultations yet`}
            </EmptyState.Description>
          </EmptyState.Root>
        )}

        {!!upcomingConsultations?.length && (
          <LegacyCarousel
            opts={{
              align: "end",
            }}
          >
            <CarouselContent className="px-1.5 py-1.5">
              {upcomingConsultations.map((consultation, idx) => (
                <CarouselItem
                  key={`upcoming-consultation-${consultation.id}`}
                  className="max-w-full last:pr-4 xl:basis-3/5"
                >
                  <ConsultationCard
                    consultation={consultation}
                    isFirstConsultation={!idx}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
            <div className="hidden md:flex">
              <CarouselPrevious />
              <CarouselNext className="-translate-x-full" />
            </div>
          </LegacyCarousel>
        )}
      </Card.Content>
    </Card.Root>
  );
};
