import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import { PORTAL_DOMAIN } from "@/api";
import { errorToast, useToastStore } from "@/ui";
import { setUserGender } from "../api";

export const useSetUserGender = () => {
  const pushToast = useToastStore((state) => state.pushToast);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: setUserGender,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [PORTAL_DOMAIN] });

      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Gender successfully updated!`,
      });
    },
    onError: errorToast,
  });
};
