import { Link, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ONBOARDING_ROUTE } from "@/domains/onboarding/router";
import { useOnboardingStore } from "@/domains/onboarding/stores";
import type { Category } from "@/domains/products/models";
import { EXTERNAL_LINK } from "@/router";
import { Button, Dialog, ShieldWarningIconMono } from "@/ui";

interface MissingProductModalProps {
  open: boolean;
  onClose: () => void;
  onKeepBrowsing: (categoryId: Category["id"]) => void;
}

export const MissingProductModal = ({
  open,
  onClose,
  onKeepBrowsing,
}: MissingProductModalProps) => {
  const selectedCategories = useOnboardingStore(
    (state) => state.selectedCategories,
  );
  const categoriesWithoutProduct = selectedCategories.filter(
    (category) => !category.selectedProduct,
  );

  const firstCategoryId = categoriesWithoutProduct[0]?.id;

  const navigate = useNavigate();

  if (!firstCategoryId) {
    return null;
  }

  const handleKeepBrowsing = () => {
    onKeepBrowsing(firstCategoryId);
    onClose();
  };

  const handleContinueCheckout = () => {
    navigate(ONBOARDING_ROUTE.TREATMENT_SUMMARY);
  };

  const actions =
    categoriesWithoutProduct.length === selectedCategories.length ? (
      <>
        <Button variant="secondary" size="lg" asChild>
          <Link to={EXTERNAL_LINK.HOME}> {t`Back to Home`}</Link>
        </Button>
        <Button onClick={handleKeepBrowsing} size="lg">
          {t`Keep browsing`}
        </Button>
      </>
    ) : (
      <>
        <Button onClick={handleKeepBrowsing} variant="secondary" size="lg">
          {t`Keep browsing`}
        </Button>
        <Button
          onClick={handleContinueCheckout}
          form="create-entry-form"
          size="lg"
        >
          {t`Continue to Checkout`}
        </Button>
      </>
    );

  const missingDescription =
    categoriesWithoutProduct.length === selectedCategories.length ? (
      <>
        {t`Looks like you haven't picked any products.`}
        <br />
        {t`Please choose at least one to continue your health journey. You can always make changes at checkout!`}
      </>
    ) : (
      <>
        {t`Looks like you haven't picked a product for `}
        <strong>
          {categoriesWithoutProduct.map((category) => category.name).join(",")}
        </strong>
        .
        <br />
        {t`Please choose a treatment to continue your health journey. You can always make changes at checkout!`}
      </>
    );

  return (
    <Dialog.Root open={open}>
      <Dialog.Content size="modal" withCloseButton={false}>
        <div className="flex h-full flex-col gap-6">
          <Dialog.Header>
            <Dialog.Icon>
              <ShieldWarningIconMono />
            </Dialog.Icon>
            <Dialog.Title>{t`Missing something?`}</Dialog.Title>

            <Dialog.Description>{missingDescription}</Dialog.Description>
          </Dialog.Header>

          <Dialog.Footer variant="sticky">{actions}</Dialog.Footer>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
