import { t } from "ttag";
import { z } from "zod";

import { getOnboardingUserSchema } from "@/domains/onboarding/models";
import {
  categorySchema,
  priceSchema,
  productSchema,
} from "@/domains/products/models";
import {
  DISCOUNT_DURATION,
  DISCOUNT_TYPE,
  DURATION_IN_MONTHS,
  INACTIVE_STATUS,
  SUBSCRIPTION_STATUS,
} from "./constants";

const durationInMonthsSchema = z.nativeEnum(DURATION_IN_MONTHS);

export const couponSchema = z.object({
  code: z.string(),
  isValid: z.boolean(),
  duration: z.nativeEnum(DISCOUNT_DURATION),
  type: z.nativeEnum(DISCOUNT_TYPE),
  amountOff: z.number(),
  percentOff: z.number(),
  durationInMonths: durationInMonthsSchema.nullable(),
  priceAfterDiscount: z.number(),
});

/**
 * @deprecate Legacy subscription schema
 */
export const getPlanSchema = () =>
  z.object({
    name: z.string(),
    subscriptionPriceId: z.string(),
    price: z.number(),
    features: z.array(z.string()),
    isCompound: z.boolean(),
    description: z.string().optional(),
    disclaimer: z.string().optional(),
    thumbnail: z.string().url(),
    discount: couponSchema.optional(),
    durationInMonths: durationInMonthsSchema,
    priceId: z.number(),
  });

export const getUserSubscriptionSchema = () =>
  z.object({
    currentPeriodPlan: getPlanSchema().nullable(),
    nextPeriodPlan: getPlanSchema().nullable(),
    requestedPlanChange: getPlanSchema().nullable(),
  });

export const getPlansParamsSchema = () =>
  z.object({
    durationInMonths: durationInMonthsSchema,
    couponCode: z.string().optional(),
  });

export const getCouponParamsSchema = () =>
  z.object({
    subscriptionPriceId: getPlanSchema().shape.subscriptionPriceId,
    couponCode: z.string(),
  });
export const getValidateCouponCodeParamsSchema = () =>
  z.object({
    couponCode: z.string(),
    pricesIds: z.array(getPlanSchema().shape.subscriptionPriceId),
  });

export const getInactiveSubscriptionStatusSchema = () =>
  z.nativeEnum(INACTIVE_STATUS);

export const getSubscriptionStatusSchema = () =>
  z.nativeEnum(SUBSCRIPTION_STATUS);

export const getChangeSubscriptionSchema = () =>
  z.object({
    subscriptionPriceId: z
      .string()
      .min(1, { message: t`Please choose one of the available plans` }),

    hasConsent: z.literal<boolean>(true, {
      errorMap: () => ({
        message: t`To continue, please check the "I agree" box`,
      }),
    }),
  });

export const getCreateSubscriptionParamsSchema = () =>
  z.object({
    userId: z.number(),
    subscriptionPriceId: getPlanSchema().shape.subscriptionPriceId,
    couponCode: z.string().optional(),
  });
export const getCreateSubscriptionResponseSchema = () =>
  z.object({
    subscriptionId: getPlanSchema().shape.subscriptionPriceId,
    clientSecret: z.string(),
  });

export const createdStripeSubscriptionSchema = z.object({
  subscriptionId: priceSchema.shape.subscriptionPriceId,
  clientSecret: z.string(),
});

export const getUpdatePaymentMethodParamsSchema = () =>
  z.object({
    paymentMethodId: z.string(),
    userCode: getOnboardingUserSchema().shape.code,
  });

/**
 * @deprecated The backend will unify the data structure to match the `detailedSubscriptionSchema`.
 * Once that's done, we'll need to update the list of subscriptions that come from the user.
 */
export const subscriptionSchema = z.object({
  id: z.number(),
  status: z.nativeEnum(SUBSCRIPTION_STATUS),
  intakeFormCompleted: z.boolean(),
  price: priceSchema.pick({
    id: true,
    durationInMonths: true,
    subscriptionPriceId: true,
  }),
  product: productSchema.pick({ id: true, name: true, slug: true }).extend({
    category: categorySchema.pick({ id: true, name: true, slug: true }),
  }),
});

const detailedSubscriptionProductSchema = productSchema
  .extend({
    legacy: z.boolean(),
    branded: z.boolean(),
    price: priceSchema.omit({ tags: true }),
  })
  .omit({ tags: true, prices: true });

export const detailedSubscriptionSchema = subscriptionSchema
  .pick({ id: true, status: true, intakeFormCompleted: true })
  .extend({
    currentProduct: detailedSubscriptionProductSchema,
    nextPeriodProduct: detailedSubscriptionProductSchema.nullable(),
    requestedProduct: detailedSubscriptionProductSchema.nullable(),
  });

export const downgradeSubscriptionSchema = subscriptionSchema
  .pick({
    id: true,
  })
  .extend({
    name: productSchema.shape.name,
    slug: productSchema.shape.slug,
    price: priceSchema
      .pick({ id: true, price: true, durationInMonths: true })
      .extend({
        label: z.string(),
      }),
  });
