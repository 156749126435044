import type { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";

import type { Coupon } from "@/domains/billing/models";
import type { CreateUser } from "@/domains/onboarding/models";
import type {
  AnalyticsSlice,
  OnboardingUserWithPhoneNumber,
} from "@/domains/onboarding/stores";
import type {
  CategoryWithSelectedProduct,
  Product,
} from "@/domains/products/models";
import { calculateTotal } from "@/domains/products/utils";
import type { UserAttributes } from "@/shared/models";
import { formatDateToISO } from "@/shared/utils";
import { mergeCustomerIoProfiles } from "../api";
import { CUSTOMER_IO_EVENT_TYPES } from "../models";
import { categoriesToCustomerIoProducts } from "./categories-to-analytics-products";

export const analyticsIdentifyOnboardingUser = (
  analytics: AnalyticsBrowser,
  user: CreateUser,
  state: UserAttributes["state"],
  utmCampaign: AnalyticsSlice["utmCampaign"],
) => {
  const bmiValues = {
    weight: user.weight,
    heightFeet: user.heightFeet,
    heightInches: user.heightInches,
  };

  const hasBmiData = Object.values(bmiValues).every(
    (attr) => typeof attr !== "undefined",
  );

  void analytics.identify(user.email, {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    dateOfBirth: formatDateToISO(user.dateOfBirth),
    gender: user.gender,
    phone: user.phoneNumber,
    state,
    marketingConsent: user.marketingEmailConsent,
    smsMarketingConsent: user.marketingSMSConsent,
    campaignId: utmCampaign,
    ...(hasBmiData && bmiValues),
  });

  void analytics.track(CUSTOMER_IO_EVENT_TYPES.EMAIL_SUBMITTED, {
    userId: user.email,
    email: user.email,
  });
};

export const analyticsProductClicked = (
  analytics: AnalyticsBrowser,
  product: Product,
) => {
  void analytics.track(CUSTOMER_IO_EVENT_TYPES.PRODUCT_ITEM_CLICKED, {
    productCategory: product.category.slug,
    productName: product.slug,
  });
};

export const analyticsCheckoutPageViewed = (
  analytics: AnalyticsBrowser,
  user: OnboardingUserWithPhoneNumber,
  categories: CategoryWithSelectedProduct[],
) => {
  const products = categoriesToCustomerIoProducts(categories);
  const totalPrice = products.reduce((acc, product) => acc + product.price, 0);

  void analytics.track(CUSTOMER_IO_EVENT_TYPES.CHECKOUT_PAGE_VIEWED, {
    userId: user.email,
    products: products,
    totalPrice: totalPrice,
  });
};

interface AnalyticsPaymentResultParams {
  analytics: AnalyticsBrowser;
  user: OnboardingUserWithPhoneNumber;
  categories: CategoryWithSelectedProduct[];
  coupon?: Coupon;
  analyticsCustomerId?: AnalyticsSlice["analyticsCustomerId"];
}

export const analyticsPaymentFailed = ({
  analytics,
  user,
  categories,
  coupon,
}: AnalyticsPaymentResultParams) => {
  const finalPrice = coupon?.isValid
    ? calculateTotal(categories, { coupon })
    : calculateTotal(categories);
  const products = categoriesToCustomerIoProducts(categories);

  void analytics.track(CUSTOMER_IO_EVENT_TYPES.PAYMENT_FAILED, {
    userId: user.email,
    products,
    totalPrice: finalPrice,
    discountCode: coupon?.code,
  });
};

export const analyticsPaymentCompleted = ({
  analytics,
  user,
  categories,
  coupon,
  analyticsCustomerId,
  succeededPaymentIds,
}: AnalyticsPaymentResultParams & { succeededPaymentIds: string[] }) => {
  const finalPrice = coupon?.isValid
    ? calculateTotal(categories, { coupon })
    : calculateTotal(categories);
  const products = categoriesToCustomerIoProducts(categories);

  void analytics.track(CUSTOMER_IO_EVENT_TYPES.PAYMENT_COMPLETED, {
    userId: user.email,
    succeededPaymentIds,
    amountPaid: finalPrice,
    discountCode: coupon?.code,
    products,
  });

  if (analyticsCustomerId) {
    try {
      void mergeCustomerIoProfiles({
        userCode: user.code,
        previousCustomerIoId: analyticsCustomerId,
      });
    } catch (error) {
      console.error("Error merging customer profiles", error);
    }
  }
};
