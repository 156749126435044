import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { FullScreenAlert, MedicationRequestFilter } from "@/components";
import { useBoolean } from "@/hooks";
import { ScreenLayout } from "@/layouts";
import { Button, CalendarFixedIconMono } from "@/ui";
import { useIntakeFormContext } from "../context";
import { INTAKE_FORM_ROUTE } from "../router";

export const MedicationRequest = () => {
  const {
    actions: { setIsAsync, setVideoConsultationSchedule },
  } = useIntakeFormContext();

  const {
    value: showDisqualifiedScreen,
    setTrue: openDisqualifiedScreen,
    setFalse: closeDisqualifiedScreen,
  } = useBoolean(false);

  const navigate = useNavigate();

  const handleNext = (isDisqualified: boolean) => {
    if (isDisqualified) {
      return openDisqualifiedScreen();
    }

    setIsAsync(true);
    setVideoConsultationSchedule(undefined);

    navigate(INTAKE_FORM_ROUTE.ASSESSMENT_FORM);
  };

  return (
    <ScreenLayout title={t`Begin filling out your request form`}>
      <MedicationRequestFilter
        onBack={() => navigate(INTAKE_FORM_ROUTE.CHOOSE_JOURNEY)}
        onNext={handleNext}
      />

      {showDisqualifiedScreen && (
        <FullScreenAlert
          customIcon={
            <CalendarFixedIconMono color="salmon" className="size-24" />
          }
          title={t`Prior consultation with provider required`}
          description={t`Due to your current medical situation, at this time we cannot make a refill request, you must see a provider first.`}
          customAction={
            <div className="flex flex-col-reverse items-center gap-2.5 sm:flex-row">
              <Button
                onClick={closeDisqualifiedScreen}
                variant="secondary"
                size="lg"
                className="w-full sm:w-fit"
              >
                {t`Go back`}
              </Button>

              <Button
                onClick={() =>
                  navigate(INTAKE_FORM_ROUTE.SCHEDULE_VIDEO_CONSULTATION)
                }
                size="lg"
                className="w-full sm:w-fit"
              >
                {t`Continue`}
              </Button>
            </div>
          }
        />
      )}
    </ScreenLayout>
  );
};
