import { ExploreSubscriptionsButton } from "@/domains/billing/components";
import { ActiveSubscriptions } from "@/domains/billing/sections";
import { ScreenLayout } from "@/layouts";
import { BillingHeader, PaymentHistory } from "./sections";

export const Overview = () => {
  return (
    <ScreenLayout>
      <BillingHeader />

      <ActiveSubscriptions />

      <PaymentHistory />

      <ExploreSubscriptionsButton />
    </ScreenLayout>
  );
};
