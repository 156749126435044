import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import { skipToken } from "@tanstack/react-query";

import type { Category } from "@/domains/products/models";
import type { Question } from "@/shared/models";
import {
  getCategoryQuestions,
  getInitialQuestion,
  getQuestion,
} from "./questions";

export const onboardingQueriesStore = createQueryKeyStore({
  onboarding: {
    filteringQuestions: {
      queryKey: null,
      contextQueries: {
        initial: (bmi?: number) => ({
          queryKey: [bmi],
          queryFn: bmi ? () => getInitialQuestion(bmi) : (skipToken as never),
        }),
        next: (questionId?: Question["id"]) => ({
          queryKey: [questionId],
          queryFn: questionId
            ? () => getQuestion(questionId)
            : (skipToken as never),
        }),
        category: (category?: Category["id"]) => ({
          queryKey: [category],
          queryFn: category
            ? () => getCategoryQuestions(category)
            : (skipToken as never),
        }),
      },
    },
  },
});
