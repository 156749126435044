import type { ServiceResponse } from "@/api";
import { publicAPI } from "@/api";
import type { Coupon, CouponParams, ValidateCouponCodeParams } from "../models";

export const applyCouponCode = async (params: CouponParams) => {
  const { data } = await publicAPI.post<ServiceResponse<Coupon>>(
    `/validate-coupon-code`,
    params,
  );

  return data.data;
};

export const validateCouponCode = async (params: ValidateCouponCodeParams) => {
  const { data } = await publicAPI.post<ServiceResponse<Coupon>>(
    `/validate-coupon-code`,
    params,
  );

  return data.data;
};
