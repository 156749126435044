import { t } from "ttag";

import { NewMedicationRequestButton } from "@/domains/my-meds/components/NewMedicationRequestButton";
import { useCurrentMedicationRequests } from "@/domains/my-meds/hooks";
import { ROUTES } from "@/router";
import { Header, UserIcon } from "@/ui";

export const MyMedsHeader = () => {
  const { data: currentMedicationRequests } = useCurrentMedicationRequests();

  const hasMedicationRequests = Boolean(currentMedicationRequests?.length);

  return (
    <Header.Root>
      <Header.Background />

      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.MY_MEDS,
              icon: UserIcon,
              label: t`My Meds`,
            },
          ]}
        />

        <div className="flex justify-between">
          <Header.Title>{t`My Meds`}</Header.Title>

          {hasMedicationRequests && (
            <Header.Actions className="hidden md:block">
              <NewMedicationRequestButton color="brown" />
            </Header.Actions>
          )}
        </div>
      </Header.Content>
    </Header.Root>
  );
};
