import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from "react";
import { forwardRef } from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import { tv } from "tailwind-variants";

import { tw } from "@/utils";

const cardVariants = tv({
  slots: {
    separator: "h-[1px] w-full bg-brown-02 last:hidden",
  },
});

const { separator } = cardVariants();

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "relative flex flex-col gap-3 rounded-2xl bg-salmon-03 p-4 text-salmon-10",
        className,
      )}
      {...props}
    />
  ),
);
Root.displayName = "CardPrimitive.Root";

const Header = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw("flex flex-col gap-3", className)}
      {...props}
    />
  ),
);
Header.displayName = "CardPrimitive.Header";

const Title = forwardRef<ElementRef<"h3">, ComponentPropsWithoutRef<"h3">>(
  ({ className, children, ...props }, ref) => (
    <h3
      ref={ref}
      className={tw(
        "flex items-center gap-2 text-lg font-bold leading-6",
        className,
      )}
      {...props}
    >
      {children}
    </h3>
  ),
);
Title.displayName = "CardPrimitive.Title";

const Subtitle = forwardRef<ElementRef<"h6">, ComponentPropsWithoutRef<"h6">>(
  ({ className, children, ...props }, ref) => (
    <h6 ref={ref} className={tw("text-brown-07", className)} {...props}>
      {children}
    </h6>
  ),
);
Subtitle.displayName = "CardPrimitive.Subtitle";

const Description = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p ref={ref} className={tw("text-sm text-brown-09", className)} {...props} />
));
Description.displayName = "CardPrimitive.Description";

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={className} {...props} />
  ),
);
Content.displayName = "CardPrimitive.Content";

const Footer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={tw("flex items-center", className)} {...props} />
  ),
);
Footer.displayName = "CardPrimitive.Footer";

const Background = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "pointer-events-none absolute inset-0 -z-10 flex h-full w-full justify-end gap-2.5 overflow-hidden rounded-2xl bg-nature-08 text-nature-05",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  ),
);
Background.displayName = "CardPrimitive.Background";

const Separator = forwardRef<
  ElementRef<typeof SeparatorPrimitive.Root>,
  ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(
  (
    { className, orientation = "horizontal", decorative = true, ...props },
    ref,
  ) => {
    return (
      <SeparatorPrimitive.Root
        asChild
        ref={ref}
        decorative={decorative}
        orientation={orientation}
        className={separator({ className })}
        {...props}
      >
        <span />
      </SeparatorPrimitive.Root>
    );
  },
);
Separator.displayName = "DetailCard.Separator";

export {
  Root,
  Header,
  Footer,
  Title,
  Description,
  Content,
  Background,
  Subtitle,
  Separator,
};
