import { useId } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation } from "@tanstack/react-query";

import type { CreatedStripeSubscription } from "@/domains/billing/models";
import type { CheckoutFormValues } from "../models";
import { useOnboardingStore } from "../stores";

type UserLocationData = Pick<
  CheckoutFormValues,
  "city" | "zipCode" | "address" | "apartmentNumber"
>;

export const useStripeConfirmCardPayment = () => {
  const elements = useElements();
  const cardElement = elements?.getElement(CardElement);
  const stripe = useStripe();

  const user = useOnboardingStore((state) => state.user);

  const mutationId = useId();

  return useMutation({
    scope: { id: mutationId },
    mutationFn: async ({
      clientSecret,
      subscriptionId,
      locationData,
    }: CreatedStripeSubscription & {
      locationData: UserLocationData;
    }) => {
      if (!stripe) {
        throw new Error("Stripe.js has not loaded yet");
      }

      if (!cardElement) {
        throw new Error("Card element is not available");
      }

      if (!user) {
        throw new Error("No user");
      }

      // When the subscription price is below the Stripe chargeable minimum, clientSecret is "null", subscriptions are created as "active" and the client will be missing a default payment method
      if (!clientSecret) {
        return { subscriptionId };
      }

      const response = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            address: {
              country: "US",
              state: user.state,
              city: locationData.city,
              postal_code: locationData.zipCode,
              line1: locationData.address,
              line2: locationData.apartmentNumber,
            },
            email: user.email,
            name: user.firstName,
            phone: user.phoneNumber,
          },
        },
      });

      if (response.error) {
        throw new Error(response.error.message);
      }

      return { paymentIntentId: response.paymentIntent.id, subscriptionId };
    },
  });
};
