import { CurrentMedicationRequests, UpcomingConsultation } from "./components";

export const MyAgenda = () => {
  return (
    <div className="flex flex-col gap-6">
      <UpcomingConsultation />
      <CurrentMedicationRequests />
    </div>
  );
};
