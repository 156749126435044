import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import { t } from "ttag";

import { useBoolean } from "@/hooks";
import { Button, PillsIcon } from "@/ui";
import { SelectRefillModal } from "./SelectRefillModal";

type NewMedicationRequestButtonProps = ComponentPropsWithoutRef<typeof Button>;

export const NewMedicationRequestButton = forwardRef<
  ElementRef<typeof Button>,
  NewMedicationRequestButtonProps
>((props, ref) => {
  const {
    value: showSelectRefillModal,
    setTrue: openSelectRefillModal,
    setFalse: closeSelectRefillModal,
  } = useBoolean(false);

  const handleNewMedicationRequest = () => {
    openSelectRefillModal();
  };

  return (
    <>
      <Button
        ref={ref}
        onClick={handleNewMedicationRequest}
        size="lg"
        className="w-full md:w-fit"
        {...props}
      >
        <PillsIcon className="size-4" />
        {t`New medication request`}
      </Button>

      <SelectRefillModal
        show={showSelectRefillModal}
        onClose={closeSelectRefillModal}
      />
    </>
  );
});
NewMedicationRequestButton.displayName = "NewMedicationRequestButton";
