import type { PropsWithChildren } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import { FullScreenLoader, Loader } from "@/components";
import {
  useHealthieForm,
  useSubmitHealthieForm,
} from "@/domains/intake-form/hooks";
import type { HealthieFormParams } from "@/domains/intake-form/models";
import { Form } from "@/ui";
import { handleAxiosFieldErrors, tw } from "@/utils";
import { HealthieFormField } from "./components";

type HealthieFormProps = PropsWithChildren & {
  onSubmit?: () => void;
  params: HealthieFormParams;
  className?: string;
};

export const HealthieForm = ({
  onSubmit,
  params,
  children,
  className,
}: HealthieFormProps) => {
  const { data: healthieForm, isLoading, isSuccess } = useHealthieForm(params);

  const form = useForm({
    values: healthieForm?.fields.reduce(
      (acc, { id, value }) => ({
        ...acc,
        [id]: value ?? undefined,
      }),
      {},
    ),
  });

  const submitMutation = useSubmitHealthieForm();

  const handleSubmit: SubmitHandler<Record<string, string>> = (values) => {
    if (healthieForm?.formId)
      submitMutation.mutate(
        { ...params, formId: healthieForm?.formId, answers: values },
        {
          onSuccess: onSubmit,
          onError: (err) => handleAxiosFieldErrors(err, form.setError),
        },
      );
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    return (
      <Form.Provider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className={tw("flex max-w-3xl flex-col gap-8", className)}
        >
          {healthieForm.fields.map((field) => (
            <HealthieFormField key={field.id} healthieField={field} />
          ))}

          {children}
        </form>

        {submitMutation.isPending && <FullScreenLoader />}
      </Form.Provider>
    );
  }

  return null;
};
