import { Link } from "react-router-dom";
import { t } from "ttag";

import { resetAllStores } from "@/domains/onboarding/stores";
import { ROUTES } from "@/router";
import { ConfettiFixedIconMono, FullScreenAlert } from "@/ui";

interface SuccessAlertProps {
  open: boolean;
}

export const SuccessAlert = ({ open }: SuccessAlertProps) => {
  const handleClick = () => {
    resetAllStores();
  };

  return (
    <FullScreenAlert.Root open={open} color="nature">
      <FullScreenAlert.Content>
        <FullScreenAlert.Header>
          <ConfettiFixedIconMono
            className="size-16 md:size-20"
            color="nature"
          />
          <FullScreenAlert.Title>{t`Congrats! You're a great fit for Fridays`}</FullScreenAlert.Title>
        </FullScreenAlert.Header>

        <FullScreenAlert.Description>
          <div className="flex flex-col gap-2 md:gap-6">
            <p>{t`The final step is to log in and complete the intake forms. Once that's done, you can start enjoying video consultations and ordering medication.`}</p>
            <p>
              {t`We're thrilled to be part of your wellness journey. Let's make every day feel like a Friday! 🎉`}
            </p>
          </div>
        </FullScreenAlert.Description>

        <FullScreenAlert.Footer>
          <FullScreenAlert.Action asChild>
            <Link
              to={ROUTES.LOGIN}
              onClick={handleClick}
            >{t`Start your journey!`}</Link>
          </FullScreenAlert.Action>
        </FullScreenAlert.Footer>
      </FullScreenAlert.Content>
    </FullScreenAlert.Root>
  );
};
