import { t } from "ttag";
import { z } from "zod";

import { subscriptionSchema } from "@/domains/billing/models";
import { FIELD_TYPE } from "@/shared.constants";
import {
  AGE_GROUP,
  APPOINTMENT_REQUIRED_STATES,
  AVAILABLE_LANGUAGE,
  GENDER,
  PORTAL_USER_TYPE,
} from "./constants";

export const getAppointmentRequiredStatesSchema = () =>
  z.enum(APPOINTMENT_REQUIRED_STATES);

export const getAvailableLanguageSchema = () =>
  z.nativeEnum(AVAILABLE_LANGUAGE, {
    required_error: t`Please select your preferred language`,
    invalid_type_error: t`Invalid language type`,
  });

export const portalUserSchema = z.object({
  code: z.string().uuid(),
  patientId: z.string().nullable(),
  name: z.string(),
  email: z.string().email(),
  type: z.nativeEnum(PORTAL_USER_TYPE).nullable(),
  gender: z.nativeEnum(GENDER).optional().catch(undefined),
  preferredLanguage: getAvailableLanguageSchema(),
  isFromEmbeddables: z.boolean(),
  needsChangePassword: z.boolean(),
  askForMedicationStatus: z.boolean(),
  subscriptions: z.array(z.lazy(() => subscriptionSchema)),
  gym: z.string().nullable(),
});

export const paginationParamsSchema = z.object({
  page: z.number(),
  pageSize: z.number(),
});

export const getUserAttributesSchema = () =>
  z.object({
    state: z.string().min(1, { message: t`State is required` }),
    gender: z.nativeEnum(GENDER, {
      message: t`Sex assigned at birth is required`,
    }),
    ageGroup: z.nativeEnum(AGE_GROUP, {
      message: t`Age range is required`,
    }),
  });

export const numberErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (
    issue.code === z.ZodIssueCode.invalid_type &&
    isNaN(Number(issue.received))
  ) {
    return { message: t`This field is required` };
  }

  return { message: ctx.defaultError };
};

export const getBmiSchema = () =>
  z.object({
    heightFeet: z
      .number({
        errorMap: numberErrorMap,
      })
      .int()
      .nonnegative(),
    heightInches: z
      .number({
        errorMap: numberErrorMap,
      })
      .int()
      .nonnegative(),
    weight: z
      .number({
        errorMap: numberErrorMap,
      })
      .int()
      .positive({ message: t`Must be greater than 0` }),
  });

export const optionSchema = z.object({ value: z.string(), label: z.string() });

export const getAnswerSchema = () =>
  z.object({
    id: z.number(),
    answer: z.string(),
    answerGroupId: z.number(),
    nextAnswerGroupId: z.number().nullable(),
    disqualifier: z.boolean(),
    unique: z.boolean().optional(),
  });

export const getQuestionSchema = () =>
  z.object({
    id: z.number(),
    question: z.string(),
    type: z.enum([FIELD_TYPE.CHECKBOX, FIELD_TYPE.RADIO]),
    onboardingAnswers: z.array(getAnswerSchema()),
  });
