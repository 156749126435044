import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import type { AnswerGroupOption } from "@/shared.types";
import { CheckboxIndicator } from "@/ui";
import { tw } from "@/utils";

interface AnswerCheckboxGroupProps<TValue> {
  id: string;
  options: readonly AnswerGroupOption<TValue>[];
  value?: TValue[];
  className?: string;
  onChange: (value: TValue[]) => void;
}

export const AnswerCheckboxGroup = <TValue extends string | number>({
  id,
  options,
  value = [],
  className,
  onChange,
}: AnswerCheckboxGroupProps<TValue>) => {
  return (
    <div
      id={id}
      className={tw(
        "grid gap-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
        className,
      )}
    >
      {options.map((option) => (
        <CheckboxPrimitive.Root
          key={`${id}-${option.value}`}
          value={option.value}
          id={`${id}-${option.value}`}
          checked={value.includes(option.value)}
          onCheckedChange={(checked) => {
            const hasUniqueOption =
              value.length === 1 &&
              options.find((option) => option.value === value[0])?.unique;
            if ((checked && !!option.unique) || hasUniqueOption) {
              return onChange([option.value]);
            }

            return checked
              ? onChange([...value, option.value])
              : onChange(value.filter((value) => value !== option.value));
          }}
          className="group flex grow gap-2 rounded-2xl border border-brown-05 bg-brown-01 p-4 data-[state=checked]:border-brown-04 data-[state=checked]:bg-brown-04"
        >
          <div className="pt-1">
            <CheckboxIndicator size="sm" />
          </div>

          <label
            htmlFor={`${id}-${option.value}`}
            className="flex cursor-pointer flex-col items-start gap-0.5 text-left text-brown-10"
          >
            {option.label}
            <span className="text-xs font-medium text-brown-07">
              {option.clarification}
            </span>
          </label>
        </CheckboxPrimitive.Root>
      ))}
    </div>
  );
};
