import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { MedicationRequestFilter } from "@/components";
import { SubscriptionDowngradeModal } from "@/domains/billing/components";
import { WL_PRODUCT_SLUG } from "@/domains/products/models";
import { useBoolean } from "@/hooks";
import { ScreenLayout } from "@/layouts";
import { ROUTES } from "@/router";
import { useInitialSubscription, useJourneyRequirements } from "../hooks";
import { JourneyRestricted } from "./JourneyRestricted";
import { JourneySelection } from "./JourneySelection";

export const ChooseJourney = () => {
  const subscription = useInitialSubscription();

  const {
    value: isDisqualifiedByRequestFilter,
    setValue: setRequestFilterDisqualify,
  } = useBoolean(false);

  const {
    requiresAppointment,
    hasPreviousRequestFilter: needsPreviousRequestFilter,
    actions: { removeMedicationRequestFilter },
  } = useJourneyRequirements();

  const {
    value: showSubscriptionUpdateModal,
    setTrue: openSubscriptionUpdateModal,
    setFalse: closeSubscriptionUpdateModal,
  } = useBoolean();

  const navigate = useNavigate();

  const isTirzepatideSubscription =
    subscription?.product.slug === WL_PRODUCT_SLUG.TIRZEPATIDE;

  const isJourneyRestricted =
    requiresAppointment ||
    isDisqualifiedByRequestFilter ||
    isTirzepatideSubscription;

  if (!subscription) {
    return null;
  }

  if (needsPreviousRequestFilter) {
    return (
      <ScreenLayout
        hasCardHeader={false}
        className="gap-6"
        title={t`Begin filling out your request form`}
      >
        <MedicationRequestFilter
          onBack={() => navigate(ROUTES.BASE)}
          onNext={(isDisqualified) => {
            if (isDisqualified) {
              openSubscriptionUpdateModal();
            } else {
              removeMedicationRequestFilter();
            }

            setRequestFilterDisqualify(isDisqualified);
          }}
        />

        <SubscriptionDowngradeModal
          subscriptionId={subscription.id}
          show={showSubscriptionUpdateModal}
          onClose={closeSubscriptionUpdateModal}
          onContinue={removeMedicationRequestFilter}
        />
      </ScreenLayout>
    );
  }

  return isJourneyRestricted ? <JourneyRestricted /> : <JourneySelection />;
};
