import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { Badge as BadgePrimitive } from "@/ui";

export const showcaseCardVariants = tv({
  slots: {
    root: "flex flex-col gap-5 overflow-hidden rounded-lg bg-white p-5 text-start",
    clickableRoot:
      "w-full cursor-pointer disabled:cursor-not-allowed disabled:opacity-50 [&>div]:duration-150 [&>div]:has-[[data-state=open]]:h-full [&>div]:data-[state=checked]:border-nature-07 [&>div]:data-[state=checked]:bg-nature-02 [&>div]:data-[state=checked]:text-nature-08 [&_.discount-alert]:data-[state=checked]:bg-nature-03 [&_.discount-alert_strong]:data-[state=checked]:text-nature-10 [&_em]:data-[state=checked]:text-nature-06 [&_label]:data-[state=checked]:text-nature-08",
    header: "flex flex-col gap-1",
    title: "text-lg font-bold leading-tight text-brown-10",
    description: "text-sm text-brown-08",
    content: "",
    footer: "",
    list: "list-inside list-disc pl-1.5 text-left text-sm [&_em]:not-italic [&_em]:text-brown-06",
    inset: "-m-5 mb-0",
    thumbnail: "size-full h-36 bg-salmon-01 object-cover sm:h-60",
    badges: "flex flex-wrap items-center gap-2",
    badge: "border-brown-02 bg-brown-02 text-brown-08",
  },
});

const insetVariants = tv({
  variants: {
    side: {
      all: "-m-5",
      x: "-mx-5",
      y: "-my-5",
      top: "-mt-5",
      bottom: "-mb-5",
      left: "-ml-5",
      right: "-mr-5",
    },
    bottomSpacing: {
      true: "mb-0",
    },
  },
  defaultVariants: {
    side: "all",
    bottomSpacing: true,
  },
});
type InsetVariants = VariantProps<typeof insetVariants>;

const {
  root,
  header,
  title,
  description,
  content,
  footer,
  list,
  thumbnail,
  badges,
  badge,
} = showcaseCardVariants();

const Root = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={root({ className })} {...props} />
  ),
);
Root.displayName = "ShowcaseCard.Root";

const Header = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={header({ className })} {...props} />
  ),
);
Header.displayName = "ShowcaseCard.Header";

const Title = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={title({ className })} {...props} />
  ),
);
Title.displayName = "ShowcaseCard.Title";

const Description = forwardRef<
  ElementRef<"div">,
  ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
  <div ref={ref} className={description({ className })} {...props} />
));
Description.displayName = "ShowcaseCard.Description";

const Content = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={content({ className })} {...props} />
  ),
);
Content.displayName = "ShowcaseCard.Content";

const Footer = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={footer({ className })} {...props} />
  ),
);
Footer.displayName = "ShowcaseCard.Footer";

const List = forwardRef<ElementRef<"ul">, ComponentPropsWithoutRef<"ul">>(
  ({ className, ...props }, ref) => {
    return <ul ref={ref} className={list({ className })} {...props} />;
  },
);
List.displayName = "ShowcaseCard.List";

const Inset = forwardRef<
  ElementRef<"div">,
  ComponentPropsWithoutRef<"div"> & InsetVariants
>(({ className, side, ...props }, ref) => (
  <div ref={ref} className={insetVariants({ side, className })} {...props} />
));
Inset.displayName = "ShowcaseCard.Inset";

const Thumbnail = forwardRef<
  ElementRef<"img">,
  ComponentPropsWithoutRef<"img">
>(({ alt, className, ...props }, ref) => {
  return (
    <img
      ref={ref}
      alt={alt}
      className={thumbnail({ className })}
      draggable="false"
      {...props}
    />
  );
});
Thumbnail.displayName = "ShowcaseCard.Thumbnail";

const Badges = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={badges({ className })} {...props} />;
  },
);
Badges.displayName = "ShowcaseCard.Badges";

const Badge = forwardRef<
  ElementRef<"div">,
  ComponentPropsWithoutRef<typeof BadgePrimitive>
>(({ className, ...props }, ref) => {
  return (
    <BadgePrimitive
      ref={ref}
      size="xs"
      rounded="3xl"
      className={badge({ className })}
      {...props}
    />
  );
});
Badge.displayName = "ShowcaseCard.Badge";

export const ShowcaseCard = {
  Root,
  Header,
  Title,
  Description,
  Content,
  Footer,
  List,
  Inset,
  Thumbnail,
  Badges,
  Badge,
};
