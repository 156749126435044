import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import { tv } from "tailwind-variants";
import type { VariantProps } from "tailwind-variants";

import { Badge } from "@/ui";
import { CATEGORY_SLUG } from "../models";

const categoryBadgeVariants = tv({
  base: "font-medium",
  variants: {
    categorySlug: {
      [CATEGORY_SLUG.HAIR_GROWTH]: "bg-brown-04 text-brown-09",
      [CATEGORY_SLUG.WEIGHT_LOSS]: "bg-nature-03 text-nature-10",
    },
  },
  defaultVariants: {
    categorySlug: CATEGORY_SLUG.HAIR_GROWTH,
  },
});

type CategoryBadgeVariants = VariantProps<typeof categoryBadgeVariants>;

type CategoryBadgeProps = ComponentPropsWithoutRef<typeof Badge> &
  CategoryBadgeVariants;

export const CategoryBadge = forwardRef<
  ElementRef<typeof Badge>,
  CategoryBadgeProps
>(({ className, rounded = "3xl", categorySlug, ...props }, ref) => {
  return (
    <Badge
      ref={ref}
      rounded={rounded}
      className={categoryBadgeVariants({ categorySlug, className })}
      {...props}
    />
  );
});
CategoryBadge.displayName = "CategoryBadge";
