import { useId } from "react";
import type { ComponentPropsWithoutRef } from "react";
import type { Control } from "react-hook-form";
import { jt, t } from "ttag";

import { Form } from "@/ui";
import { Checkbox } from "@/ui/form/Checkbox";

interface FieldValue {
  marketingEmailConsent?: boolean;
}

type MarketingEmailConsentControl<T> = T extends FieldValue
  ? Control<T, "marketingEmailConsent">
  : never;

type MarketingEmailConsentFieldProps<T extends FieldValue> =
  ComponentPropsWithoutRef<typeof Form.Item> & {
    control: MarketingEmailConsentControl<T>;
  };
export const MarketingEmailConsentField = <T extends FieldValue>({
  control,
  ...props
}: MarketingEmailConsentFieldProps<T>) => {
  const id = useId();

  const emailBold = <strong key={`${id}-bold-email`}>{t`emails`}</strong>;

  const promotionalContentBold = (
    <strong key={`${id}-bold-promotional-content`}>
      {t`promotional content`}
    </strong>
  );

  const includedContentBold = (
    <strong key={`${id}-bold-included-content`}>
      {t`members-only exclusive weight loss updates, special offers, and health tips`}
    </strong>
  );

  return (
    <Form.Field
      control={control}
      name="marketingEmailConsent"
      render={({ field: { value, onChange, ...rest } }) => (
        <Form.Item {...props}>
          <div className="flex items-start gap-2.5">
            <Form.Control>
              <Checkbox
                {...rest}
                checked={value}
                onCheckedChange={onChange}
                defaultChecked={value}
              />
            </Form.Control>

            <Form.Label className="text-brown-08">
              {jt`Send me Fridays ${emailBold} with ${promotionalContentBold}, including ${includedContentBold} (optional).`}
            </Form.Label>
          </div>

          <Form.Message compact={false} />
        </Form.Item>
      )}
    />
  );
};
