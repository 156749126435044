import {
  REQUIRE_JOURNEY_INFO_ROUTES,
  REQUIRE_MAIN_INFO_ROUTES,
  REQUIRE_MEASUREMENTS_ROUTES,
} from ".";

export const requiresMainInfoData = (pathname: string) =>
  REQUIRE_MAIN_INFO_ROUTES.includes(pathname as never);

export const requiresMeasurementsData = (pathname: string) =>
  REQUIRE_MEASUREMENTS_ROUTES.includes(pathname as never);

export const requiresJourneyInfoData = (pathname: string) =>
  REQUIRE_JOURNEY_INFO_ROUTES.includes(pathname as never);
