import type { ServiceResponse } from "@/api";
import { publicAPI } from "@/api";
import type { PasswordFormValues, Patient } from "@/shared.types";
import { getOnboardingUserSchema } from "../models";
import type { CreateUser, OnboardingUser } from "../models";

export const createUser = async (params: CreateUser) => {
  const { data } = await publicAPI.post<ServiceResponse<OnboardingUser>>(
    "/users",
    params,
  );

  return getOnboardingUserSchema().parse(data.data);
};

type SetPasswordParams = PasswordFormValues & { token: string };
interface SetPasswordResponse {
  patientId: Patient["id"];
}

export const setPassword = async (params: SetPasswordParams) => {
  const { data } = await publicAPI.put<ServiceResponse<SetPasswordResponse>>(
    "/patients/set-password",
    params,
  );

  return data.data;
};
