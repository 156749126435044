import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { PORTAL_DOMAIN } from "@/api";
import {
  billingQueriesStore,
  createPatientSubscriptions,
} from "@/domains/billing/api";
import { errorToast, useToastStore } from "@/ui";
import { BILLING_ROUTE } from "../router";

export const useCreatePatientSubscriptions = () => {
  const { pushToast } = useToastStore();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  return useMutation({
    mutationFn: createPatientSubscriptions,
    onSuccess: (_, sentData) => {
      void queryClient.invalidateQueries({
        queryKey: billingQueriesStore.stripe.subscriptions.queryKey,
      });

      void queryClient.invalidateQueries({
        queryKey: [PORTAL_DOMAIN],
      });

      void pushToast({
        type: "success",
        title: t`Success`,
        message:
          sentData.length > 1
            ? t`Your new subscriptions are now active—enjoy!`
            : t`Your new subscription is now active—enjoy!`,
      });

      navigate(BILLING_ROUTE.BASE);
    },
    onError: errorToast,
  });
};
