import type { ComponentPropsWithoutRef } from "react";
import type { Control } from "react-hook-form";
import { t } from "ttag";

import { Form, Home2Icon } from "@/ui";
import { Select } from "@/ui/form/Select";
import { useStateOptions } from "../hooks";

interface FieldValue {
  state: string;
}

type StateControl<T> = T extends FieldValue ? Control<T, "state"> : never;

type StateFieldProps<T extends FieldValue> = ComponentPropsWithoutRef<
  typeof Form.Item
> & {
  control: StateControl<T>;
};

export const StateField = <T extends FieldValue>({
  control,
  ...props
}: StateFieldProps<T>) => {
  const { data: stateOptions, isLoading } = useStateOptions();

  return (
    <Form.Field
      control={control}
      name="state"
      render={({ field }) => (
        <Form.Item {...props}>
          <Form.Label aria-required>{t`State`}</Form.Label>
          <Form.Control>
            <Select
              options={stateOptions}
              isLoading={isLoading}
              leftIcon={<Home2Icon />}
              {...field}
            />
          </Form.Control>
          <Form.Message compact={false} />
        </Form.Item>
      )}
    />
  );
};
