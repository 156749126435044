import { zodResolver } from "@hookform/resolvers/zod";
import { differenceInYears } from "date-fns";
import { useForm } from "react-hook-form";
import { t } from "ttag";
import { z } from "zod";

import { useOnboardingStore } from "@/domains/onboarding/stores";
import { getDateOfBirthSchema } from "@/shared.schemas";
import { AGE_GROUP } from "@/shared/models";
import type { UserAttributes } from "@/shared/models";
import { formatDOB } from "@/utils";
import { getUserContactInfoSchema } from "../models";

const ageGroupValidationMap = {
  [AGE_GROUP.YOUNGER_THAN_18]: (age: number) => {
    return age < 18;
  },
  [AGE_GROUP.BETWEEN_18_AND_49]: (age: number) => {
    return age >= 18 && age <= 49;
  },
  [AGE_GROUP.BETWEEN_18_AND_74]: (age: number) => {
    return age >= 18 && age <= 74;
  },
  [AGE_GROUP.BETWEEN_50_AND_74]: (age: number) => {
    return age >= 50 && age <= 74;
  },
  [AGE_GROUP.OLDER_THAN_74]: (age: number) => {
    return age >= 74;
  },
};

export const getFormSchema = (ageGroup?: UserAttributes["ageGroup"]) =>
  getUserContactInfoSchema()
    .omit({ dateOfBirth: true })
    .extend({
      emailConfirmation: z
        .string()
        .email({ message: t`Please enter a valid email` }),
      termsAndConditionsConsent: z.literal(true, {
        errorMap: () => ({
          message: t`You must agree to the terms and conditions`,
        }),
      }),
      dateOfBirth: getDateOfBirthSchema().refine(
        (dateOfBirth) => {
          if (!ageGroup) {
            return true;
          }

          const formattedDOB = new Date(formatDOB(dateOfBirth));
          const age = differenceInYears(new Date(), formattedDOB);

          return ageGroupValidationMap[ageGroup](age);
        },
        {
          message: t`Your age does not match the previously selected age range`,
          path: ["year"],
        },
      ),
    })
    .refine((data) => data.email === data.emailConfirmation, {
      message: t`Emails must match`,
      path: ["emailConfirmation"],
    });

export const useCreateUserForm = () => {
  const userAttributes = useOnboardingStore((state) => state.userAttributes);
  const formSchema = getFormSchema(userAttributes?.ageGroup);

  return useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "onTouched",
    defaultValues: {
      marketingEmailConsent: true,
    },
  });
};
