import { useId } from "react";
import { tv } from "tailwind-variants";

import { ShowcaseCardSkeleton } from "@/ui";

export const categoriesVariants = tv({
  slots: {
    container: "flex grow flex-col gap-10 md:gap-12",
    list: "grid gap-x-6 gap-y-4 md:grid-cols-2",
    card: "duration-200 has-[[data-state=open]]:h-full",
    errorMessage: "mt-auto text-center",
  },
  variants: {
    singleCol: {
      true: {
        list: "md:mx-auto md:w-1/2 md:grid-cols-1",
      },
    },
  },
});

const { container, list } = categoriesVariants();

export const LoadingCategories = () => {
  const id = useId();

  return (
    <div className={container()}>
      <div className={list()}>
        {Array.from({ length: 2 }, (_, index) => `${id}-${index}`).map(
          (key) => (
            <ShowcaseCardSkeleton key={key} />
          ),
        )}
      </div>
    </div>
  );
};
