import type { QueryClient } from "@tanstack/react-query";

import type {
  Appointment,
  Consultation,
  ISODate,
  OnboardingUser,
  Provider,
  TimeSlot,
  USState,
} from "@/shared.types";
import type { AvailableLanguage } from "@/shared/models";
import type { ServiceResponse } from "./api.types";
import { privateAPI, publicAPI } from "./axios";

export const CONSULTATIONS_DOMAIN = "consultations";

export const getPastConsultationsQuery = (consultationsOffset: number) => ({
  queryKey: [
    CONSULTATIONS_DOMAIN,
    "getPastConsultationsQuery",
    consultationsOffset,
  ],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<Consultation[]>>(
      "/appointments",
      { params: { offset: consultationsOffset, upcomingAppointments: false } },
    );

    return data;
  },
});

export const getUpcomingConsultationsQuery = () => ({
  queryKey: [CONSULTATIONS_DOMAIN, "getUpcomingConsultationsQuery"],
  queryFn: async () => {
    const {
      data: { data },
    } = await privateAPI.get<ServiceResponse<Consultation[]>>(
      `/appointments?upcomingAppointments=true`,
    );

    return data;
  },
});

export const getNeedsSyncRefillQuery = () => ({
  queryKey: [CONSULTATIONS_DOMAIN, "getNeedsSyncRefillQuery"],
  queryFn: async () => {
    const {
      data: { data },
    } = await privateAPI.get<ServiceResponse<{ needsSync: boolean }>>(
      `/patients/needs-sync-refill`,
    );

    return data;
  },
});

export const getProvidersQuery = (params: { state?: USState["value"] }) => ({
  queryKey: [CONSULTATIONS_DOMAIN, "getProvidersQuery", params.state],
  queryFn: async () => {
    const {
      data: { data },
    } = await publicAPI.get<ServiceResponse<Provider[]>>(`/providers`, {
      params,
    });

    return data;
  },
});

interface ScheduleParams {
  userId?: OnboardingUser["id"];
  providerId?: Provider["id"];
}

interface GetAvailableDaysQueryParams extends ScheduleParams {
  dateFromMonth: ISODate;
  language?: AvailableLanguage;
}

export const getAvailableDaysQuery = (params: GetAvailableDaysQueryParams) => ({
  queryKey: [CONSULTATIONS_DOMAIN, "getAvailableDaysQuery", { ...params }],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<ISODate[]>>(
      `/providers/available-days-in-month`,
      { params },
    );

    return data.data;
  },
});

interface GetAvailableTimesQueryParams extends ScheduleParams {
  date: ISODate | null;
  language?: AvailableLanguage;
}

export const getAvailableTimesQuery = (
  params: GetAvailableTimesQueryParams,
) => ({
  enabled: !!params.date,
  queryKey: [CONSULTATIONS_DOMAIN, "getAvailableTimesQuery", { ...params }],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<TimeSlot[]>>(
      `/providers/time-slots`,
      { params },
    );

    return data.data;
  },
});

interface ScheduleAppointmentParams {
  timeSlot: TimeSlot;
  language: AvailableLanguage;
}

export const scheduleAppointment = {
  mutation: async (params: ScheduleAppointmentParams) => {
    const { timeSlot, language } = params;
    const { data } = await privateAPI.post<ServiceResponse<Appointment>>(
      "/appointments",
      {
        providerId: timeSlot.providerId,
        appointmentDate: `${timeSlot.dateTime} ${timeSlot.utcDiff}`,
        language,
      },
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [CONSULTATIONS_DOMAIN] });
  },
};

export const cancelAppointment = {
  mutation: async (appointmentId: string) => {
    const { data } = await privateAPI.delete<ServiceResponse<unknown>>(
      `/appointments/${appointmentId}`,
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({
      queryKey: [CONSULTATIONS_DOMAIN, "getUpcomingConsultationsQuery"],
    });
  },
};
