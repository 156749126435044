import { useQuery } from "@tanstack/react-query";

import { productsQueriesStore } from "../api";
import type { Category } from "../models";

export const usePatientProductsByCategory = (categoryId?: Category["id"]) => {
  return useQuery(
    productsQueriesStore.products.patient._ctx.products(categoryId),
  );
};
