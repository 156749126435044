import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ErrorState } from "@/components";
import {
  NavigationButtons,
  OnboardingHeader,
} from "@/domains/onboarding/components";
import { ONBOARDING_ROUTE } from "@/domains/onboarding/router";
import {
  MissingProductModal,
  ProductSelection,
} from "@/domains/onboarding/sections";
import { useOnboardingStore } from "@/domains/onboarding/stores";
import { LoadingProducts } from "@/domains/products/components";
import { useProductsByCategory } from "@/domains/products/hooks";
import { useBoolean } from "@/hooks";
import { Badge, Button } from "@/ui";
import { handleTheOfferTracking, THE_OFFER_EVENTS } from "@/utils";

export const ChooseProducts = () => {
  const selectedCategories = useOnboardingStore(
    (state) => state.selectedCategories,
  );
  const [categoryId, setCategoryId] = useState(selectedCategories[0]?.id);
  const userAttributes = useOnboardingStore((state) => state.userAttributes);
  const {
    value: showMissingProductModal,
    setTrue: openMissingProductModal,
    setFalse: closeMissingProductModal,
  } = useBoolean(false);

  const {
    data: products,
    isSuccess,
    isLoading,
    isError,
  } = useProductsByCategory(categoryId, userAttributes);

  const navigate = useNavigate();

  if (!selectedCategories.length) {
    return <Navigate to={ONBOARDING_ROUTE.TREATMENT_CHOOSE_CATEGORIES} />;
  }

  if (isError) {
    return <ErrorState className="grow-0 border-none" />;
  }

  const handleBack = () => {
    navigate(ONBOARDING_ROUTE.TREATMENT_CHOOSE_CATEGORIES);
  };

  const handleContinue = () => {
    const categoriesWithProduct = selectedCategories.filter(
      (category) => category.selectedProduct,
    );

    const categoriesWithoutProduct = selectedCategories.filter(
      (category) => !category.selectedProduct,
    );

    const categoryWithoutProductId = categoriesWithoutProduct[0]?.id;
    const isLastCategory = categoryId === selectedCategories.at(-1)?.id;
    const notAllCategoriesSelected =
      categoriesWithProduct.length !== selectedCategories.length;
    const isCurrentCategoryWithoutProduct =
      categoryWithoutProductId === categoryId && notAllCategoriesSelected;

    if (!categoriesWithProduct.length || isCurrentCategoryWithoutProduct) {
      return openMissingProductModal();
    }

    if (!isLastCategory && notAllCategoriesSelected) {
      return setCategoryId(categoryWithoutProductId);
    }

    if (isLastCategory && notAllCategoriesSelected) {
      return openMissingProductModal();
    }

    handleTheOfferTracking({ event: THE_OFFER_EVENTS.ADD_TO_CART });
    navigate(ONBOARDING_ROUTE.TREATMENT_SUMMARY);
  };

  return (
    <>
      <OnboardingHeader title={t`Choose your products`} />

      <div className="flex grow flex-col gap-12">
        <div className="flex flex-wrap items-center justify-center gap-2.5">
          {selectedCategories?.map((sessionCategory) => {
            return (
              <Badge
                key={sessionCategory.id}
                asChild
                rounded="3xl"
                size="xl"
                variant="secondary"
                clickable
                selected={categoryId === sessionCategory.id}
              >
                <Button onClick={() => setCategoryId(sessionCategory.id)}>
                  {sessionCategory.name}
                </Button>
              </Badge>
            );
          })}
        </div>

        {isLoading && <LoadingProducts />}

        {isSuccess && (
          <>
            <ProductSelection key={categoryId} products={products} />

            <NavigationButtons
              onCancel={handleBack}
              onContinue={handleContinue}
            />

            <MissingProductModal
              open={showMissingProductModal}
              onClose={closeMissingProductModal}
              onKeepBrowsing={setCategoryId}
            />
          </>
        )}
      </div>
    </>
  );
};
