import { create } from "zustand";

import { CATEGORY_SLUG } from "@/domains/products/models";
import { PORTAL_USER_TYPE } from "@/shared/models";
import type { PortalUser } from "@/shared/models";
import { isInactiveSubscription } from "@/utils";

interface PortalUserStoreState {
  portalUser: PortalUser | null;
  hasActiveWLSubscription: boolean;
  setPortalUser: (portalUser: PortalUser) => void;
}

export const usePortalUserStore = create<PortalUserStoreState>()((set) => ({
  portalUser: null,
  hasActiveWLSubscription: false,

  setPortalUser: (portalUser) => {
    const hasActiveWLSubscription = Boolean(
      portalUser.subscriptions?.some(
        ({ product, status }) =>
          product.category.slug === CATEGORY_SLUG.WEIGHT_LOSS &&
          !isInactiveSubscription(status),
      ),
    );

    set({ hasActiveWLSubscription });

    if (portalUser.type) {
      set({ portalUser });
      return;
    }

    let type: PortalUser["type"] = null;

    if (portalUser.patientId) {
      type = PORTAL_USER_TYPE.PATIENT;
    }

    if (!portalUser.patientId && portalUser.isFromEmbeddables) {
      type = PORTAL_USER_TYPE.COACHING;
    }

    set({ portalUser: { ...portalUser, type } });
  },
}));
