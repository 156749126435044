import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import type { z } from "zod";

import { getUserAttributesSchema } from "@/shared/models";
import { useOnboardingStore } from "../stores";

export const useUserAttributesForm = () => {
  const defaultValues = useOnboardingStore((state) => state.userAttributes);

  const formSchema = getUserAttributesSchema();

  return useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });
};
