const BASE_ROUTE = "/embeddables-onboarding";

export const EMBEDDABLES_ONBOARDING_ROUTE = {
  BASE: BASE_ROUTE,
  ACCOUNT: `${BASE_ROUTE}/account`,
  MAIN_INFO: `${BASE_ROUTE}/main-info`,
  MAIN_INFO_MEASUREMENTS: `${BASE_ROUTE}/main-info/measurements`,

  SUCCESS: `${BASE_ROUTE}/start-your-journey`,
} as const;

export const REQUIRE_JOURNEY_INFO_ROUTES = [
  EMBEDDABLES_ONBOARDING_ROUTE.SUCCESS,
] as const;

export const REQUIRE_MEASUREMENTS_ROUTES = [
  ...REQUIRE_JOURNEY_INFO_ROUTES,
] as const;

export const REQUIRE_MAIN_INFO_ROUTES = [
  EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO_MEASUREMENTS,
  ...REQUIRE_MEASUREMENTS_ROUTES,
] as const;
