import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getPastConsultationsQuery } from "@/api";
import { getDocumentQuery } from "@/api/documents";
import { ErrorState } from "@/components";
import {
  PastConsultationsCards,
  PastConsultationsTable,
} from "@/domains/consultations/components";
import type { Consultation } from "@/shared.types";
import { icons, IconWrapper, illustratedIcons } from "@/ui";
import { Pagination } from "@/ui/common/Pagination";
import { TableSkeleton } from "@/ui/common/TableSkeleton";

export const PastConsultations = () => {
  const [consultationsOffset, setConsultationsOffset] = useState(0);
  const { data, isLoading, isSuccess, isError } = useQuery(
    getPastConsultationsQuery(consultationsOffset),
  );

  const consultations = data?.data ?? [];
  const pagination = data?.pagination;
  const [documentId, setDocumentId] = useState<Consultation["documentId"]>();

  const { data: documentUrl } = useQuery(getDocumentQuery(documentId));

  useEffect(() => {
    if (documentUrl) {
      window.open(documentUrl.url, "_blank");
      setDocumentId("");
    }
  }, [documentUrl]);

  return (
    <section className="flex flex-1 flex-col gap-4 pb-10 text-brown-10">
      <h3 className="flex items-center gap-2 text-lg font-bold">
        <IconWrapper size="sm">
          <icons.VideoCamera />
        </IconWrapper>
        {t`Past Video Consultations`}
      </h3>

      {isLoading && <TableSkeleton columns={5} rows={3} />}

      {isSuccess && !consultations.length && (
        <div className="flex flex-col items-center justify-center p-4">
          <illustratedIcons.LightBulb />
          <p className="text-xl font-medium text-brown-05">
            {t`You haven't had any consultation yet`}
          </p>
        </div>
      )}

      {isSuccess && Boolean(consultations.length) && (
        <>
          <PastConsultationsTable
            consultations={consultations}
            setDocumentId={setDocumentId}
          />

          <PastConsultationsCards
            consultations={consultations}
            setDocumentId={setDocumentId}
          />

          {pagination && pagination.totalPages > 1 && (
            <div className="flex items-center justify-between rounded-xl border border-brown-05 px-6 py-4 md:rounded-b-2xl md:rounded-t-none md:border-t-0">
              <Pagination
                {...pagination}
                onPageChange={(page) => {
                  setConsultationsOffset((page - 1) * pagination.perPage);
                }}
              />
            </div>
          )}
        </>
      )}

      {isError && <ErrorState />}
    </section>
  );
};
