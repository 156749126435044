import type { DPMonth } from "@rehookify/datepicker";
import { format } from "date-fns";
import { enUS, es } from "date-fns/locale";

import { AVAILABLE_LANGUAGE } from "@/shared/models";
import { useAppSettingsStore } from "@/stores";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./DPSelect";

interface MonthSelectProps {
  months: DPMonth[];
  month: number;
  onChange: (month: number) => void;
}

export const MonthSelect = ({ months, month, onChange }: MonthSelectProps) => {
  const locale = useAppSettingsStore((state) =>
    state.language === AVAILABLE_LANGUAGE.ES ? es : enUS,
  );

  return (
    <Select
      value={month.toString()}
      onValueChange={(value) => onChange(parseInt(value))}
    >
      <SelectTrigger id="month-selector-trigger" className="capitalize">
        <SelectValue placeholder="Select year..." />
      </SelectTrigger>

      <SelectContent align="center">
        {months.map((dPMonth) => (
          <SelectItem
            key={dPMonth.$date.toDateString()}
            value={dPMonth.$date.getMonth().toString()}
            className="capitalize"
          >
            {format(dPMonth.$date, "MMMM", { locale })}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
