import { t } from "ttag";

import { ErrorState } from "@/components";
import { MedicationStatusChip } from "@/domains/my-meds/components";
import { usePastMedications } from "@/domains/my-meds/hooks";
import {
  History2Icon,
  IconWrapper,
  illustratedIcons,
  Table,
  TableBody,
  TableCard,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
} from "@/ui";
import { TableSkeleton } from "@/ui/common/TableSkeleton";
import { formatBackendDate, tw } from "@/utils";
import { ResolutionIcon } from "./components";

// TODO: Review this component https://app.clickup.com/t/86duxq65b
export const PastMedicationRequests = () => {
  const {
    data: pastMedicationRequests,
    isLoading,
    isSuccess,
    isError,
  } = usePastMedications();

  return (
    <section className="flex grow flex-col gap-4 text-brown-10">
      <h3 className="flex items-center gap-2 text-lg font-bold">
        <History2Icon className="size-4" />
        {t`Past medication requests`}
      </h3>

      {isLoading && <TableSkeleton columns={6} rows={3} />}

      {isSuccess && Boolean(pastMedicationRequests.length) && (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t`Drug name`}</TableHead>
                <TableHead>{t`Dose`}</TableHead>
                <TableHead>{t`Supply`}</TableHead>
                <TableHead>{t`Date`}</TableHead>
                <TableHead className="text-center">{t`Status`}</TableHead>
                <TableHead className="truncate text-right">{t`Tracking #`}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {pastMedicationRequests.map(
                ({
                  dose,
                  dateOfRequest,
                  status,
                  trackingNumber,
                  resolution,
                  supply,
                  product,
                }) => (
                  <TableRow key={`past-medication-request-${trackingNumber}`}>
                    <TableCell>{product.name ?? "-"}</TableCell>
                    <TableCell>{dose ?? "-"}</TableCell>
                    <TableCell>{supply ? `${supply} month(s)` : "-"}</TableCell>
                    <TableCell>{formatBackendDate(dateOfRequest)}</TableCell>
                    <TableCell>
                      <div className="flex justify-center">
                        <Tooltip.Provider>
                          <Tooltip.Root>
                            <Tooltip.Trigger
                              className={tw(!resolution && "cursor-default")}
                            >
                              <MedicationStatusChip
                                status={status}
                                className="truncate"
                              />
                            </Tooltip.Trigger>

                            {resolution && (
                              <Tooltip.Content className="flex gap-1">
                                <IconWrapper size="sm">
                                  <ResolutionIcon resolution={resolution} />
                                </IconWrapper>
                                {resolution}
                              </Tooltip.Content>
                            )}
                          </Tooltip.Root>
                        </Tooltip.Provider>
                      </div>
                    </TableCell>
                    <TableCell className="text-right">
                      {trackingNumber}
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>

          <TableCard.Root className="bg-white">
            {pastMedicationRequests.map(
              ({
                dose,
                dateOfRequest,
                status,
                trackingNumber,
                resolution,
                supply,
                product,
              }) => (
                <TableCard.Row
                  key={`past-medication-request-${trackingNumber}`}
                  className="flex-row"
                >
                  <div className="flex flex-1 flex-col gap-2">
                    <TableCard.Cell className="justify-between">
                      <TableCard.Data className="text-lg">
                        {formatBackendDate(dateOfRequest)}
                      </TableCard.Data>
                    </TableCard.Cell>

                    {trackingNumber && (
                      <TableCard.Cell>
                        <TableCard.Label>{t`Tracking`}</TableCard.Label>
                        <TableCard.Data>{trackingNumber}</TableCard.Data>
                      </TableCard.Cell>
                    )}

                    <TableCard.Cell>
                      <TableCard.Label>{t`Drug`}</TableCard.Label>
                      <TableCard.Data>{product.name ?? "-"}</TableCard.Data>
                    </TableCard.Cell>
                    <TableCard.Cell>
                      <TableCard.Label>{t`Dose`}</TableCard.Label>
                      <TableCard.Data>{dose ?? "-"}</TableCard.Data>
                    </TableCard.Cell>
                    <TableCard.Cell>
                      <TableCard.Label>{t`Supply`}</TableCard.Label>
                      <TableCard.Data>{supply ?? "-"}</TableCard.Data>
                    </TableCard.Cell>
                  </div>

                  <div className="flex flex-1 flex-col items-center gap-2 sm:items-end">
                    <MedicationStatusChip
                      status={status}
                      className="w-full sm:w-fit"
                    />
                    {resolution && (
                      <p className="inline-flex justify-center gap-1 text-sm leading-4 text-red-07">
                        <IconWrapper as="span" size="sm">
                          <ResolutionIcon resolution={resolution} />
                        </IconWrapper>
                        {resolution}
                      </p>
                    )}
                  </div>
                </TableCard.Row>
              ),
            )}
          </TableCard.Root>
        </>
      )}

      {isSuccess && !pastMedicationRequests.length && (
        <div className="flex grow items-center justify-center rounded-2xl border border-nature-06">
          <div className="flex flex-col items-center gap-2 text-center">
            <illustratedIcons.LightBulb />
            <p className="text-xl font-medium text-brown-05">{t`You don't have past medication requests`}</p>
          </div>
        </div>
      )}

      {isError && <ErrorState />}
    </section>
  );
};
