import { useCreateSubscriptions } from "@/domains/billing/hooks";
import type { CheckoutFormValues, UseCheckoutParams } from "../models";
import { getPricesIds } from "../utils";
import { useConfirmSubscriptionsPayment } from "./useConfirmSubscriptionsPayment";
import { useCreateHealthieUser } from "./useCreateHealthieUser";

export const useCheckout = ({
  user,
  selectedCategories,
  coupon,
}: UseCheckoutParams) => {
  const createSubscriptionsMutation = useCreateSubscriptions();
  const confirmSubscriptionsPaymentMutation = useConfirmSubscriptionsPayment({
    user,
    selectedCategories,
    coupon,
  });
  const createHealthieUserMutation = useCreateHealthieUser({
    user,
    coupon,
  });

  return async (formData: CheckoutFormValues) => {
    const subscriptionsToCreate = getPricesIds(selectedCategories);

    if (!user) {
      throw new Error("No user");
    }

    const subscriptions = await createSubscriptionsMutation.mutateAsync({
      userCode: user.code,
      subscriptions: subscriptionsToCreate,
      couponCode: coupon?.code,
    });

    await confirmSubscriptionsPaymentMutation.mutateAsync({
      subscriptions,
      locationData: formData,
    });

    await createHealthieUserMutation.mutateAsync({
      userCode: user.code,
      state: user.state,
      city: formData.city,
      zip: formData.zipCode,
      line1: formData.address,
      line2: formData.apartmentNumber,
      phoneNumber: user.phoneNumber,
    });
  };
};
