import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import {
  DoctorNotes,
  DocumentCenterOverview,
  EditProfile,
  FridaysResources,
  Home,
  LabsDetails,
  MedicalRecordsDetails,
  Messages,
  MyProfile,
  PrescriptionsDetails,
} from "@/screens";
import { usePortalUserStore } from "@/stores";
import { ROUTES } from "../constants";
import { ModalsRoutesWrapper } from "./ModalsRoutesWrapper";
import { PortalRoutesWrapper } from "./PortalRoutesWrapper";

/**
 * @deprecated These are the pending routes to move to their own router in the `domains` folder.
 */
export const LegacyRouter = () => {
  const location = useLocation();
  const { previousLocation } = (location.state ?? {}) as {
    previousLocation?: Location;
  };

  const hasActiveWLSubscription = usePortalUserStore(
    (state) => state.hasActiveWLSubscription,
  );

  return (
    <>
      <Routes location={previousLocation ?? location}>
        <Route element={<PortalRoutesWrapper />}>
          <Route element={<MyProfile />} path={ROUTES.PROFILE.OVERVIEW} />
          <Route element={<EditProfile />} path={ROUTES.PROFILE.EDIT} />
          <Route element={<Home />} path={ROUTES.HOME} />

          <Route element={<Messages />} path={ROUTES.MESSAGES} />

          <Route
            element={<DocumentCenterOverview />}
            path={ROUTES.DOCUMENT_CENTER.OVERVIEW}
          />
          <Route element={<LabsDetails />} path={ROUTES.DOCUMENT_CENTER.LABS} />

          <Route
            element={<DoctorNotes />}
            path={ROUTES.DOCUMENT_CENTER.DOCTOR_NOTES}
          />
          <Route
            element={<PrescriptionsDetails />}
            path={ROUTES.DOCUMENT_CENTER.PRESCRIPTIONS}
          />
          <Route
            element={<MedicalRecordsDetails />}
            path={ROUTES.DOCUMENT_CENTER.MEDICAL_RECORDS}
          />

          {hasActiveWLSubscription && (
            <Route
              element={<FridaysResources />}
              path={ROUTES.DOCUMENT_CENTER.FRIDAYS_RESOURCES}
            />
          )}
        </Route>

        <Route path="*" element={<Navigate replace to={ROUTES.BASE} />} />
      </Routes>

      <ModalsRoutesWrapper />
    </>
  );
};
