import { useNavigate } from "react-router-dom";

import { NavigationButtons } from "@/domains/onboarding/components";
import { useCategoriesForm } from "@/domains/onboarding/hooks";
import { ONBOARDING_ROUTE } from "@/domains/onboarding/router";
import { useOnboardingStore } from "@/domains/onboarding/stores";
import {
  categoriesVariants,
  CategoryCard,
} from "@/domains/products/components";
import type { Category } from "@/domains/products/models";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { Form, showcaseCardVariants } from "@/ui";

const { container, list, card, errorMessage } = categoriesVariants();
const categoryCardClassName = showcaseCardVariants().clickableRoot({
  className: card(),
});

interface CategoriesSelectionProps {
  categories: Category[];
}

export const CategoriesSelection = ({
  categories,
}: CategoriesSelectionProps) => {
  const setSelectedCategories = useOnboardingStore(
    (state) => state.setSelectedCategories,
  );

  const form = useCategoriesForm();

  const navigate = useNavigate();

  const handleContinue = form.handleSubmit(({ categoryIds }) => {
    const selectedCategories = categoryIds
      .map((id) => categories?.find((category) => category.id === id))
      .filter((category) => category !== undefined);

    setSelectedCategories(selectedCategories);

    const hasWeightLossSelected = selectedCategories.some(
      (category) => category.slug === CATEGORY_SLUG.WEIGHT_LOSS,
    );

    if (hasWeightLossSelected) {
      return navigate(ONBOARDING_ROUTE.TREATMENT_BMI);
    }

    navigate(ONBOARDING_ROUTE.TREATMENT_CHOOSE_PRODUCTS);
  });

  const handleBack = () => {
    navigate(ONBOARDING_ROUTE.MAIN_INFO);
  };

  return (
    <Form.Provider {...form}>
      <form className={container()}>
        <Form.Field
          defaultValue={[]}
          control={form.control}
          name="categoryIds"
          render={({ field }) => (
            <Form.Item className="grow">
              <div className={list({ singleCol: categories.length === 1 })}>
                {categories.map((category) => (
                  <Form.Field
                    key={category.id}
                    control={form.control}
                    name="categoryIds"
                    render={() => {
                      return (
                        <Form.Item key={category.id}>
                          <Form.Control>
                            <CategoryCard
                              category={category}
                              checked={field.value?.includes(category.id)}
                              defaultChecked={field.value?.includes(
                                category.id,
                              )}
                              onCheckedChange={(checked) => {
                                return checked
                                  ? field.onChange([
                                      ...field.value,
                                      category.id,
                                    ])
                                  : field.onChange(
                                      field.value?.filter(
                                        (value) => value !== category.id,
                                      ),
                                    );
                              }}
                              className={categoryCardClassName}
                            />
                          </Form.Control>
                        </Form.Item>
                      );
                    }}
                  />
                ))}
              </div>

              <Form.Message className={errorMessage()} />
            </Form.Item>
          )}
        />

        <NavigationButtons onCancel={handleBack} onContinue={handleContinue} />
      </form>
    </Form.Provider>
  );
};
