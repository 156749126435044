import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { usePatientCategories } from "@/domains/products/hooks";
import { Button, Header } from "@/ui";
import { BILLING_ROUTE } from "../router";

interface ExploreSubscriptionsButtonProps {
  isHeaderButton?: boolean;
}

export const ExploreSubscriptionsButton = ({
  isHeaderButton,
}: ExploreSubscriptionsButtonProps) => {
  const { data: categories, isError, isLoading } = usePatientCategories();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(BILLING_ROUTE.EXPLORE_SUBSCRIPTIONS, { state: categories });
  };

  if (isError) {
    return null;
  }

  if (isHeaderButton) {
    return (
      <Header.Actions className="hidden md:block">
        <Button
          disabled={isLoading}
          onClick={handleClick}
          color="brown"
          size="lg"
          className="w-full disabled:cursor-wait md:w-fit"
        >
          {t`Explore subscriptions`}
        </Button>
      </Header.Actions>
    );
  }

  return (
    <div className="sticky bottom-0 -m-6 mt-1 bg-salmon-01 p-6 py-5 shadow-modal-footer md:hidden">
      <Button
        disabled={isLoading}
        onClick={handleClick}
        size="lg"
        className="w-full disabled:cursor-wait md:w-fit"
      >
        {t`Explore subscriptions`}
      </Button>
    </div>
  );
};
