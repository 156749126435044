import { z } from "zod";

import type { ServiceResponse } from "@/api";
import { privateAPI } from "@/api";
import { productSchema } from "../models";
import type { Product } from "../models";

export const getAvailableProductsToSubscribe = async () => {
  const { data } = await privateAPI.get<ServiceResponse<Product[]>>(
    "/patients/available-products-to-subscribe",
  );

  return z.array(productSchema).parse(data.data);
};
