import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { MODAL_ROUTES, ROUTES, useNavigateModal } from "@/router";
import { usePortalUserStore } from "@/stores";
import { DocumentsIcon, FolderCheckIcon, Header, IconWrapper } from "@/ui";

export const MedicalRecordsDetailsHeader = () => {
  const navigateModal = useNavigateModal();
  const navigate = useNavigate();

  const hasActiveWLSubscription = usePortalUserStore(
    (state) => state.hasActiveWLSubscription,
  );

  return (
    <Header.Root variant="secondary">
      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.DOCUMENT_CENTER.OVERVIEW,
              icon: DocumentsIcon,
              label: t`Document center`,
            },
            {
              href: ROUTES.DOCUMENT_CENTER.DOCTOR_NOTES,
              icon: FolderCheckIcon,
              label: t`Medical records`,
            },
          ]}
        />

        <div className="flex items-center justify-between gap-6">
          <div className="flex items-center gap-2">
            <Header.GoBackButton
              onClick={() => navigate(ROUTES.DOCUMENT_CENTER.OVERVIEW)}
              className="md:hidden"
            />
            <Header.Title>{t`Medical records`}</Header.Title>
          </div>

          {hasActiveWLSubscription && (
            <Header.Actions className="hidden md:flex">
              <Header.Button
                onClick={() =>
                  navigateModal(MODAL_ROUTES.DOCUMENT_CENTER.MEDICAL_RECORDS)
                }
              >
                <IconWrapper size="sm">
                  <FolderCheckIcon />
                </IconWrapper>
                {t`Add medical records`}
              </Header.Button>
            </Header.Actions>
          )}
        </div>

        <Header.Description>{t`Your dietitian notes, medical records and more.`}</Header.Description>
      </Header.Content>
    </Header.Root>
  );
};
