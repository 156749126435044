import { t } from "ttag";

import { OnboardingHeader } from "@/domains/onboarding/components";
import { BMIForm } from "@/domains/onboarding/sections";

export const BMICalculation = () => {
  return (
    <>
      <OnboardingHeader
        title={t`Your main info`}
        description={t`(*) Mandatory field`}
      />

      <BMIForm />
    </>
  );
};
