import { useQuery } from "@tanstack/react-query";

import { billingQueriesStore } from "../api";
import type { Subscription } from "../models";

export const useSubscriptionDowngrade = (
  subscriptionId?: Subscription["id"],
) => {
  return useQuery(
    billingQueriesStore.stripe.subscriptions._ctx.downgrade(subscriptionId),
  );
};
