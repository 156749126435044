import type { ComponentPropsWithoutRef } from "react";
import type { Control } from "react-hook-form";
import { t } from "ttag";

import { Form } from "@/ui";
import { Checkbox } from "@/ui/form/Checkbox";

interface FieldValue {
  authorization?: boolean;
}

type CreditCardAuthorizationControl<T> = T extends FieldValue
  ? Control<T, "authorization">
  : never;

type CreditCardAuthorizationFieldProps<T extends FieldValue> =
  ComponentPropsWithoutRef<typeof Form.Item> & {
    control: CreditCardAuthorizationControl<T>;
  };
export const CreditCardAuthorizationField = <T extends FieldValue>({
  control,
  ...props
}: CreditCardAuthorizationFieldProps<T>) => {
  return (
    <Form.Field
      control={control}
      name="authorization"
      render={({ field: { value, onChange, ...rest } }) => (
        <Form.Item {...props}>
          <div className="flex items-start gap-2.5">
            <Form.Control>
              <Checkbox {...rest} checked={value} onCheckedChange={onChange} />
            </Form.Control>

            <Form.Label className="text-brown-08">
              {t`I authorize the use of this credit card for my plan charges`}
            </Form.Label>
          </div>

          <Form.Message compact={false} />
        </Form.Item>
      )}
    />
  );
};
