import { Navigate, useNavigate } from "react-router-dom";
import { t } from "ttag";

import {
  NavigationButtons,
  OnboardingHeader,
  SelectedProducts,
} from "@/domains/onboarding/components";
import { ONBOARDING_ROUTE } from "@/domains/onboarding/router";
import { useOnboardingStore } from "@/domains/onboarding/stores";

export const Summary = () => {
  const selectedCategories = useOnboardingStore(
    (state) => state.selectedCategories,
  );
  const categoriesWithProduct = selectedCategories.filter(
    (category) => category.selectedProduct,
  );

  const navigate = useNavigate();

  if (!categoriesWithProduct.length) {
    return <Navigate to={ONBOARDING_ROUTE.TREATMENT_CHOOSE_PRODUCTS} />;
  }

  const handleBack = () => {
    navigate(ONBOARDING_ROUTE.TREATMENT_CHOOSE_PRODUCTS);
  };

  const handleContinue = () => {
    navigate(ONBOARDING_ROUTE.CHECKOUT);
  };

  return (
    <>
      <OnboardingHeader title={t`Here's your summary`} />

      <div className="flex grow justify-center">
        <SelectedProducts />
      </div>

      <NavigationButtons onCancel={handleBack} onContinue={handleContinue} />
    </>
  );
};
