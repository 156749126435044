import * as RadioGroup from "@radix-ui/react-radio-group";

import { useAnalyticsContext } from "@/contexts/AnalyticsContext";
import { analyticsProductClicked } from "@/domains/analytics/utils";
import { useProductForm } from "@/domains/onboarding/hooks";
import { useOnboardingStore } from "@/domains/onboarding/stores";
import {
  ProductCard,
  ProductItem,
  ProductList,
} from "@/domains/products/components";
import type { Product } from "@/domains/products/models";
import { Form, showcaseCardVariants } from "@/ui";

const radioGroupItemClassName = showcaseCardVariants().clickableRoot();

interface ProductSelectionProps {
  products: Product[];
}

export const ProductSelection = ({ products }: ProductSelectionProps) => {
  const { analytics } = useAnalyticsContext();
  const addProductToCategory = useOnboardingStore(
    (state) => state.addSelectedProduct,
  );

  const form = useProductForm(products[0]?.category.id);

  const handleProductSelect = (productId: Product["id"]) => {
    const selectedProduct = products.find(
      (product) => product.id === productId,
    );

    if (selectedProduct) {
      addProductToCategory(selectedProduct);
      analyticsProductClicked(analytics, selectedProduct);
    }
  };

  return (
    <Form.Provider {...form}>
      <Form.Field
        control={form.control}
        name="productId"
        render={({ field: { value, onChange, ...rest } }) => (
          <RadioGroup.Root
            {...rest}
            defaultValue={String(value)}
            onValueChange={(v) => {
              onChange(v);
              handleProductSelect(Number(v));
            }}
            className="grow"
          >
            <ProductList>
              {products.map((product, _, array) => (
                <ProductItem key={product.id} length={array.length}>
                  <RadioGroup.Item
                    value={String(product.id)}
                    className={radioGroupItemClassName}
                  >
                    <ProductCard product={product} />
                  </RadioGroup.Item>
                </ProductItem>
              ))}
            </ProductList>
          </RadioGroup.Root>
        )}
      />
    </Form.Provider>
  );
};
