import { t } from "ttag";

import { useActiveSubscriptions } from "@/domains/billing/hooks";
import { getPeriodDurationText } from "@/domains/billing/utils";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { env } from "@/env";
import { useBoolean } from "@/hooks";
import { Button, ConfettiFixedIconMono, Dialog } from "@/ui";
import { ChangeSubscriptionConfirmationModal } from "./ChangeSubscriptionConfirmationModal";
import { KeepCurrentSubscriptionModal } from "./KeepCurrentSubscriptionModal";

export const ChangePlanFridaysModal = () => {
  const { data: activeSubscriptions } = useActiveSubscriptions();

  const showRequestedChangeByFridays = activeSubscriptions?.some(
    (subscription) =>
      subscription.requestedProduct?.price.subscriptionPriceId ===
      env.VITE_STRIPE_BRANDED_MONTHLY_PRICE_ID,
  );

  const activeWLSubscription = activeSubscriptions?.find(
    (subscription) =>
      subscription.currentProduct.category.slug === CATEGORY_SLUG.WEIGHT_LOSS,
  );

  const { value: showModal, setFalse: onClose } = useBoolean(true);
  const {
    value: showConfirmationModal,
    setTrue: openConfirmationModal,
    setFalse: closeConfirmationModal,
  } = useBoolean(false);
  const {
    value: showKeepModal,
    setTrue: openKeepModal,
    setFalse: closeKeepModal,
  } = useBoolean(false);

  const periodDuration = activeWLSubscription?.currentProduct
    ? getPeriodDurationText(
        activeWLSubscription?.currentProduct.price.durationInMonths,
      )
    : "";

  return (
    <Dialog.Root
      open={showRequestedChangeByFridays && showModal}
      onOpenChange={onClose}
    >
      {activeWLSubscription?.currentProduct && (
        <Dialog.Content size="modal" className="h-fit">
          <div className="flex h-full flex-col gap-6">
            <Dialog.Header>
              <Dialog.Icon>
                <ConfettiFixedIconMono color="salmon" />
              </Dialog.Icon>
              <Dialog.Title>{t`Subscription options available`}</Dialog.Title>
              <Dialog.Description>
                {t`You're currently on a ${activeWLSubscription.currentProduct.name} subscription for $${activeWLSubscription.currentProduct.price.price}/${periodDuration}. Since you were recently prescribed Branded medication, you might want to switch to a subscription that matches your current needs.`}
              </Dialog.Description>
            </Dialog.Header>

            <Dialog.Footer variant="sticky">
              <Button
                onClick={openKeepModal}
                size="lg"
                variant="secondary"
              >{t`Keep Current Subscription`}</Button>
              <Button
                onClick={openConfirmationModal}
                size="lg"
              >{t`Change Subscription`}</Button>
            </Dialog.Footer>
          </div>

          <KeepCurrentSubscriptionModal
            show={showKeepModal}
            onClose={closeKeepModal}
            onConfirm={() => {
              onClose();
              closeKeepModal();
            }}
          />
          <ChangeSubscriptionConfirmationModal
            show={showConfirmationModal}
            onClose={closeConfirmationModal}
            onConfirm={() => {
              onClose();
              closeConfirmationModal();
            }}
          />
        </Dialog.Content>
      )}
    </Dialog.Root>
  );
};
