import { useActiveSubscriptions } from "@/domains/billing/hooks";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { env } from "@/env";

export const useCanBookCounseling = () => {
  const { data: activeSubscriptions, ...query } = useActiveSubscriptions();

  const wlActiveSubscription = activeSubscriptions?.find(
    (subscription) =>
      subscription.currentProduct.category.slug === CATEGORY_SLUG.WEIGHT_LOSS,
  );

  const hasNewSubscription =
    wlActiveSubscription?.currentProduct.price.subscriptionPriceId !==
    env.VITE_STRIPE_BASE_PRICE_ID;

  const hasScheduledLegacyChange =
    wlActiveSubscription?.nextPeriodProduct?.price.subscriptionPriceId !==
    env.VITE_STRIPE_BASE_PRICE_ID;

  return { ...query, data: hasNewSubscription || hasScheduledLegacyChange };
};
