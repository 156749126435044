import type { PropsWithChildren } from "react";
import { tv } from "tailwind-variants";

import { useIsMobile } from "@/shared/hooks";
import { Carousel } from "@/ui";

const productItemVariants = tv({
  base: "md:basis-2/5",
  variants: {
    length: {
      1: "mx-auto",
      2: "md:basis-1/2",
    },
  },
});

type ProductItemProps = PropsWithChildren & { length: number };

export const ProductItem = ({ length, children }: ProductItemProps) => {
  const isMobile = useIsMobile();

  const ItemComponent = isMobile ? "div" : Carousel.Item;

  return (
    <ItemComponent
      className={productItemVariants({
        length: length as 1 | 2,
      })}
    >
      {children}
    </ItemComponent>
  );
};
