import type { RouteObject } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { PortalRoutesWrapper } from "@/shared/components";
import { IntakeFormContextProvider } from "../context";
import {
  AssessmentForm,
  ChooseJourney,
  MedicationRequest,
  SuccessScreen,
  VideoConsultation,
} from "../screens";
import { INTAKE_FORM_ROUTE } from "./constants";

export const getIntakeFormRoute = (): RouteObject => ({
  path: INTAKE_FORM_ROUTE.BASE,
  element: (
    <IntakeFormContextProvider>
      <PortalRoutesWrapper />
    </IntakeFormContextProvider>
  ),

  children: [
    { path: INTAKE_FORM_ROUTE.CHOOSE_JOURNEY, element: <ChooseJourney /> },

    {
      path: INTAKE_FORM_ROUTE.SCHEDULE_VIDEO_CONSULTATION,
      element: <VideoConsultation />,
    },

    {
      path: INTAKE_FORM_ROUTE.MEDICATION_REQUEST,
      element: <MedicationRequest />,
    },

    {
      path: INTAKE_FORM_ROUTE.ASSESSMENT_FORM,
      element: <AssessmentForm />,
    },

    {
      path: INTAKE_FORM_ROUTE.SUCCESS,
      element: <SuccessScreen />,
    },

    {
      index: true,
      element: <Navigate replace to={INTAKE_FORM_ROUTE.CHOOSE_JOURNEY} />,
    },
    { path: "*", element: <Navigate replace to={INTAKE_FORM_ROUTE.BASE} /> },
  ],
});
