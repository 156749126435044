import { z } from "zod";

import { couponSchema, DURATION_IN_MONTHS } from "@/domains/billing/models";
import { CATEGORY_SLUG } from "./constants";

export const categorySchema = z.object({
  id: z.number(),
  slug: z.nativeEnum(CATEGORY_SLUG),
  name: z.string(),
  description: z.string().optional(),
  treatments: z.array(z.string()),
  isNew: z.boolean(),
});

const tagSchema = z.object({ label: z.string(), type: z.string() });

export const priceSchema = z.object({
  id: z.number(),
  durationInMonths: z.nativeEnum(DURATION_IN_MONTHS),
  subscriptionPriceId: z.string(),
  price: z.number(),
  // TODO: review discount schema (the only type of discount is a coupon?)
  discounts: z.array(z.lazy(() => couponSchema)),
  tags: z.array(tagSchema),
  dose: z.string().optional(),
  isSubscribed: z.boolean(),
});

export const productSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().optional(),
  features: z.array(z.string()),
  disclaimer: z.string(),
  thumbnail: z.string().url(),
  category: categorySchema.pick({ id: true, name: true, slug: true }),
  prices: z.array(priceSchema),
  slug: z.string(),
  tags: z.array(tagSchema),
  priceTag: z.string().optional(),
});
