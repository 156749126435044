import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import type { Category } from "@/domains/products/models";
import { productSchema } from "@/domains/products/models";
import { useOnboardingStore } from "../stores";

export const useProductForm = (categoryId?: Category["id"]) => {
  const productId = useOnboardingStore(
    (state) =>
      state.selectedCategories.find((category) => category.id === categoryId)
        ?.selectedProduct?.id,
  );

  const formSchema = z.object({
    productId: productSchema.shape.id,
  });

  return useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { productId },
  });
};
