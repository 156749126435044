import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PORTAL_DOMAIN } from "@/api";
import { errorToast } from "@/ui";
import {
  medicationRequestQueriesStore,
  updateMedicationRequestStatus,
} from "../api";

export const useUpdateMedicationStatus = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateMedicationRequestStatus,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: medicationRequestQueriesStore.medicationRequest._def,
      });
      void queryClient.invalidateQueries({ queryKey: [PORTAL_DOMAIN] });
    },
    onError: errorToast,
  });
};
