import { useState } from "react";
import type { CheckedState } from "@radix-ui/react-checkbox";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { Link } from "react-router-dom";
import { jt, t } from "ttag";

import { ErrorState, FullScreenLoader } from "@/components";
import {
  useSetSubscriptionDowngrade,
  useSubscriptionDowngrade,
} from "@/domains/billing/hooks";
import type { Subscription } from "@/domains/billing/models";
import { ROUTES } from "@/router";
import type { ModalProps } from "@/shared.types";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  Label,
  LightbulbFixedIconMono,
  Loader,
} from "@/ui";
import { tw } from "@/utils";
import { NewSubscriptionCard } from "./NewSubscriptionCard";

interface SubscriptionDowngradeModalProps extends ModalProps {
  subscriptionId: Subscription["id"];
  onContinue: () => void;
}

export const SubscriptionDowngradeModal = ({
  show,
  subscriptionId,
  onClose,
  onContinue,
}: SubscriptionDowngradeModalProps) => {
  const [hasConsent, setHasConsent] = useState<CheckedState>(false);

  const setSubscriptionDowngradeMutation = useSetSubscriptionDowngrade();

  const {
    data: downgradeSubscription,
    isLoading,
    isSuccess,
    isError,
  } = useSubscriptionDowngrade(subscriptionId);

  const handleContinue = () => {
    setSubscriptionDowngradeMutation.mutate(subscriptionId, {
      onSuccess: () => {
        onClose();
        onContinue();
      },
    });
  };

  const linkToMessages = (
    <Link key={ROUTES.MESSAGES} to={ROUTES.MESSAGES} className="underline">
      {t`send us a message`}
    </Link>
  );

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content
        size="modal"
        className={tw((isLoading || isError) && "h-fit")}
      >
        {!isSuccess && (
          <>
            <VisuallyHidden asChild>
              <Dialog.Title>{t`Subscription Update Needed`}</Dialog.Title>
            </VisuallyHidden>

            <VisuallyHidden asChild>
              <Dialog.Description>
                {t`To stay aligned with our policies, we'll need to switch your subscription from 3 months to 1 month. No worries—this won't affect your access to anything; it's just a quick update to keep everything on track!`}
              </Dialog.Description>
            </VisuallyHidden>
          </>
        )}

        {isLoading && (
          <div className="flex min-h-48 items-center justify-center">
            <Loader />
          </div>
        )}

        {isError && <ErrorState className="border-0" />}

        {isSuccess && (
          <div className="flex flex-col gap-6">
            <Dialog.Header>
              <Dialog.Icon>
                <LightbulbFixedIconMono />
              </Dialog.Icon>

              <Dialog.Title>{t`Subscription Update Needed`}</Dialog.Title>

              <Dialog.Description>
                {t`To stay aligned with our policies, we'll need to switch your subscription from 3 months to 1 month. No worries—this won't affect your access to anything; it's just a quick update to keep everything on track!`}
              </Dialog.Description>
            </Dialog.Header>

            <Alert>
              {jt`You'll receive a partial refund to the card on file to cover the difference in price with your subscription. If you need additional help, please ${linkToMessages} in the portal.`}
            </Alert>

            <NewSubscriptionCard newSubscription={downgradeSubscription} />

            <div className="flex gap-2.5 pt-2">
              <Checkbox
                id="subscription-update-consent"
                checked={hasConsent}
                onCheckedChange={setHasConsent}
              />

              <Label htmlFor="subscription-update-consent">
                {t`I agree to the new charge and understand the changes`}
              </Label>
            </div>

            <Dialog.Footer variant="sticky">
              <Button
                disabled={!hasConsent || isLoading}
                onClick={handleContinue}
                size="lg"
                className="w-full md:w-fit"
              >
                {t`Approve change`}
              </Button>
            </Dialog.Footer>
          </div>
        )}

        {setSubscriptionDowngradeMutation.isPending && <FullScreenLoader />}
      </Dialog.Content>
    </Dialog.Root>
  );
};
