import { createQueryKeyStore } from "@lukemorales/query-key-factory";

import { getStateOptions } from "./location";

export const queriesStore = createQueryKeyStore({
  location: {
    options: {
      queryKey: null,
      contextQueries: {
        state: {
          queryKey: null,
          queryFn: getStateOptions,
        },
      },
    },
  },
});
