import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import { getPricesIds } from "@/domains/onboarding/utils";
import { ScreenLayout } from "@/layouts";
import { AltArrowLeftIcon, AltArrowRightIcon, Button } from "@/ui";
import { useExploreSubscriptionsContext } from "../context";
import { useCreatePatientSubscriptions } from "../hooks";
import { BILLING_ROUTE } from "../router";
import {
  ExploreSubscriptionsChooseProducts,
  ExploreSubscriptionsHeader,
  ProductsSummary,
} from "../sections";

const STEP = {
  PRODUCTS_SELECTION: "products-selection",
  PRODUCTS_SUMMARY: "products-summary",
} as const;
type StepType = (typeof STEP)[keyof typeof STEP];

export const ExploreSubscriptions = () => {
  const [step, setStep] = useState<StepType>(STEP.PRODUCTS_SELECTION);
  const { categories } = useExploreSubscriptionsContext();

  const createSubscriptions = useCreatePatientSubscriptions();

  const navigate = useNavigate();

  const handleBack = () => {
    switch (step) {
      case STEP.PRODUCTS_SELECTION:
        return navigate(BILLING_ROUTE.BASE);
      case STEP.PRODUCTS_SUMMARY:
        return setStep(STEP.PRODUCTS_SELECTION);
    }
  };

  const handleContinue = () => {
    switch (step) {
      case STEP.PRODUCTS_SELECTION:
        return setStep(STEP.PRODUCTS_SUMMARY);
      case STEP.PRODUCTS_SUMMARY:
        return createSubscriptions.mutate(getPricesIds(categories));
    }
  };

  const checkoutDisabled = !categories.filter(
    (category) => category.selectedProduct,
  ).length;

  return (
    <ScreenLayout className="md:gap-0">
      <ExploreSubscriptionsHeader />

      <div className="flex grow flex-col">
        {step === STEP.PRODUCTS_SELECTION && (
          <ExploreSubscriptionsChooseProducts />
        )}

        {step === STEP.PRODUCTS_SUMMARY && (
          <ProductsSummary
            onEmptyProducts={() => setStep(STEP.PRODUCTS_SELECTION)}
          />
        )}
      </div>

      <div className="flex flex-col-reverse items-stretch justify-between gap-4 md:flex-row md:items-center">
        <Button onClick={handleBack} variant="secondary" size="lg">
          <AltArrowLeftIcon className="hidden size-4 md:block" />
          {t`Back`}
        </Button>

        <Button
          disabled={checkoutDisabled}
          onClick={handleContinue}
          type="submit"
          size="lg"
        >
          {step === STEP.PRODUCTS_SUMMARY ? t`Confirm and pay` : t`Checkout`}
          {step === STEP.PRODUCTS_SELECTION && (
            <AltArrowRightIcon className="hidden size-4 md:block" />
          )}
        </Button>
      </div>

      {createSubscriptions.isPending && <FullScreenLoader />}
    </ScreenLayout>
  );
};
