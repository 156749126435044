import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";
import { z } from "zod";

import { JourneyRadioGroup } from "@/domains/intake-form/components";
import { useJourneyRequirements } from "@/domains/intake-form/hooks";
import { ScreenLayout } from "@/layouts";
import { JOURNEY } from "@/shared.constants";
import { AltArrowLeftIcon, AltArrowRightIcon, Button, Form } from "@/ui";
import { useIntakeFormContext } from "../context";
import { INTAKE_FORM_ROUTE } from "../router";

export const JourneySelection = () => {
  const {
    isAsync,
    actions: { setIsAsync, setVideoConsultationSchedule },
  } = useIntakeFormContext();

  const formSchema = z.object({
    isAsync: z.boolean({ required_error: t`Please select your journey` }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { isAsync },
  });

  const { hasPreviousRequestFilter } = useJourneyRequirements();

  const navigate = useNavigate();

  const handleSubmit = form.handleSubmit(({ isAsync }) => {
    setIsAsync(isAsync);

    if (isAsync) {
      if (hasPreviousRequestFilter) {
        setVideoConsultationSchedule(undefined);
        return navigate(INTAKE_FORM_ROUTE.ASSESSMENT_FORM);
      }

      return navigate(INTAKE_FORM_ROUTE.MEDICATION_REQUEST);
    }

    return navigate(INTAKE_FORM_ROUTE.SCHEDULE_VIDEO_CONSULTATION);
  });

  return (
    <ScreenLayout
      hasCardHeader={false}
      variant="nature"
      title={t`How would you like to kick-start your journey?`}
    >
      <Form.Provider {...form}>
        <form
          onSubmit={handleSubmit}
          className="flex h-full flex-col items-center gap-14"
        >
          <div className="flex w-full max-w-xl grow flex-col gap-6">
            <Form.Field
              control={form.control}
              name="isAsync"
              render={({ field: { value, onChange, ...rest } }) => (
                <Form.Item>
                  <div className="flex gap-2.5">
                    <Form.Control>
                      <JourneyRadioGroup
                        value={
                          value !== undefined
                            ? value
                              ? JOURNEY.MEDICATION_REQUEST
                              : JOURNEY.VIDEO_CONSULTATION
                            : undefined
                        }
                        onValueChange={(journey) =>
                          onChange(journey === JOURNEY.MEDICATION_REQUEST)
                        }
                        {...rest}
                      />
                    </Form.Control>
                  </div>
                  <Form.Message className="text-center text-red-03" />
                </Form.Item>
              )}
            />

            <div className="flex flex-col gap-5 text-center text-nature-02 sm:text-xl">
              <p>
                {t`You can choose to have a video visit or order medication without a visit. If you're unsure which medication might be right for you, book a video visit and select any medication at checkout.`}
              </p>
              <p>
                {t`Then, you and your provider can talk it through and change afterwards if needed!`}
              </p>
            </div>
          </div>

          <div className="flex w-full flex-col-reverse items-center gap-y-4 sm:flex-row sm:justify-between">
            <Button
              onClick={() => navigate(-1)}
              variant="cover-secondary"
              size="lg"
            >
              <AltArrowLeftIcon className="hidden size-4 sm:block" />
              {t`Back`}
            </Button>

            <Button type="submit" size="lg" variant="cover-primary">
              {t`Next`}
              <AltArrowRightIcon className="hidden size-4 sm:block" />
            </Button>
          </div>
        </form>
      </Form.Provider>
    </ScreenLayout>
  );
};
