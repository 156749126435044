import { Link } from "react-router-dom";
import { t } from "ttag";

import {
  EmptyMedicationRequestCard,
  MedicationRequestCard,
} from "@/domains/my-meds/components";
import { useCurrentMedicationRequests } from "@/domains/my-meds/hooks";
import { ROUTES } from "@/router";
import { usePortalUserStore } from "@/stores";
import { CardPrimitive as Card, CardSkeleton, PillIcon } from "@/ui";

export const CurrentMedicationRequests = () => {
  const hasIncompletedIntakeForms = usePortalUserStore(({ portalUser }) =>
    Boolean(
      !portalUser ||
        portalUser.subscriptions.some(
          ({ intakeFormCompleted }) => !intakeFormCompleted,
        ),
    ),
  );

  const {
    data: currentRequests,
    isLoading,
    isSuccess,
  } = useCurrentMedicationRequests();

  if (hasIncompletedIntakeForms) {
    return null;
  }

  return (
    <Card.Root className="flex-1 bg-salmon-01">
      <Card.Header>
        <Card.Title>
          <PillIcon className="size-4" />
          {t`Medication refill requests`}
        </Card.Title>
        <Link
          to={ROUTES.MY_MEDS}
          className="hidden font-medium text-nature-08 md:block"
        >
          {t`View all requests`}
        </Link>
      </Card.Header>

      <Card.Content className="flex flex-col gap-3">
        {isLoading && <CardSkeleton className="bg-salmon-01 px-2" />}

        {isSuccess && (
          <div className="flex flex-col gap-3">
            {currentRequests.length ? (
              currentRequests.map((request) => (
                <MedicationRequestCard
                  key={request.subscription.id}
                  className="border-transparent bg-brown-02 md:border-salmon-07 md:bg-transparent"
                  medicationRequest={request}
                />
              ))
            ) : (
              <EmptyMedicationRequestCard />
            )}
          </div>
        )}

        <div className="flex w-full items-center justify-center pb-2 md:hidden">
          <Link to={ROUTES.MY_MEDS} className="font-medium text-nature-08">
            {t`View all requests`}
          </Link>
        </div>
      </Card.Content>
    </Card.Root>
  );
};
