import { z } from "zod";

import { subscriptionSchema } from "@/domains/billing/models";
import {
  categorySchema,
  priceSchema,
  productSchema,
} from "@/domains/products/models";
import { MEDICATION_REQUEST_STATUS } from "./constants";

export const medicationRequestSchema = z.object({
  dateOfRequest: z.string().date(),
  status: z.nativeEnum(MEDICATION_REQUEST_STATUS),
  trackingNumber: z.string().nullable(),
  drugName: z.string().nullable(),
  dose: z.string().nullable(),
  supply: z.string().nullable(),
  resolution: z.string().nullable(),
  product: productSchema.pick({ id: true, name: true, slug: true }).extend({
    category: categorySchema.pick({ id: true, name: true, slug: true }),
  }),
  subscription: subscriptionSchema.pick({ id: true }),
});

export const refillRequestSchema = z.object({
  id: z.number(),
  dateOfRequest: z.string().date(),
  product: productSchema
    .pick({
      id: true,
      name: true,
      slug: true,
      description: true,
      disclaimer: true,
    })
    .extend({
      category: categorySchema.pick({
        id: true,
        name: true,
        slug: true,
        description: true,
        isNew: true,
      }),
    }),
  price: priceSchema.pick({ id: true, dose: true }),
});

export const availableRefillRequestSchema = z.object({
  product: medicationRequestSchema.shape.product,
  dosis: z.string().nullable(),
  isRefillable: z.boolean(),
  frequency: z.string(),
  hasUpcomingAppointment: z.boolean(),
});

export const deliveredMedicationSchema = z.object({
  id: refillRequestSchema.shape.id,
  delivered: z.boolean(),
});
