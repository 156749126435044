import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from "react";
import { forwardRef } from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { tw } from "@/utils";
import { CloseIcon } from "../icons";
import { ScrollArea } from "./ScrollArea";

const Root = DialogPrimitive.Root;
Root.displayName = "Dialog.Root";

const Trigger = DialogPrimitive.Trigger;
Trigger.displayName = "Dialog.Trigger";

const Overlay = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={tw(
      "fixed inset-0 z-50 bg-black/60 backdrop-blur-sm !duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
    )}
    {...props}
  />
));
Overlay.displayName = "Dialog.Overlay";

const CloseButton = forwardRef<
  ElementRef<typeof DialogPrimitive.Close>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Close>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Close
    ref={ref}
    className={tw(
      "absolute right-3.5 top-2.5 z-10 size-7 rounded-full bg-salmon-01 p-1 text-nature-10 ring-gray-400 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:pointer-events-none",
      className,
    )}
    {...props}
  >
    <CloseIcon />
    <span className="sr-only">Close</span>
  </DialogPrimitive.Close>
));
CloseButton.displayName = "Dialog.CloseButton";

const contentVariance = tv({
  slots: {
    base: "!pointer-events-none fixed left-0 top-0 z-50 flex size-full max-h-svh w-full items-end justify-center overflow-hidden p-0 pt-8 !duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-30 data-[state=open]:fade-in-30 data-[state=closed]:slide-out-to-bottom-[90%] data-[state=open]:slide-in-from-bottom-[50%] sm:items-center sm:p-8 sm:!duration-150 sm:data-[state=closed]:zoom-out-95 sm:data-[state=open]:zoom-in-95 sm:data-[state=closed]:slide-out-to-bottom-0 sm:data-[state=open]:slide-in-from-bottom-0",
    container: "relative size-full",
    content:
      "pointer-events-auto rounded-t-2xl bg-salmon-01 p-8 px-6 text-brown-09 shadow-lg sm:rounded-2xl",
    closeButton: "",
  },
  variants: {
    scrollable: {
      false: {
        container:
          "overflow-hidden rounded-t-2xl bg-salmon-01 p-0 text-brown-09 shadow-lg sm:rounded-2xl sm:p-0",
      },
    },
    size: {
      modal: {
        base: "absolute h-screen",
        container: "flex items-center sm:max-w-modal",
        content:
          "pointer-events-auto size-full sm:relative sm:h-fit sm:max-h-full sm:pt-0",
        closeButton:
          "sm:sticky sm:left-full sm:top-4 sm:-mr-3 sm:ml-auto sm:self-end",
      },
      full: {},
    },
    withCloseButton: {
      false: {
        content: "pt-8 sm:pt-8",
        closeButton: "hidden",
      },
    },
  },
});

const Content = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content> &
    VariantProps<typeof contentVariance>
>(
  (
    {
      className,
      children,
      size,
      scrollable = true,
      withCloseButton = true,
      ...props
    },
    ref,
  ) => {
    const Component = scrollable ? ScrollArea : "div";
    const { base, container, content, closeButton } = contentVariance({
      size,
      scrollable,
      withCloseButton,
    });
    const restComponentProps = scrollable
      ? { viewportClassName: content() }
      : {};

    return (
      <DialogPrimitive.Portal>
        <Overlay />
        <DialogPrimitive.Content ref={ref} className={base()} {...props}>
          <Component
            className={container({ className })}
            {...restComponentProps}
          >
            <CloseButton className={closeButton()} />
            {children}
          </Component>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    );
  },
);
Content.displayName = DialogPrimitive.Content.displayName;

const Header = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={tw("flex flex-col items-center gap-y-2", className)}
    {...props}
  />
);
Header.displayName = "Dialog.Header";

const Icon = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={tw("size-16 sm:size-20", className)} {...props} />
);
Icon.displayName = "Dialog.Icon";

export const dialogFooterVariance = tv({
  base: "flex flex-col-reverse gap-3.5 sm:flex-row sm:justify-center",
  variants: {
    variant: {
      sticky:
        "sticky -bottom-8 -mx-6 -mb-8 mt-auto bg-salmon-01 p-6 shadow-modal-footer sm:static sm:m-0 sm:bg-transparent sm:p-0 sm:shadow-none",
    },
  },
});

const Footer = ({
  className,
  variant,
  ...props
}: HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof dialogFooterVariance>) => (
  <div className={dialogFooterVariance({ variant, className })} {...props} />
);
Footer.displayName = "Dialog.Footer";

const Title = forwardRef<
  ElementRef<typeof DialogPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={tw(
      "text-center font-serif text-3xl leading-10 text-salmon-10",
      className,
    )}
    {...props}
  />
));
Title.displayName = "Dialog.Title";

const Description = forwardRef<
  ElementRef<typeof DialogPrimitive.Description>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={tw("text-center font-medium text-brown-09", className)}
    {...props}
  />
));
Description.displayName = "Dialog.Description";

export const Dialog = {
  Root,
  Overlay,
  Trigger,
  CloseButton,
  Content,
  Header,
  Icon,
  Footer,
  Title,
  Description,
};
