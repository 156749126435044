import { Link, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { CallToAction, UpcomingConsultations } from "@/components";
import { PastConsultations } from "@/domains/consultations/sections";
import { ScreenLayout } from "@/layouts";
import { BASE_ROUTE, ROUTES } from "@/router";
import { CalendarMinimalisticIcon, ScreenHeader } from "@/ui";

export const Consultations = () => {
  const navigate = useNavigate();

  return (
    <ScreenLayout>
      <ScreenHeader
        title={t`Consultations`}
        paths={[
          {
            href: BASE_ROUTE.CONSULTATIONS,
            icon: CalendarMinimalisticIcon,
            label: t`Consultations`,
          },
        ]}
        actions={
          <Link
            to={ROUTES.CONSULTATIONS.PURPOSE}
            className="flex items-center gap-2 rounded-lg bg-salmon-01 px-4 py-2.5 text-xs font-medium text-brown-10 md:h-auto md:w-auto"
          >
            <CalendarMinimalisticIcon className="hidden size-4 text-brown-07 sm:block" />
            <span className="hidden sm:block">{t`New consultation`}</span>
            <span className="block text-sm sm:hidden">{t`Book`}</span>
          </Link>
        }
      />

      <UpcomingConsultations />

      <CallToAction
        title={t`Want to review your refill medication requests?`}
        description={t`Navigate to the "My Meds" section`}
        buttonText={t`Go to My Meds`}
        onClick={() => navigate(ROUTES.MY_MEDS)}
      />

      <PastConsultations />
    </ScreenLayout>
  );
};
