import { createQueryKeyStore } from "@lukemorales/query-key-factory";

import {
  getCurrentMedicationRequests,
  getPastMedicationRequests,
} from "./list";
import {
  getAvailableRefillRequests,
  getDelayedRefillRequests,
} from "./refill-request";

export const medicationRequestQueriesStore = createQueryKeyStore({
  medicationRequest: {
    list: {
      queryKey: null,
      contextQueries: {
        current: {
          queryKey: null,
          queryFn: getCurrentMedicationRequests,
        },

        past: {
          queryKey: null,
          queryFn: getPastMedicationRequests,
        },

        available: {
          queryKey: null,
          queryFn: getAvailableRefillRequests,
        },

        delayed: {
          queryKey: null,
          queryFn: getDelayedRefillRequests,
        },
      },
    },
  },
});
