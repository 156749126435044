import { createQueryKeyStore } from "@lukemorales/query-key-factory";

import type { HealthieFormParams } from "../models";
import { getHealthieFormFields } from "./healthie";

export const intakeFormQueriesStore = createQueryKeyStore({
  healthie: {
    form: (params: HealthieFormParams) => ({
      queryKey: [{ params }],
      queryFn: () => getHealthieFormFields(params),
      staleTime: Infinity,
    }),
  },
});
