import { t } from "ttag";

import type { AgeGroup } from "@/shared/models";
import { AGE_GROUP } from "@/shared/models";
import { FullScreenAlert, HeartsCircleFixedIconMono } from "@/ui";

const getTitleAndDescription = (ageGroup: AgeGroup) => {
  switch (ageGroup) {
    case AGE_GROUP.YOUNGER_THAN_18:
      return {
        title: t`It looks like you're outside the age range for Fridays`,
        description: t`Fridays is currently available to individuals 18 and older. If you're under 18, you're not eligible to participate just yet. Keep up the great work on your wellness journey—there are plenty of other fantastic options out there to support you!`,
      };
    case AGE_GROUP.OLDER_THAN_74:
      return {
        title: t`It appears that Fridays treatments may not be the best fit for you at the moment`,
        description: t`Your journey to wellness is personal and unique. While Fridays may not align with your current needs, it's just the beginning of discovering what works best for you. Keep exploring, and you'll find the perfect fit.`,
      };
    default:
      return null;
  }
};

interface DQByAgeProps {
  open: boolean;
  ageGroup: AgeGroup;
  onBack: VoidFunction;
}

export const DQByAge = ({ open, ageGroup, onBack }: DQByAgeProps) => {
  const text = getTitleAndDescription(ageGroup);

  if (!text) {
    return null;
  }

  return (
    <FullScreenAlert.Root open={open}>
      <FullScreenAlert.Content>
        <FullScreenAlert.Header>
          <HeartsCircleFixedIconMono className="size-16 md:size-20" />
          <FullScreenAlert.Title>{text.title}</FullScreenAlert.Title>
        </FullScreenAlert.Header>

        <FullScreenAlert.Description>
          {text.description}
        </FullScreenAlert.Description>

        <FullScreenAlert.Footer>
          <FullScreenAlert.Action onClick={onBack}>
            {t`Back`}
          </FullScreenAlert.Action>
        </FullScreenAlert.Footer>
      </FullScreenAlert.Content>
    </FullScreenAlert.Root>
  );
};
