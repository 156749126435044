import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { useScheduleConsultationContext } from "@/contexts";
import { useIntakeFormContext } from "@/domains/intake-form/context";
import { INTAKE_FORM_ROUTE } from "@/domains/intake-form/router";
import { AltArrowLeftIcon, AltArrowRightIcon, Button } from "@/ui";

export const Submit = () => {
  const {
    actions: { setVideoConsultationSchedule },
  } = useIntakeFormContext();

  const {
    language,
    scheduleDate: { timeSlot },
  } = useScheduleConsultationContext();

  const navigate = useNavigate();

  const handleContinue = () => {
    if (timeSlot) {
      setVideoConsultationSchedule({ timeSlot, language });
      navigate(INTAKE_FORM_ROUTE.ASSESSMENT_FORM);
    }
  };

  return (
    <div className="mt-auto flex w-full flex-col-reverse items-center gap-y-4 sm:flex-row sm:justify-between">
      <Button
        onClick={() => navigate(INTAKE_FORM_ROUTE.CHOOSE_JOURNEY)}
        variant="secondary"
        size="lg"
        className="w-full sm:w-fit"
      >
        <AltArrowLeftIcon className="hidden size-4 sm:block" />
        {t`Back`}
      </Button>

      <Button
        disabled={!timeSlot}
        onClick={handleContinue}
        size="lg"
        className="w-full sm:w-fit"
      >
        {t`Confirm Consultation`}
        <AltArrowRightIcon className="hidden size-4 sm:block" />
      </Button>
    </div>
  );
};
