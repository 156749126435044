import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { ErrorState } from "@/components";
import { getPaymentHistoryQuery } from "@/domains/billing/api";
import { icons, IconWrapper, illustratedIcons } from "@/ui";
import { PagelessPagination } from "@/ui/common/PagelessPagination";
import { TableSkeleton } from "@/ui/common/TableSkeleton";
import { PaymentHistoryCards, PaymentHistoryTable } from "./components";

export const PaymentHistory = () => {
  const [lastPaymentInformation, setLastPaymentInformation] =
    useState<string>();
  const [beforeLastPaymentInformation, setBeforeLastPaymentInformation] =
    useState<string>();

  const { data, isLoading, isSuccess, isError } = useQuery(
    getPaymentHistoryQuery(lastPaymentInformation),
  );

  const paymentHistory = data?.data ?? [];
  const pagination = data?.pagination;

  const changeBeforePage = () => {
    setLastPaymentInformation(beforeLastPaymentInformation);
    setBeforeLastPaymentInformation(undefined);
  };

  const changeAfterPage = () => {
    setBeforeLastPaymentInformation(lastPaymentInformation);
    setLastPaymentInformation(paymentHistory?.at(-1)?.id);
  };

  return (
    <>
      <h3 className="flex items-center gap-2 text-lg font-bold">
        <IconWrapper size="sm">
          <icons.Clock />
        </IconWrapper>
        {t`Payment history`}
      </h3>
      <div className="grow">
        {isLoading && <TableSkeleton columns={7} rows={3} />}

        {isSuccess && !paymentHistory.length && (
          <div className="flex flex-col items-center justify-center p-4">
            <illustratedIcons.LightBulb />
            <p className="text-xl font-medium text-brown-05">
              {t`You haven't had any payment yet`}
            </p>
          </div>
        )}

        {isSuccess && Boolean(paymentHistory.length) && (
          <>
            <PaymentHistoryTable payments={paymentHistory} />
            <PaymentHistoryCards payments={paymentHistory} />

            {pagination && pagination.totalPages > 1 && (
              <PagelessPagination
                changeBeforePage={changeBeforePage}
                changeAfterPage={changeAfterPage}
                hasMorePagesBefore={pagination?.hasMorePagesBefore ?? false}
                hasMorePagesAfter={pagination?.hasMorePagesAfter ?? false}
              />
            )}
          </>
        )}

        {isError && <ErrorState />}
      </div>
    </>
  );
};
