import type { PropsWithChildren } from "react";
import { tv } from "tailwind-variants";

import { useIsMobile } from "@/shared/hooks";
import { Carousel } from "@/ui";

const productListVariants = tv({
  slots: {
    root: "w-full",
    content: "flex gap-0 pb-4",
  },
  variants: {
    isMobile: {
      true: {
        content: "flex-col gap-4",
      },
    },
  },
});

export const ProductList = (props: PropsWithChildren) => {
  const isMobile = useIsMobile();

  const { root, content } = productListVariants({ isMobile });

  if (isMobile) {
    return <div className={content()} {...props} />;
  }

  return (
    <Carousel.Root className={root()}>
      <Carousel.Content className={content()} {...props} />

      <Carousel.PreviousButton />
      <Carousel.NextButton />
    </Carousel.Root>
  );
};
