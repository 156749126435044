import { createBrowserRouter, Route, Routes } from "react-router-dom";

import { LoadingScreen } from "@/components";
import {
  CoachingRouter,
  EMBEDDABLES_ONBOARDING_ROUTE,
  EmbeddablesOnboardingRouter,
  getBillingRoute,
  getIntakeFormRoute,
  getMyMedsRoute,
  GuestRouter,
  onboardingRoute,
} from "@/domains";
import { PORTAL_USER_TYPE } from "@/shared/models";
import { useAuthStore, usePortalUserStore } from "@/stores";
import { BaseRouter } from "./components";
import { CoachingUserRouter } from "./components/CoachingUserRouter";
import { PatientUserRouter } from "./components/PatientUserRouter";
import { BASE_ROUTE } from "./constants";
import { useSetPortalUser } from "./hooks";

const Root = () => {
  const { isLoading } = useSetPortalUser();
  const isLoggedOut = useAuthStore((state) => !state.token);
  const userType = usePortalUserStore((state) => state.portalUser?.type);
  const hasActiveWLSubscription = usePortalUserStore(
    (state) => state.hasActiveWLSubscription,
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Routes>
      <Route path="/" element={<BaseRouter />} />

      {hasActiveWLSubscription && (
        <Route
          path={`${EMBEDDABLES_ONBOARDING_ROUTE.BASE}/*`}
          element={<EmbeddablesOnboardingRouter />}
        />
      )}

      <Route path={`${BASE_ROUTE.COACHING}/*`} element={<CoachingRouter />} />

      {isLoggedOut && <Route path="*" element={<GuestRouter />} />}

      {userType === PORTAL_USER_TYPE.PATIENT && (
        <Route path="*" element={<PatientUserRouter />} />
      )}

      {userType === PORTAL_USER_TYPE.COACHING && (
        <Route path="*" element={<CoachingUserRouter />} />
      )}

      <Route path="*" element={<BaseRouter />} />
    </Routes>
  );
};

/**
 * Migration Guide: For details on migrating to RouterProvider,
 * please refer to the documentation at for the new routers:
 * [Migration Documentation](https://reactrouter.com/6.28.0/upgrading/v6-data)
 */
export const router = createBrowserRouter([
  onboardingRoute(),
  getIntakeFormRoute(),
  getMyMedsRoute(),
  getBillingRoute(),
  { path: "*", element: <Root /> },
]);
