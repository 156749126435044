import { zodResolver } from "@hookform/resolvers/zod";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { useForm } from "react-hook-form";
import { t } from "ttag";
import { z } from "zod";

import { FullScreenLoader } from "@/components";
import { CategoryBadge } from "@/domains/products/components";
import { Button, CheckboxIndicator, DetailCard, Dialog, Form } from "@/ui";
import { useUpdateMedicationStatus } from "../hooks";
import { deliveredMedicationSchema } from "../models";
import type { RefillRequest } from "../models";

const formSchema = z.object({
  medications: z.array(deliveredMedicationSchema),
});

interface DelayedRequestsSelectionProps {
  refillRequests: RefillRequest[];
  onSuccess: () => void;
}

export const DelayedRefillRequestsSelection = ({
  refillRequests,
  onSuccess,
}: DelayedRequestsSelectionProps) => {
  const updateMedicationStatusMutation = useUpdateMedicationStatus();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      medications: refillRequests.map((refillRequest) => ({
        id: refillRequest.id,
        delivered: false,
      })),
    },
  });

  const handleSubmit = form.handleSubmit(
    (values) =>
      void updateMedicationStatusMutation.mutate(values.medications, {
        onSuccess,
      }),
  );

  return (
    <Form.Provider {...form}>
      <form onSubmit={handleSubmit} className="flex h-full flex-col gap-6">
        <Form.Field
          defaultValue={[]}
          control={form.control}
          name="medications"
          render={({ field }) => (
            <Form.Item className="gap-2.5">
              {refillRequests.map((refillRequest) => (
                <Form.Field
                  key={refillRequest.id}
                  control={form.control}
                  name="medications"
                  render={() => {
                    return (
                      <Form.Item key={refillRequest.id}>
                        <Form.Control>
                          <CheckboxPrimitive.Root
                            checked={field.value?.some(
                              (request) =>
                                request.id === refillRequest.id &&
                                request.delivered,
                            )}
                            onCheckedChange={(checked) => {
                              field.onChange(
                                field.value.map((deliveredMedication) =>
                                  deliveredMedication.id === refillRequest.id
                                    ? {
                                        ...deliveredMedication,
                                        delivered: checked,
                                      }
                                    : deliveredMedication,
                                ),
                              );
                            }}
                            asChild
                          >
                            <DetailCard.Root
                              variant="borderLess"
                              className="cursor-pointer flex-row gap-2.5 bg-brown-02"
                            >
                              <CheckboxIndicator size="sm" className="mt-0.5" />

                              <div className="flex grow flex-col">
                                <div className="flex justify-between">
                                  <DetailCard.Title>
                                    {refillRequest.product.name}
                                  </DetailCard.Title>

                                  <CategoryBadge
                                    categorySlug={
                                      refillRequest.product.category.slug
                                    }
                                  >
                                    {refillRequest.product.category.name}
                                  </CategoryBadge>
                                </div>

                                <DetailCard.Description>
                                  {refillRequest.product.description}
                                  {refillRequest.price.dose &&
                                    ` (${refillRequest.price.dose})`}
                                </DetailCard.Description>
                              </div>
                            </DetailCard.Root>
                          </CheckboxPrimitive.Root>
                        </Form.Control>
                      </Form.Item>
                    );
                  }}
                />
              ))}

              <Form.Message />
            </Form.Item>
          )}
        />

        <Dialog.Footer variant="sticky">
          <Button type="submit" size="lg">
            {t`Confirm reception`}
          </Button>
        </Dialog.Footer>
      </form>

      {updateMedicationStatusMutation.isPending && <FullScreenLoader />}
    </Form.Provider>
  );
};
