import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import type { z } from "zod";

import { getCheckoutSchema } from "../models";

export const useCheckoutForm = () => {
  const formSchema = getCheckoutSchema();

  return useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });
};
