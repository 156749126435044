import type { ServiceResponse } from "@/api";
import { privateAPI } from "@/api";
import type { CreateHealthieUserParams } from "../models";

interface CreateHealthieUserResponse {
  token: string;
}

export const createHealthieUser = async ({
  userCode,
  ...userData
}: CreateHealthieUserParams) => {
  const { data } = await privateAPI.post<
    ServiceResponse<CreateHealthieUserResponse>
  >(`/patients/${userCode}/create-healthie-user`, userData);

  return data.data;
};
