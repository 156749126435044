import { useEffect } from "react";
import { t } from "ttag";

import { OnboardingHeader } from "@/domains/onboarding/components";
import { handleTheOfferTracking, THE_OFFER_EVENTS } from "@/utils";
import { MainInfoForm } from "../sections";

export const MainInfo = () => {
  useEffect(() => {
    handleTheOfferTracking({ event: THE_OFFER_EVENTS.VIEW_CONTENT });
  }, []);

  return (
    <>
      <OnboardingHeader
        title={t`Tell us about yourself`}
        description={t`(*) Mandatory field`}
      />

      <MainInfoForm />
    </>
  );
};
