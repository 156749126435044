import { t } from "ttag";

import { Button, Dialog, ShieldWarningIconMono } from "@/ui";

interface WeightLossDQModalProps {
  open: boolean;
  onGoBack: () => void;
  onContinue: () => void;
}

export const WeightLossDQModal = ({
  open,
  onGoBack,
  onContinue,
}: WeightLossDQModalProps) => {
  return (
    <Dialog.Root open={open}>
      <Dialog.Content size="modal" withCloseButton={false}>
        <div className="flex h-full flex-col gap-6">
          <Dialog.Header>
            <Dialog.Icon>
              <ShieldWarningIconMono />
            </Dialog.Icon>
            <Dialog.Title>
              {t`Weight loss treatments may not be a fit`}
            </Dialog.Title>
            <Dialog.Description>
              {t`Weight loss treatments may not be the best fit for you right now.`}
              <br />
              {t`In the meantime, feel free to continue exploring the other treatments you've selected!`}
            </Dialog.Description>
          </Dialog.Header>

          <Dialog.Footer variant="sticky">
            <Button onClick={onGoBack} variant="secondary" size="lg">
              {t`Go back`}
            </Button>
            <Button onClick={onContinue} size="lg">
              {t`Continue`}
            </Button>
          </Dialog.Footer>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
