import { t } from "ttag";

import { ExploreSubscriptionsButton } from "@/domains/billing/components";
import { BILLING_ROUTE } from "@/domains/billing/router";
import { DollarMinimalisticIcon, Header } from "@/ui";

export const BillingHeader = () => {
  return (
    <Header.Root>
      <Header.Background />

      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: BILLING_ROUTE.BASE,
              icon: DollarMinimalisticIcon,
              label: t`Billing`,
            },
          ]}
        />

        <div className="flex justify-between">
          <Header.Title>{t`Billing`}</Header.Title>

          <ExploreSubscriptionsButton isHeaderButton />
        </div>
      </Header.Content>
    </Header.Root>
  );
};
