import { Navigate, Route, Routes } from "react-router-dom";

import { RequireLoggedOut, ROUTES } from "@/router";
import { Account, MainInfo, Measurements, SuccessScreen } from "../screens";
import {
  RequireEmbeddablesHealthieAccount,
  RequireFormData,
  RequireValidUserCode,
} from "./components";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "./constants";

const getPartialRoute = (route: string) => {
  return route.replace(EMBEDDABLES_ONBOARDING_ROUTE.BASE, "");
};

export const EmbeddablesOnboardingRouter = () => {
  return (
    <Routes>
      <Route
        path={`${getPartialRoute(EMBEDDABLES_ONBOARDING_ROUTE.ACCOUNT)}/:userCode`}
        element={
          <RequireLoggedOut>
            <RequireValidUserCode>
              <Account />
            </RequireValidUserCode>
          </RequireLoggedOut>
        }
      />

      <Route element={<RequireEmbeddablesHealthieAccount />}>
        <Route
          path={getPartialRoute(EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO)}
          element={<MainInfo />}
        />

        <Route element={<RequireFormData />}>
          <Route
            path={getPartialRoute(
              EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO_MEASUREMENTS,
            )}
            element={<Measurements />}
          />
        </Route>
      </Route>

      <Route
        path={getPartialRoute(EMBEDDABLES_ONBOARDING_ROUTE.SUCCESS)}
        element={<SuccessScreen />}
      />

      <Route path="*" element={<Navigate replace to={ROUTES.BASE} />} />
    </Routes>
  );
};
