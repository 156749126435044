const BASE_ROUTE = "/intake-form";

export const INTAKE_FORM_ROUTE = {
  BASE: BASE_ROUTE,
  CHOOSE_JOURNEY: `${BASE_ROUTE}/choose-journey`,
  SCHEDULE_VIDEO_CONSULTATION: `${BASE_ROUTE}/schedule-video-consultation`,
  MEDICATION_REQUEST: `${BASE_ROUTE}/medication-request`,
  ASSESSMENT_FORM: `${BASE_ROUTE}/assessment-form`,
  SUCCESS: `${BASE_ROUTE}/success`,
} as const;
