import { Navigate, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { Loader } from "@/components";
import {
  OnboardingHeader,
  QuestionForm,
} from "@/domains/onboarding/components";
import { useEligibilityQuestions } from "@/domains/onboarding/hooks";
import { ONBOARDING_ROUTE } from "@/domains/onboarding/router";
import {
  DQWithCoachingOption,
  WeightLossDQModal,
} from "@/domains/onboarding/sections";
import { useOnboardingStore } from "@/domains/onboarding/stores";
import { useBoolean } from "@/hooks";

export const EligibilityQuestions = () => {
  const selectedCategories = useOnboardingStore(
    (state) => state.selectedCategories,
  );
  const setSelectedCategories = useOnboardingStore(
    (state) => state.setSelectedCategories,
  );

  const { value: showFullscreenAlert, setTrue: openFullscreenAlert } =
    useBoolean(false);
  const { value: showContinueModal, setTrue: openContinueModal } =
    useBoolean(false);

  const navigate = useNavigate();

  const handleTotalDQ = () => {
    openFullscreenAlert();
  };

  const handlePartialDQ = () => {
    const updatedCategories = selectedCategories.filter(
      (category) =>
        !disqualifiedCategories.some((disqualifiedCategory) => {
          return category.id === disqualifiedCategory.id;
        }),
    );
    setSelectedCategories(updatedCategories);
    openContinueModal();
  };

  const handleNoDQ = () => {
    navigate(ONBOARDING_ROUTE.TREATMENT_CHOOSE_PRODUCTS);
  };

  const { currentQuestion, disqualifiedCategories, isLoading, handleAnswers } =
    useEligibilityQuestions({
      selectedCategories,
      onTotalDQ: handleTotalDQ,
      onPartialDQ: handlePartialDQ,
      onNoDQ: handleNoDQ,
    });

  if (!selectedCategories.length) {
    return <Navigate to={ONBOARDING_ROUTE.TREATMENT_CHOOSE_CATEGORIES} />;
  }

  return (
    <>
      <OnboardingHeader
        title={t`Tell us more about your health`}
        description={currentQuestion?.question}
      />

      {currentQuestion && (
        <QuestionForm question={currentQuestion} onAnswer={handleAnswers} />
      )}

      {isLoading && <Loader />}

      <DQWithCoachingOption
        open={showFullscreenAlert}
        onGoBack={() => navigate(ONBOARDING_ROUTE.TREATMENT_CHOOSE_CATEGORIES)}
      />

      <WeightLossDQModal
        open={showContinueModal}
        onGoBack={() => navigate(ONBOARDING_ROUTE.TREATMENT_CHOOSE_CATEGORIES)}
        onContinue={() => navigate(ONBOARDING_ROUTE.TREATMENT_CHOOSE_PRODUCTS)}
      />
    </>
  );
};
