import type { PropsWithChildren } from "react";
import { createContext, useContext, useState } from "react";
import { Navigate } from "react-router-dom";

import { ErrorState } from "@/components";
import type { Subscription } from "@/domains/billing/models";
import type { VideoConsultationSchedule } from "@/domains/consultations/models";
import { usePatientQuery } from "@/hooks";
import { Layout } from "@/layouts";
import { ROUTES } from "@/router";
import type { Patient } from "@/shared.types";
import { Loading } from "@/ui";

interface IntakeFormContextProps {
  patient: Patient;
  subscription?: Subscription;
  isAsync?: boolean;
  videoConsultationSchedule?: VideoConsultationSchedule;

  actions: {
    setIsAsync: (isAsync: boolean) => void;
    setSubscription: (subscription: Subscription) => void;
    setVideoConsultationSchedule: (
      videoConsultationSchedule?: VideoConsultationSchedule,
    ) => void;
  };
}

const IntakeFormContext = createContext<IntakeFormContextProps | null>(null);

export const useIntakeFormContext = () => {
  const context = useContext(IntakeFormContext);

  if (!context) {
    throw new Error(
      "useIntakeFormContext must be used within a <IntakeFormProvider />",
    );
  }

  return context;
};

export const IntakeFormContextProvider = ({ children }: PropsWithChildren) => {
  const { data: patient, isLoading, isError } = usePatientQuery();

  const [subscription, setSubscription] = useState<Subscription>();

  const [isAsync, setIsAsync] = useState<boolean>();

  const [videoConsultationSchedule, setVideoConsultationSchedule] =
    useState<VideoConsultationSchedule>();

  if (isError) {
    return (
      <Layout>
        <ErrorState className="-translate-y-10 border-none" />
      </Layout>
    );
  }

  if (isLoading) {
    return (
      <Layout>
        <div className="flex grow items-center justify-center">
          <Loading />
        </div>
      </Layout>
    );
  }

  if (!patient) {
    return <Navigate to={ROUTES.BASE} />;
  }

  return (
    <IntakeFormContext.Provider
      value={{
        patient,
        subscription,
        isAsync,
        videoConsultationSchedule,

        actions: {
          setIsAsync,
          setSubscription,
          setVideoConsultationSchedule,
        },
      }}
    >
      {children}
    </IntakeFormContext.Provider>
  );
};
