import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { t } from "ttag";

import { LanguageSwitch } from "@/components";
import type { Variant } from "@/shared.types";
import { StepProgressBar } from "@/ui";
import { ONBOARDING_ROUTE } from "../router";

const getSteps = () =>
  [
    {
      value: ONBOARDING_ROUTE.MAIN_INFO,
      label: t`Main info`,
    },
    {
      value: ONBOARDING_ROUTE.TREATMENT,
      label: t`Treatment`,
    },
    {
      value: ONBOARDING_ROUTE.CHECKOUT,
      label: t`Checkout`,
    },
    {
      value: ONBOARDING_ROUTE.ACCOUNT,
      label: t`Account`,
    },
  ] as const;

export const OnboardingProgressBar = ({ variant }: { variant?: Variant }) => {
  const { pathname: currentPath } = useLocation();
  const queryClient = useQueryClient();

  return (
    <div className="flex w-full flex-col-reverse items-center justify-center gap-9 md:relative md:flex-row">
      <StepProgressBar
        steps={getSteps()}
        currentStep={currentPath}
        variant={variant}
      />

      <LanguageSwitch
        onCheckedChange={() => queryClient.invalidateQueries()}
        className="right-0 self-end md:absolute md:-top-2"
      />
    </div>
  );
};
