import { z } from "zod";

import type { ServiceResponse } from "@/api";
import { privateAPI } from "@/api";
import type { MedicationRequest } from "../models";
import { medicationRequestSchema } from "../models";

export const getCurrentMedicationRequests = async () => {
  const { data } = await privateAPI.get<ServiceResponse<MedicationRequest[]>>(
    "/patients/current-medication-request",
  );

  return z.array(medicationRequestSchema).parse(data.data);
};

export const getPastMedicationRequests = async () => {
  const { data } = await privateAPI.get<ServiceResponse<MedicationRequest[]>>(
    "/patients/past-medications",
  );

  return z.array(medicationRequestSchema).parse(data.data);
};
