import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import { skipToken } from "@tanstack/react-query";

import type { Question } from "@/shared/models";
import { getInitialQuestion, getQuestion } from "./questions";
import { getUserValidation } from "./user";

export const queriesStore = createQueryKeyStore({
  user: {
    validation: (userCode?: string) => ({
      queryKey: [userCode],
      queryFn: userCode
        ? () => getUserValidation(userCode)
        : (skipToken as never),
    }),
  },

  bmiQuestions: {
    initialQuestion: (bmi: number) => ({
      queryKey: [bmi],
      queryFn: () => getInitialQuestion(bmi),
    }),
    question: (questionId?: Question["id"]) => ({
      queryKey: [questionId],
      queryFn: questionId
        ? () => getQuestion(questionId)
        : (skipToken as never),
    }),
  },
});
