import { Skeleton } from "@/ui";

export const SubscriptionsSkeleton = () => {
  return (
    <div className="grid gap-2">
      {Array.from({ length: 2 }, (_, index) => `${index}`).map((i) => (
        <div
          key={`radio-group-skeleton-${i}`}
          className="flex flex-col gap-3 rounded-2xl border border-brown-04 bg-brown-01 p-4 shadow-sm"
        >
          <div className="flex items-center justify-between gap-4">
            <Skeleton className="h-4 w-2/5" />

            <div className="flex gap-4">
              <Skeleton className="h-5 w-9" />
              <Skeleton className="h-5 w-12" />
            </div>
          </div>

          <Skeleton className="h-3 w-3/5" />
        </div>
      ))}
    </div>
  );
};
