import { useId } from "react";
import type { ComponentPropsWithoutRef } from "react";
import type { Control } from "react-hook-form";
import { Link } from "react-router-dom";
import { jt, t } from "ttag";

import { EXTERNAL_LINK } from "@/router";
import { Form } from "@/ui";
import { Checkbox } from "@/ui/form/Checkbox";

interface FieldValue {
  termsAndConditionsConsent: boolean;
}

type TermsAndConditionsFieldControl<T> = T extends FieldValue
  ? Control<T, "termsAndConditionsConsent">
  : never;

type TermsAndConditionsFieldProps<T extends FieldValue> =
  ComponentPropsWithoutRef<typeof Form.Item> & {
    control: TermsAndConditionsFieldControl<T>;
  };
export const TermsAndConditionsField = <T extends FieldValue>({
  control,
  ...props
}: TermsAndConditionsFieldProps<T>) => {
  const id = useId();

  const termsAndConditionsLink = (
    <Link
      key={`${id}-terms-and-conditions-link`}
      to={EXTERNAL_LINK.TERMS_AND_CONDITIONS}
      target="_blank"
      className="underline underline-offset-1"
    >
      {t`Fridays Terms and Conditions`}
    </Link>
  );

  const privacyPolicyLink = (
    <Link
      key={`${id}-privacy-policy-link`}
      to={EXTERNAL_LINK.PRIVACY_AND_POLICY}
      target="_blank"
      className="underline underline-offset-1"
    >
      {t`Fridays Privacy Policy`}
    </Link>
  );

  return (
    <Form.Field
      control={control}
      name="termsAndConditionsConsent"
      render={({ field: { value, onChange, ...rest } }) => (
        <Form.Item {...props}>
          <div className="flex items-start gap-2.5">
            <Form.Control>
              <Checkbox {...rest} checked={value} onCheckedChange={onChange} />
            </Form.Control>

            <Form.Label className="text-brown-08">
              {jt`I agree to the ${termsAndConditionsLink} and consent to the ${privacyPolicyLink}.  Regardless of my choices above, I agree to get non-marketing emails, text messages, and/or calls from Fridays relating to my account (for example, appointment reminders).`}
            </Form.Label>
          </div>
          <Form.Message compact={false} className="text-center" />
        </Form.Item>
      )}
    />
  );
};
