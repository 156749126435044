import type { RouteObject } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { PortalRoutesWrapper, RequireUserType } from "@/shared/components";
import { PORTAL_USER_TYPE } from "@/shared/models";
import { ExploreSubscriptionsContextProvider } from "../context";
import { ChangeSubscription, ExploreSubscriptions, Overview } from "../screens";
import { BILLING_ROUTE } from "./constants";

export const getBillingRoute = (): RouteObject => ({
  path: BILLING_ROUTE.BASE,
  element: <PortalRoutesWrapper />,

  children: [
    { index: true, element: <Overview /> },

    {
      path: BILLING_ROUTE.CHANGE_SUBSCRIPTION,
      element: (
        <RequireUserType allowedUserTypes={[PORTAL_USER_TYPE.PATIENT]}>
          <ChangeSubscription />
        </RequireUserType>
      ),
    },

    {
      path: BILLING_ROUTE.EXPLORE_SUBSCRIPTIONS,
      element: (
        <ExploreSubscriptionsContextProvider>
          <RequireUserType allowedUserTypes={[PORTAL_USER_TYPE.PATIENT]}>
            <ExploreSubscriptions />
          </RequireUserType>
        </ExploreSubscriptionsContextProvider>
      ),
    },

    { path: "*", element: <Navigate replace to={BILLING_ROUTE.BASE} /> },
  ],
});
