import { t } from "ttag";
import { z } from "zod";

import {
  getDateOfBirthSchema,
  getLocationSchema,
  journeySchema,
} from "@/shared.schemas";
import { getAvailableLanguageSchema, portalUserSchema } from "@/shared/models";

export const embeddablesUserSchema = z.object({
  isValid: z.boolean(),
  email: z.string().email(),
  type: portalUserSchema.shape.type,
});

export const getMainInfoSchema = () =>
  z.object({
    dateOfBirth: getDateOfBirthSchema(),
    location: getLocationSchema(),
    preferredLanguage: getAvailableLanguageSchema(),
  });

export const getChooseJourneySchema = () =>
  z.object({
    journey: journeySchema,
  });

export const getMeasurementsSchema = () =>
  z.object({
    heightFeet: z
      .number({ required_error: t`Height in feet is required` })
      .int()
      .positive(),
    heightInches: z
      .number({ required_error: t`Height in inches is required` })
      .int()
      .nonnegative(),
    weight: z.number({ required_error: t`Weight is required` }).positive(),
  });
