import { t } from "ttag";

import { Alert } from "@/ui";

const getAlertTexts = (
  isRefillable: boolean,
  hasUpcomingAppointment: boolean,
) => {
  if (!isRefillable) {
    return {
      title: t`Back to back orders`,
      description: t`Your last order was placed too recently. Please wait a little longer before requesting another refill.`,
    };
  }

  if (hasUpcomingAppointment) {
    return {
      title: t`Upcoming appointment`,
      description: t`You have an upcoming visit with your provider. Please wait until after it before requesting another refill.`,
    };
  }

  return {
    title: "",
    description: "",
  };
};

interface RefillDisabledAlertProps {
  isRefillable: boolean;
  hasUpcomingAppointment: boolean;
}

export const RefillDisabledAlert = ({
  isRefillable,
  hasUpcomingAppointment,
}: RefillDisabledAlertProps) => {
  const { title, description } = getAlertTexts(
    isRefillable,
    hasUpcomingAppointment,
  );

  const show = !isRefillable || hasUpcomingAppointment;

  if (!show) {
    return null;
  }

  return (
    <Alert type="info" className="-ml-6 text-nature-10 opacity-100">
      <h6 className="font-bold">{title}</h6>
      {description}
    </Alert>
  );
};
