import { useLocation } from "react-router-dom";

import type { PortalUser } from "@/shared/models";
import { PORTAL_USER_TYPE } from "@/shared/models";
import { CoachingSuccessScreenAlert } from "./sections";
import { PatientSuccessScreenAlert } from "./sections/PatientSuccessScreenAlert";

export const SuccessScreen = () => {
  const location = useLocation();

  const portalUserType = location.state as PortalUser["type"];

  if (portalUserType === PORTAL_USER_TYPE.COACHING) {
    return <CoachingSuccessScreenAlert />;
  }

  return <PatientSuccessScreenAlert />;
};
