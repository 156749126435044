export const DISCOUNT_TYPE = {
  PERCENTAGE: "percentage",
  FIXED_AMOUNT: "fixed_amount",
} as const;

export const DISCOUNT_DURATION = {
  ONCE: "once",
  REPEATING: "repeating",
  FOREVER: "forever",
} as const;

export const DURATION_IN_MONTHS = {
  MONTHLY: 1,
  QUARTERLY: 3,
} as const;

export const PLAN_DURATION = {
  MONTHLY: {
    TEXT_VALUE: "monthly",
    VALUE: DURATION_IN_MONTHS.MONTHLY,
  },
  QUARTERLY: {
    TEXT_VALUE: "quarterly",
    VALUE: DURATION_IN_MONTHS.QUARTERLY,
  },
} as const;

export const INACTIVE_STATUS = {
  ONBOARDING: "onboarding",
  CANCELLED: "cancelled",
  PAYMENT_FAILED: "payment_failed",
  INCOMPLETE: "incomplete",
} as const;

export const SUBSCRIPTION_STATUS = {
  ...INACTIVE_STATUS,
  ACTIVE: "active",
  PAID_IN_HEALTHIE: "paid_in_healthie",
  EMBEDDABLES: "embeddables",
} as const;
