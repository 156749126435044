import { z } from "zod";

import type { ServiceResponse } from "@/api";
import { privateAPI } from "@/api";
import type {
  AvailableRefillRequest,
  DeliveredMedication,
  MedicationRequest,
  RefillRequest,
} from "../models";
import { availableRefillRequestSchema, refillRequestSchema } from "../models";

type DeliveredMedicationResponse = {
  id: number;
  medicationSlug: string;
  medicationStatus: MedicationRequest["status"];
}[];

export const updateMedicationRequestStatus = async (
  medications: DeliveredMedication[],
) => {
  const { data } = await privateAPI.put<
    ServiceResponse<DeliveredMedicationResponse>
  >("/refill-requests/delivered", { medications });

  return data.data;
};

export const getAvailableRefillRequests = async () => {
  const { data } = await privateAPI.get<
    ServiceResponse<AvailableRefillRequest[]>
  >("/patients/available-refills");

  return z.array(availableRefillRequestSchema).parse(data.data);
};

export const getDelayedRefillRequests = async () => {
  const { data } = await privateAPI.get<ServiceResponse<RefillRequest[]>>(
    "/patients/refill-requests/delayed",
  );

  return z.array(refillRequestSchema).parse(data.data);
};
