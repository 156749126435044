import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import { RadioGroupIndicator } from "@radix-ui/react-radio-group";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

const radioIndicatorVariants = tv({
  slots: {
    container:
      "flex shrink-0 items-center justify-center rounded-full border border-brown-04 bg-salmon-01 group-focus:ring-2",
    indicatorContainer:
      "flex h-full w-full items-center justify-center rounded-full",
    indicator: "rounded-full",
  },
  variants: {
    size: {
      sm: {
        container: "size-4",
        indicator: "size-2",
      },
      md: {
        container: "size-5",
        indicator: "size-2.5",
      },
    },
    color: {
      nature: {
        container: "hover:border-nature-08 group-focus:ring-nature-08",
        indicatorContainer: "bg-nature-09",
        indicator: "bg-nature-04",
      },
      brown: {
        container: "hover:border-brown-05 group-focus:ring-brown-05",
        indicatorContainer: "bg-brown-09",
        indicator: "bg-brown-05",
      },
    },
  },
  defaultVariants: {
    size: "sm",
    color: "nature",
  },
});

type RadioIndicatorProps = ComponentPropsWithoutRef<"div"> &
  VariantProps<typeof radioIndicatorVariants>;

export const RadioIndicator = forwardRef<
  ElementRef<"div">,
  RadioIndicatorProps
>(({ className, size, color }, ref) => {
  const { container, indicatorContainer, indicator } = radioIndicatorVariants({
    size,
    color,
  });

  return (
    <div ref={ref} className={container({ className })}>
      <RadioGroupIndicator className={indicatorContainer()}>
        <div className={indicator()} />
      </RadioGroupIndicator>
    </div>
  );
});

RadioIndicator.displayName = "RadioIndicator";
