import type { ComponentProps, ElementRef } from "react";
import { forwardRef, useId } from "react";

import { CategoryBadge } from "@/domains/products/components";
import { RadioGroupCards } from "@/ui";
import { tw } from "@/utils";
import type { AvailableRefillRequest } from "../models";
import { RefillDisabledAlert } from "./RefillDisabledAlert";

interface RefillOptionsRadioGroupProps
  extends ComponentProps<typeof RadioGroupCards.Root> {
  refillRequests: AvailableRefillRequest[];
}

export const RefillOptionsRadioGroup = forwardRef<
  ElementRef<typeof RadioGroupCards.Root>,
  RefillOptionsRadioGroupProps
>(({ refillRequests, ...props }: RefillOptionsRadioGroupProps, ref) => {
  const id = useId();

  return (
    <RadioGroupCards.Root ref={ref} className="grow" {...props}>
      {refillRequests.map(
        ({
          dosis,
          frequency,
          product,
          isRefillable,
          hasUpcomingAppointment,
        }) => {
          const disabled = !isRefillable || hasUpcomingAppointment;

          return (
            <RadioGroupCards.Item
              disabled={disabled}
              id={`${id}-${product.id}`}
              key={`${id}-${product.id}`}
              value={product.slug}
              className={tw(
                "gap-2.5 pr-4 disabled:opacity-100",
                disabled && "pl-10",
              )}
            >
              <div
                className={tw("flex gap-2.5", disabled && "pl-0.5 opacity-50")}
              >
                {!disabled && <RadioGroupCards.ItemIndicator />}

                <div className="flex grow flex-col gap-1">
                  <RadioGroupCards.ItemLabel htmlFor={`${id}-${product.id}`}>
                    {product.name}
                  </RadioGroupCards.ItemLabel>

                  <RadioGroupCards.ItemDescription>
                    {dosis} {frequency}
                  </RadioGroupCards.ItemDescription>
                </div>

                <CategoryBadge categorySlug={product.category.slug}>
                  {product.category.name}
                </CategoryBadge>
              </div>

              <RefillDisabledAlert
                isRefillable={isRefillable}
                hasUpcomingAppointment={hasUpcomingAppointment}
              />
            </RadioGroupCards.Item>
          );
        },
      )}
    </RadioGroupCards.Root>
  );
});
RefillOptionsRadioGroup.displayName = "RefillOptionsRadioGroup";
