import { useId } from "react";
import { useMutation } from "@tanstack/react-query";
import { Navigate, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import {
  NavigationButtons,
  PaymentDetails,
  PaymentErrorAlert,
  PaymentSucceededAlert,
  PaymentTotal,
} from "@/domains/onboarding/components";
import {
  useCheckout,
  useCheckoutForm,
  useMarkCheckoutVisited,
} from "@/domains/onboarding/hooks";
import { ONBOARDING_ROUTE } from "@/domains/onboarding/router";
import { useOnboardingStore } from "@/domains/onboarding/stores";
import { useBoolean } from "@/hooks";
import { Form } from "@/ui";
import { PaymentFieldset } from "./PaymentFieldset";
import { ShippingFieldset } from "./ShippingFieldset";

export const CheckoutForm = () => {
  const formId = useId();
  const user = useOnboardingStore((state) => state.user);
  const coupon = useOnboardingStore((state) => state.coupon);
  const selectedCategories = useOnboardingStore(
    (state) => state.selectedCategories,
  );

  const { value: showPaymentFailedAlert, setTrue: openPaymentFailedAlert } =
    useBoolean(false);
  const {
    value: showPaymentSucceededAlert,
    setTrue: openPaymentSucceededAlert,
  } = useBoolean(false);

  const form = useCheckoutForm();

  const navigate = useNavigate();

  const handleCheckout = useCheckout({ user, selectedCategories, coupon });
  const checkoutMutation = useMutation({
    mutationFn: handleCheckout,
    onSuccess: openPaymentSucceededAlert,
    onError: openPaymentFailedAlert,
  });

  useMarkCheckoutVisited();

  if (!user || !selectedCategories.length) {
    return <Navigate to={ONBOARDING_ROUTE.MAIN_INFO} />;
  }

  const handleBack = () => {
    navigate(-1);
  };

  const handlePay = form.handleSubmit((formData) => {
    checkoutMutation.mutate(formData);
  });

  return (
    <>
      <Form.Provider {...form}>
        <PaymentTotal />

        <form id={formId} className="flex grow flex-col content-start gap-7">
          <PaymentFieldset />
          <ShippingFieldset />
        </form>

        <PaymentDetails />

        <NavigationButtons
          continueLabel={t`Pay`}
          onCancel={handleBack}
          onContinue={handlePay}
          continueDisabled={checkoutMutation.isPending}
          continueType="submit"
          continueForm={formId}
        />
      </Form.Provider>

      {checkoutMutation.isPending && (
        <FullScreenLoader
          description={
            <p className="max-w-lg text-center text-lg font-medium text-salmon-01">
              {t`We are processing your payment, just give us a moment. You'll be able to create your Fridays account shortly!`}
            </p>
          }
        />
      )}

      <PaymentSucceededAlert
        show={showPaymentSucceededAlert}
        onContinue={() => navigate(ONBOARDING_ROUTE.ACCOUNT)}
      />

      <PaymentErrorAlert open={showPaymentFailedAlert} />
    </>
  );
};
