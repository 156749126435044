import {
  CardSkeleton,
  Skeleton,
  Table,
  TableBody,
  TableCard,
  TableHeader,
  TableRow,
} from "@/ui";

interface TableSkeletonProps {
  columns: number;
  rows: number;
}

export const TableSkeleton = ({ columns, rows }: TableSkeletonProps) => {
  return (
    <>
      <Table>
        <TableHeader>
          <TableRow className="pointer-events-none">
            <th colSpan={columns}>
              <Skeleton className="h-[40.5px] w-full rounded-b-none rounded-tl-2xl rounded-tr-2xl bg-salmon-10" />
            </th>
          </TableRow>
        </TableHeader>

        <TableBody>
          {Array.from({ length: rows }).map((_, rowIndex) => (
            <TableRow key={rowIndex} className="pointer-events-none h-20">
              {Array.from({ length: columns }).map((_, colIndex) => (
                <td key={colIndex} className="p-4">
                  <Skeleton className="h-6" />
                </td>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TableCard.Root>
        {Array.from({ length: rows }).map((_, rowIndex) => (
          <TableCard.Row key={rowIndex}>
            <CardSkeleton className="bg-transparent" />
          </TableCard.Row>
        ))}
      </TableCard.Root>
    </>
  );
};
