import { t } from "ttag";

import type { Consultation } from "@/shared.types";
import { Badge, LinkMinimalistic, TableCard } from "@/ui";
import { formatBackendDate, formatConsultationTime } from "@/utils";

interface PastConsultationsCardsProps {
  consultations: Consultation[];
  setDocumentId: (documentId: Consultation["documentId"]) => void;
}

export const PastConsultationsCards = ({
  consultations,
  setDocumentId,
}: PastConsultationsCardsProps) => {
  return (
    <TableCard.Root>
      {consultations.map((consultation) => (
        <TableCard.Row key={consultation.id} className="flex-row">
          <div className="flex flex-1 flex-col gap-2">
            <TableCard.Cell>
              <TableCard.Label>{t`Provider`}</TableCard.Label>
              <TableCard.Data className="flex w-full justify-between">
                {consultation.provider.fullName}
              </TableCard.Data>
            </TableCard.Cell>

            <TableCard.Cell>
              <TableCard.Label>{t`Date`}</TableCard.Label>
              <TableCard.Data>
                {formatBackendDate(consultation.startTime)}
              </TableCard.Data>
            </TableCard.Cell>

            <TableCard.Cell>
              <TableCard.Label>{t`Time`}</TableCard.Label>
              <TableCard.Data className="flex w-full justify-between">
                {`${formatConsultationTime(
                  consultation.startTime,
                )} - ${formatConsultationTime(consultation.endTime)} ${
                  consultation.timezone
                }`}
              </TableCard.Data>
            </TableCard.Cell>
          </div>

          <div className="flex flex-col items-end justify-between">
            {consultation.pmStatus && (
              <Badge
                rounded="3xl"
                variant="secondary"
                size="md"
                className="bg-salmon-09 text-brown-01"
              >
                {consultation.pmStatus}
              </Badge>
            )}
            {consultation.documentId && (
              <button
                type="button"
                className="flex items-center justify-end gap-0.5 text-nature-08"
                onClick={() => setDocumentId(consultation.documentId)}
              >
                <LinkMinimalistic className="size-4" />
                <span className="font-medium">{t`Note`}</span>
              </button>
            )}
          </div>
        </TableCard.Row>
      ))}
    </TableCard.Root>
  );
};
