import React from "react";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import { ErrorBoundaryFallback } from "../../resources/js/screens/ErrorBoundaryFallback";
import { env } from "./env";
import { router } from "./router";
import { Toasts } from "./ui";

import "../css/app.css";
import "./bootstrap";

import { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { NuqsAdapter } from "nuqs/adapters/react-router";
import { RouterProvider } from "react-router-dom";
import { addLocale, useLocale } from "ttag";
import { ZodError } from "zod";

import translationEs from "../lang/es/es.po.json";
import { AnalyticsContextProvider } from "./contexts";
import { useAppSettingsStore } from "./stores";
import { initGoogleTagManagerTracking } from "./utils";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: (error) => {
        // Instead of using `APP_ENV !== "production"`, we use the VITE_API_URL because in stage, APP_ENV is set as "production".
        if (env.VITE_API_URL !== "https://app.joinfridays.com/api") {
          if (error instanceof ZodError) {
            console.error(error);
          }
        } else {
          Sentry.captureException(error);
        }

        return false;
      },
      retry: (failureCount, error) =>
        error instanceof ZodError ? false : failureCount < 3,
    },
  },
});

Sentry.init({
  dsn: env.VITE_SENTRY_DSN_PUBLIC,
  environment: env.VITE_SENTRY_ENVIRONMENT,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

addLocale("es", translationEs);
const stripePromise = loadStripe(env.VITE_STRIPE_PUBLIC_KEY);

const analytics = AnalyticsBrowser.load({
  writeKey: env.VITE_CUSTOMER_IO_WRITE_KEY,
});

function AppWrapper() {
  const language = useAppSettingsStore((state) => state.language);
  useLocale(language);

  initGoogleTagManagerTracking();

  return (
    <NuqsAdapter>
      <Elements stripe={stripePromise} options={{ locale: language }}>
        <AnalyticsContextProvider analytics={analytics}>
          <RouterProvider key={language} router={router} />
        </AnalyticsContextProvider>
      </Elements>
    </NuqsAdapter>
  );
}

let container: ReturnType<typeof document.getElementById> = null;

document.addEventListener("DOMContentLoaded", () => {
  if (!container) {
    container = document.getElementById("app")!;
    const root = createRoot(container);
    root.render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
            <AppWrapper />
          </Sentry.ErrorBoundary>

          {ReactDOM.createPortal(<Toasts />, document.body)}

          {env.VITE_APP_ENV === "local" && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </React.StrictMode>,
    );
  }
});
