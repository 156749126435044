import { z } from "zod";

import type { ServiceResponse } from "@/api";
import { privateAPI, publicAPI } from "@/api";
import type { OnboardingUser } from "@/domains/onboarding/models";
import type { Product, SubscriptionPriceId } from "@/domains/products/models";
import type {
  Coupon,
  CreatedStripeSubscription,
  CreateSubscriptionParams,
  CreateSubscriptionResponse,
  DetailedSubscription,
  DowngradeSubscription,
  Plan,
  Subscription,
} from "../models";
import {
  detailedSubscriptionSchema,
  downgradeSubscriptionSchema,
} from "../models";

export const acceptRequestedPlanChange = async (accepted: boolean) => {
  const { data } = await privateAPI.put<ServiceResponse<null>>(
    "/patients/subscriptions/requested-plan-change",
    { accepted },
  );

  return data.data;
};

export const refundAndCancelSubscription = async (
  subscriptionId: Subscription["id"],
) => {
  const { data } = await privateAPI.delete<ServiceResponse<null>>(
    `/patients/subscriptions/${subscriptionId}`,
  );

  return data.data;
};

export const changeSubscriptionPlan = async (
  subscriptionPriceId: Plan["subscriptionPriceId"],
) => {
  const { data } = await privateAPI.put<ServiceResponse<null>>(
    `/patients/subscriptions/change-subscription`,
    { subscriptionPriceId },
  );

  return data.data;
};

interface ChangeNextPeriodSubscriptionParams {
  subscriptionId: Subscription["id"];
  productPriceId: Product["prices"][number]["id"];
}

export const changeNextPeriodSubscription = async ({
  subscriptionId,
  productPriceId,
}: ChangeNextPeriodSubscriptionParams) => {
  const { data } = await privateAPI.put<ServiceResponse<null>>(
    `/patients/subscriptions/${subscriptionId}/schedule`,
    { nextProductPriceId: productPriceId },
  );

  return data.data;
};

export const createSubscription = async ({
  userId,
  subscriptionPriceId,
  couponCode,
}: CreateSubscriptionParams) => {
  const { data } = await publicAPI.post<
    ServiceResponse<CreateSubscriptionResponse>
  >(`/patients/${userId}/create-subscription`, {
    couponCode,
    subscriptionPriceId,
  });

  return data.data;
};

interface CreateSubscriptionsParams {
  userCode: OnboardingUser["code"];
  subscriptions: SubscriptionPriceId[];
  couponCode?: Coupon["code"];
}

export const createSubscriptions = async ({
  userCode,
  subscriptions,
  couponCode,
}: CreateSubscriptionsParams) => {
  const { data } = await publicAPI.post<
    ServiceResponse<CreatedStripeSubscription[]>
  >(`/patients/${userCode}/create-subscriptions`, {
    subscriptions,
    couponCode,
  });

  return data.data;
};

export const getPatientCurrentSubscriptions = async () => {
  const { data } = await privateAPI.get<
    ServiceResponse<DetailedSubscription[]>
  >("/patients/subscriptions");

  return z.array(detailedSubscriptionSchema).parse(data.data);
};

export const createPatientSubscriptions = async (
  subscriptions: SubscriptionPriceId[],
) => {
  const { data } = await privateAPI.post<ServiceResponse<unknown>>(
    "/patients/subscriptions",
    { subscriptions },
  );

  return data.data;
};

export const getSubscriptionDowngrade = async (
  subscriptionId: Subscription["id"],
) => {
  const { data } = await privateAPI.get<ServiceResponse<DowngradeSubscription>>(
    `patients/subscriptions/${subscriptionId}/downgrade`,
  );

  return downgradeSubscriptionSchema.parse(data.data);
};

export const setSubscriptionDowngrade = async (
  subscriptionId: Subscription["id"],
) => {
  const { data } = await privateAPI.post<ServiceResponse<null>>(
    `patients/subscriptions/${subscriptionId}/downgrade`,
  );

  return data.data;
};
