import { ShowcaseCard, Skeleton } from "@/ui";

interface ShowcaseCardSkeletonProps {
  className?: string;
  withThumbnail?: boolean;
}

export const ShowcaseCardSkeleton = ({
  className,
  withThumbnail,
}: ShowcaseCardSkeletonProps) => {
  return (
    <ShowcaseCard.Root className={className}>
      {withThumbnail && (
        <ShowcaseCard.Inset className="relative">
          <Skeleton className="size-full h-36 sm:h-60" />
        </ShowcaseCard.Inset>
      )}

      <ShowcaseCard.Header className="flex-row items-center justify-between gap-4">
        <Skeleton className="my-1 h-4.5 w-2/5" />

        {!withThumbnail && <Skeleton className="size-4.5" />}
      </ShowcaseCard.Header>

      <ShowcaseCard.Content>
        <Skeleton className="my-1 h-3.5" />
        <Skeleton className="my-1 h-3.5 w-2/5" />
      </ShowcaseCard.Content>

      <ShowcaseCard.Footer>
        <Skeleton className="my-0.5 h-4 w-3/5" />
      </ShowcaseCard.Footer>
    </ShowcaseCard.Root>
  );
};
