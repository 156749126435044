import { useSyncExternalStore } from "react";

const subscribe = (callback: () => void) => {
  window.addEventListener("resize", callback);

  return () => window.removeEventListener("resize", callback);
};

const getSnapShot = () => window.innerWidth;

export const useIsMobile = (maxWidth = 768) => {
  const width = useSyncExternalStore(subscribe, getSnapShot);

  return width < maxWidth;
};
