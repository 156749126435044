import { z } from "zod";

import { publicAPI } from "@/api";
import type { ServiceResponse } from "@/api";
import type { Category } from "@/domains/products/models";
import { getQuestionSchema } from "@/shared/models";
import type { Question } from "@/shared/models";

export const getInitialQuestion = async (bmi?: number) => {
  const { data } = await publicAPI.get<ServiceResponse<Question>>(
    `/answer-groups/?bmi=${bmi}`,
  );

  return getQuestionSchema().parse(data.data);
};

export const getQuestion = async (questionId: Question["id"]) => {
  const { data } = await publicAPI.get<ServiceResponse<Question>>(
    `/answer-groups/${questionId}`,
  );

  return getQuestionSchema().parse(data.data);
};

export const getCategoryQuestions = async (category: Category["id"]) => {
  const { data } = await publicAPI.get<ServiceResponse<Question[]>>(
    `/product-categories/${category}/answer-groups/`,
  );

  return z.array(getQuestionSchema()).parse(data.data);
};
