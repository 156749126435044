import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";
import type { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";

interface AnalyticsContextProps {
  analytics: AnalyticsBrowser;
}

const AnalyticsContext = createContext<AnalyticsContextProps | null>(null);

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error(
      "useAnalyticsContext must be used within a <AnalyticsContextProvider />",
    );
  }

  return context;
};

type AnalyticsContextProviderProps = PropsWithChildren & {
  analytics: AnalyticsBrowser;
};

export const AnalyticsContextProvider = ({
  analytics,
  children,
}: AnalyticsContextProviderProps) => {
  return (
    <AnalyticsContext.Provider
      value={{
        analytics,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
