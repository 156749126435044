import type { RouteObject } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { PortalRoutesWrapper } from "@/shared/components";
import { MyMedsOverview, RefillRequest } from "../screens";
import { MY_MEDS_ROUTE } from "./constants";

export const getMyMedsRoute = (): RouteObject => ({
  path: MY_MEDS_ROUTE.BASE,
  element: <PortalRoutesWrapper />,

  children: [
    { index: true, element: <MyMedsOverview /> },

    { path: MY_MEDS_ROUTE.REFILL_REQUEST, element: <RefillRequest /> },

    { path: "*", element: <Navigate replace to={MY_MEDS_ROUTE.BASE} /> },
  ],
});
