import { Fragment } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { EMBEDDABLES_ONBOARDING_ROUTE, INTAKE_FORM_ROUTE } from "@/domains";
import { usePatientQuery } from "@/hooks";
import type { PortalUser } from "@/shared/models";
import { usePortalUserStore } from "@/stores";
import {
  Alert,
  ArrowRightIcon,
  Badge,
  Button,
  DetailCard,
  FileTextIcon,
  Label,
} from "@/ui";

interface IncompleteIntakeFormAlertProps {
  subscriptions: PortalUser["subscriptions"];
}

export const IncompleteIntakeFormAlert = ({
  subscriptions,
}: IncompleteIntakeFormAlertProps) => {
  const isFromEmbeddables = usePortalUserStore((state) =>
    Boolean(state.portalUser?.isFromEmbeddables),
  );
  const { data: patient, isFetching } = usePatientQuery();

  const navigate = useNavigate();

  const uniqueCategories = new Set();

  const filteredSubscriptions = subscriptions.filter((subscription) => {
    const categoryName = subscription.product.category.name;

    if (!uniqueCategories.has(categoryName)) {
      uniqueCategories.add(categoryName);
      return true;
    }

    return false;
  });

  const handleCompleteIntake = (
    subscription: PortalUser["subscriptions"][number],
  ) => {
    if (isFromEmbeddables && !patient?.dateOfBirth) {
      return navigate(EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO);
    }

    navigate(INTAKE_FORM_ROUTE.CHOOSE_JOURNEY, {
      state: subscription,
    });
  };

  return (
    <Alert type="warning">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1">
          <h6 className="font-bold">{t`You have pending intake forms`}</h6>
          <p>
            {t`Please complete them to be able to join video consultations or order medication.`}
          </p>
        </div>

        <div className="-ml-6">
          <div className="flex flex-col gap-3">
            {filteredSubscriptions.map((subscription) => (
              <Fragment key={subscription.id}>
                <DetailCard.Root className="border-yellow-500 bg-yellow-01 md:flex-row">
                  <DetailCard.Header className="flex grow items-center gap-2 pr-0">
                    <div className="flex grow flex-wrap items-center gap-x-2 md:grow-0">
                      <DetailCard.Title className="flex items-center gap-1">
                        <FileTextIcon className="size-4 text-yellow-10" />

                        <Label
                          size="md"
                          className="line-clamp-2 font-bold text-yellow-10"
                        >
                          {subscription.product.category.name}
                        </Label>
                      </DetailCard.Title>

                      <DetailCard.Description className="text-yellow-08">{t`5 min`}</DetailCard.Description>
                    </div>

                    <Badge
                      rounded="3xl"
                      className="border-transparent bg-red-02 text-red-07"
                    >
                      {t`Incomplete`}
                    </Badge>
                  </DetailCard.Header>

                  <Button
                    disabled={isFetching}
                    onClick={() => handleCompleteIntake(subscription)}
                    size="sm"
                  >
                    {t`Complete`}
                    <ArrowRightIcon className="size-3" />
                  </Button>
                </DetailCard.Root>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </Alert>
  );
};
