import { t } from "ttag";

import { PaymentStatusChip } from "@/components";
import { PAYMENT_STATUS } from "@/shared.constants";
import type { Payment } from "@/shared.types";
import { LinkMinimalistic, TableCard } from "@/ui";
import { formatToFrontendDate } from "@/utils";

interface PaymentHistoryCardsProps {
  payments?: Payment[];
}

export const PaymentHistoryCards = ({ payments }: PaymentHistoryCardsProps) => {
  if (!payments?.length) {
    return null;
  }

  return (
    <TableCard.Root className="border-transparent bg-white">
      {payments?.map(
        ({
          id,
          status,
          amount,
          hostedInvoiceUrl,
          date,
          productCategory,
          product,
        }) => (
          <TableCard.Row key={`past-payment-${id}`}>
            <PaymentStatusChip status={status} />

            {status === PAYMENT_STATUS.FAILED && (
              <p className="text-sm font-medium text-red-07">
                {t`You can retry by updating your payment method.`}
              </p>
            )}

            <TableCard.Cell>
              <TableCard.Data className="text-lg">
                {t`Subscription payment`}
              </TableCard.Data>
            </TableCard.Cell>

            <TableCard.Cell>
              <TableCard.Label>{productCategory.name}</TableCard.Label>
              <TableCard.Data>${amount}</TableCard.Data>
            </TableCard.Cell>

            <TableCard.Cell>
              <TableCard.Label>{t`Date`}</TableCard.Label>
              <TableCard.Data>
                {formatToFrontendDate(new Date(date))}
              </TableCard.Data>
            </TableCard.Cell>

            <TableCard.Cell>
              <TableCard.Label>{t`Drug`}</TableCard.Label>
              <TableCard.Data>{product.name}</TableCard.Data>
            </TableCard.Cell>

            <TableCard.Cell>
              <TableCard.Label>{t`Dose`}</TableCard.Label>
              <TableCard.Data>{product.dose ?? "-"}</TableCard.Data>
            </TableCard.Cell>

            {hostedInvoiceUrl && (
              <a
                href={hostedInvoiceUrl}
                target="_blank"
                rel="noreferrer"
                className="flex items-center gap-0.5 text-nature-08"
                onClick={() => window.open(hostedInvoiceUrl)}
              >
                <LinkMinimalistic className="size-4" />
                {t`Invoice`}
              </a>
            )}
          </TableCard.Row>
        ),
      )}
    </TableCard.Root>
  );
};
