import { t } from "ttag";

import { PLAN_DURATION } from "@/domains/billing/models";
import { Badge, Tabs } from "@/ui";

export const TabList = () => {
  return (
    <Tabs.List>
      <Tabs.Trigger
        value={PLAN_DURATION.MONTHLY.TEXT_VALUE}
        className="flex-col"
      >
        {t`Month-to-month`}
      </Tabs.Trigger>

      <Tabs.Trigger
        value={PLAN_DURATION.QUARTERLY.TEXT_VALUE}
        className="relative flex-wrap gap-2"
      >
        {t`Three month`}
        <Badge
          size="sm"
          rounded="3xl"
          className="absolute right-0 top-0 -translate-y-4.5 translate-x-3 bg-nature-08 text-salmon-01 lg:relative lg:translate-x-0 lg:translate-y-0"
        >
          {t`Best value!`}
        </Badge>
      </Tabs.Trigger>
    </Tabs.List>
  );
};
