import { useId } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import {
  BMIScoreAlert,
  NavigationButtons,
} from "@/domains/onboarding/components";
import { useBMIForm } from "@/domains/onboarding/hooks";
import { ONBOARDING_ROUTE } from "@/domains/onboarding/router";
import { useOnboardingStore } from "@/domains/onboarding/stores";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { useBoolean } from "@/hooks";
import { MIN_BMI_VALUE } from "@/shared.constants";
import { Form, Input, RulerIcon, Tuning3Icon } from "@/ui";
import { calculateBMI } from "@/utils";
import { DQByBMI } from "./DQByBMI";
import { WeightLossDQModal } from "./WeightLossDQModal";

export const BMIForm = () => {
  const formId = useId();
  const selectedCategories = useOnboardingStore(
    (state) => state.selectedCategories,
  );
  const setSelectedCategories = useOnboardingStore(
    (state) => state.setSelectedCategories,
  );
  const setBmiValues = useOnboardingStore((state) => state.setBmiValues);

  const {
    value: showDQByBMI,
    setTrue: openDQByBMI,
    setFalse: closeDQByBMI,
  } = useBoolean(false);

  const {
    value: showWeightLossDQModal,
    setTrue: openWeightLossDQModal,
    setFalse: closeWeightLossDQModal,
  } = useBoolean(false);

  const form = useBMIForm();
  const formErrors = form.formState.errors;

  const navigate = useNavigate();

  const handleSubmit = form.handleSubmit((value) => {
    setBmiValues(value);

    if (calculateBMI(value) < MIN_BMI_VALUE) {
      const onlyWLSelected = selectedCategories.every(
        (category) => category.slug === CATEGORY_SLUG.WEIGHT_LOSS,
      );

      return onlyWLSelected ? openDQByBMI() : openWeightLossDQModal();
    }

    navigate(ONBOARDING_ROUTE.TREATMENT_HEALTH_ELIGIBILITY_QUESTIONS);
  });

  const handleWeightLossDQContinue = () => {
    navigate(ONBOARDING_ROUTE.TREATMENT_HEALTH_ELIGIBILITY_QUESTIONS);

    setSelectedCategories(
      selectedCategories.filter(
        (category) => category.slug !== CATEGORY_SLUG.WEIGHT_LOSS,
      ),
    );
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Form.Provider {...form}>
        <form id={formId} className="flex grow flex-col gap-10 md:gap-12">
          <div className="flex grow flex-col gap-1.5">
            <div className="grid gap-x-8 gap-y-1.5 sm:grid-cols-3">
              <Form.Item>
                <Form.Label aria-required>{t`Height (feet)`}</Form.Label>
                <Form.Control>
                  <Input
                    {...form.register("heightFeet", { valueAsNumber: true })}
                    type="number"
                    placeholder={t`E.g. 5`}
                    left={<RulerIcon />}
                  />
                </Form.Control>
                <Form.Message compact={false}>
                  {formErrors.heightFeet?.message}
                </Form.Message>
              </Form.Item>

              <Form.Item>
                <Form.Label aria-required>{t`Height (inches)`}</Form.Label>
                <Form.Control>
                  <Input
                    {...form.register("heightInches", { valueAsNumber: true })}
                    type="number"
                    placeholder={t`E.g. 3`}
                    left={<RulerIcon />}
                  />
                </Form.Control>
                <Form.Message compact={false}>
                  {formErrors.heightInches?.message}
                </Form.Message>
              </Form.Item>

              <Form.Item>
                <Form.Label aria-required>{t`Weight (pounds)`}</Form.Label>
                <Form.Control>
                  <Input
                    {...form.register("weight", { setValueAs: Number })}
                    type="number"
                    placeholder={t`E.g. 286`}
                    left={<Tuning3Icon />}
                  />
                </Form.Control>
                <Form.Message compact={false}>
                  {formErrors.weight?.message}
                </Form.Message>
              </Form.Item>
            </div>

            <BMIScoreAlert control={form.control} />
          </div>

          <NavigationButtons
            onCancel={handleBack}
            onContinue={handleSubmit}
            continueType="submit"
            continueForm={formId}
          />
        </form>
      </Form.Provider>

      <DQByBMI open={showDQByBMI} onGoBack={closeDQByBMI} />

      <WeightLossDQModal
        open={showWeightLossDQModal}
        onGoBack={closeWeightLossDQModal}
        onContinue={handleWeightLossDQContinue}
      />
    </>
  );
};
