import { Navigate, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ErrorState } from "@/components";
import { OnboardingHeader } from "@/domains/onboarding/components";
import { ONBOARDING_ROUTE } from "@/domains/onboarding/router";
import {
  CategoriesSelection,
  DQByUserAttributes,
} from "@/domains/onboarding/sections";
import { useOnboardingStore } from "@/domains/onboarding/stores";
import { LoadingCategories } from "@/domains/products/components";
import { useProductCategories } from "@/domains/products/hooks";

export const ChooseCategories = () => {
  const userAttributes = useOnboardingStore((state) => state.userAttributes);

  const navigate = useNavigate();

  const {
    data: categories,
    isLoading,
    isSuccess,
    isError,
  } = useProductCategories(userAttributes);

  if (isError) {
    return <ErrorState className="grow-0 border-none" />;
  }

  if (!categories && !isLoading) {
    return <Navigate to={ONBOARDING_ROUTE.MAIN_INFO} />;
  }

  return (
    <>
      <OnboardingHeader
        title={t`Your journey starts here`}
        description={t`Here are the plans available for you.`}
      />

      {isLoading && <LoadingCategories />}

      {isSuccess && <CategoriesSelection categories={categories} />}

      <DQByUserAttributes
        open={isSuccess && !categories.length}
        onGoBack={() => navigate(ONBOARDING_ROUTE.MAIN_INFO)}
      />
    </>
  );
};
