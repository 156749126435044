import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import type { z } from "zod";

import { getBmiSchema } from "@/shared/models";
import { useOnboardingStore } from "../stores";

export const useBMIForm = () => {
  const bmiValues = useOnboardingStore((state) => state.bmiValues);

  const formSchema = getBmiSchema();

  return useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: bmiValues,
  });
};
