import { useId } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { t } from "ttag";

import { FullScreenLoader, PasswordTips } from "@/components";
import { setPassword } from "@/domains/onboarding/api";
import {
  NavigationButtons,
  SuccessAlert,
} from "@/domains/onboarding/components";
import { ONBOARDING_ROUTE } from "@/domains/onboarding/router";
import { useOnboardingStore } from "@/domains/onboarding/stores";
import { useBoolean } from "@/hooks";
import { getConfirmPasswordSchema } from "@/shared.schemas";
import type { PasswordFormValues } from "@/shared.types";
import { Form, PasswordInput } from "@/ui";
import { handleAxiosFieldErrors } from "@/utils";

export const PasswordForm = () => {
  const formId = useId();
  const { value: showSuccessAlert, setTrue: openSuccessAlert } =
    useBoolean(false);

  const token = useOnboardingStore((state) => state.healthieUserToken);

  const form = useForm<PasswordFormValues>({
    resolver: zodResolver(getConfirmPasswordSchema()),
  });

  const setPasswordMutation = useMutation({
    mutationFn: setPassword,
    onSuccess: () => {
      openSuccessAlert();
    },
    onError: (error) => {
      handleAxiosFieldErrors(error, form.setError);
    },
  });

  if (!token) {
    return <Navigate to={ONBOARDING_ROUTE.MAIN_INFO} />;
  }

  const handleNext = form.handleSubmit((data) => {
    setPasswordMutation.mutate({ ...data, token });
  });

  return (
    <>
      <Form.Provider {...form}>
        <section className="grow">
          <form
            id={formId}
            className="grid content-start gap-x-8 gap-y-1.5 md:grid-cols-2"
          >
            <Form.Field
              control={form.control}
              name="password"
              render={() => (
                <Form.Item>
                  <Form.Label aria-required>{t`Password`}</Form.Label>
                  <Form.Control>
                    <PasswordInput
                      {...form.register("password")}
                      placeholder={t`Enter your password`}
                    />
                  </Form.Control>
                  <Form.Message compact={false} />
                </Form.Item>
              )}
            />

            <Form.Field
              control={form.control}
              name="passwordConfirmation"
              render={() => (
                <Form.Item>
                  <Form.Label aria-required>{t`Confirm password`}</Form.Label>
                  <Form.Control>
                    <PasswordInput
                      {...form.register("passwordConfirmation")}
                      placeholder={t`Confirm your password`}
                    />
                  </Form.Control>
                  <Form.Message compact={false} />
                </Form.Item>
              )}
            />
          </form>

          <PasswordTips />
        </section>

        <NavigationButtons
          onContinue={handleNext}
          continueLabel={t`Submit`}
          continueDisabled={setPasswordMutation.isPending}
          continueType="submit"
          continueForm={formId}
        />
      </Form.Provider>

      {setPasswordMutation.isPending && <FullScreenLoader />}

      <SuccessAlert open={showSuccessAlert} />
    </>
  );
};
