import { t } from "ttag";
import { z } from "zod";

import {
  getDateOfBirthSchema,
  getPhoneNumberSchema,
  getZipCodeSchema,
} from "@/shared.schemas";
import { getAvailableLanguageSchema, getBmiSchema } from "@/shared/models";

export const getUserContactInfoSchema = () =>
  z.object({
    firstName: z
      .string()
      .min(1, { message: t`First name is required` })
      .default(""),
    lastName: z.string().min(1, { message: t`Last name is required` }),
    email: z
      .string()
      .min(1, { message: t`Email is required` })
      .email({ message: t`Invalid email` }),
    phoneNumber: getPhoneNumberSchema(),
    dateOfBirth: getDateOfBirthSchema(),
    marketingEmailConsent: z.boolean().default(false).optional(),
    marketingSMSConsent: z.boolean().default(false).optional(),
  });

export const getCreateUserSchema = () =>
  getUserContactInfoSchema()
    .merge(getBmiSchema().partial())
    .extend({
      dateOfBirth: z.date(),
      gender: z.string().min(1, { message: t`Gender is required` }),
    });

export const getOnboardingUserSchema = () =>
  getUserContactInfoSchema()
    .omit({ phoneNumber: true })
    .extend({
      ehrId: z.string().uuid().nullable(),
      code: z.string().uuid(),
      gender: z.string().min(1, { message: t`Gender is required` }),
      dateOfBirth: z.string(),
      state: z.string().min(1, { message: t`State is required` }),
    });

export const getCheckoutSchema = () =>
  z.object({
    creditCard: z.literal<boolean>(true, {
      errorMap: () => ({ message: t`Credit card is required` }),
    }),
    authorization: z.literal<boolean>(true, {
      errorMap: () => ({ message: t`Authorization is required` }),
    }),
    couponCode: z.string().optional(),
    address: z.string().min(1, { message: t`Address is required` }),
    apartmentNumber: z.string(),
    zipCode: getZipCodeSchema(),
    city: z.string().min(1, { message: t`City is required` }),
  });

export const getCreateHealthieUserParamsSchema = () =>
  z.object({
    userCode: z.string(),
    providerId: z.string().optional(),
    appointmentDate: z.string().optional(),
    line1: z.string().min(1),
    line2: z.string().optional(),
    zip: z.string().min(1),
    state: z.string().min(1),
    city: z.string().min(1),
    phoneNumber: getPhoneNumberSchema(),
    language: getAvailableLanguageSchema().optional(),
  });
