import type {
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementRef,
} from "react";
import { createContext, forwardRef, useContext } from "react";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { Shape7, Shape11 } from "../shapes";
import { Button } from "./Button";
import { ScrollArea } from "./ScrollArea";

const fullScreenAlertVariant = tv({
  slots: {
    overlay:
      "fixed inset-0 z-50 flex basis-1/2 bg-salmon-02 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
    background:
      "absolute left-1/2 hidden size-full bg-salmon-09 text-salmon-07 md:block md:translate-x-80 lg:translate-x-48 xl:translate-x-36",
    primitiveContent:
      "bg-background fixed left-1/2 top-1/2 z-50 flex h-full max-h-desktop w-full max-w-desktop translate-x-[-50%] translate-y-[-50%] gap-4 duration-200 md:items-center",
    content:
      "flex h-full max-h-dvh w-full grow flex-col gap-6 px-6 py-12 md:h-fit md:max-w-2xl md:gap-5 md:px-0 md:pl-20 xl:max-w-3xl",
    header:
      "relative flex flex-col items-center gap-1 pb-28 text-center text-salmon-01 md:items-start md:pb-0 md:text-left md:text-salmon-10",
    headerMobileBackground:
      "absolute bottom-0 left-1/2 -z-10 block size-219 -translate-x-1/2 rounded-full bg-salmon-09 md:hidden md:pb-0",
    footer: "flex flex-col-reverse gap-2.5 pb-12 pt-7 md:flex-row",
    title: "font-serif text-4xl md:text-5xl md:leading-snug",
    description: "grow text-center text-xl text-salmon-10 md:text-left",
    actionButton: "",
    cancelButton: "",
  },

  variants: {
    color: {
      salmon: {
        overlay: "bg-salmon-02",
        background: "bg-salmon-09 text-salmon-07",
        header: "text-salmon-01 md:text-salmon-10",
        headerMobileBackground: "bg-salmon-09",
        description: "text-salmon-10",
      },
      nature: {
        overlay: "bg-nature-02",
        background: "bg-nature-09 text-nature-08",
        header: "text-nature-01 md:text-nature-10",
        headerMobileBackground: "bg-nature-09",
        description: "text-nature-10",
      },
    },
  },

  defaultVariants: {
    color: "salmon",
  },
});

type FullScreenAlertVariant = VariantProps<typeof fullScreenAlertVariant>;

const FullScreenAlertVariantContext =
  createContext<FullScreenAlertVariant["color"]>("salmon");

const Root = ({
  color,
  ...props
}: ComponentProps<typeof AlertDialogPrimitive.Root> &
  Pick<FullScreenAlertVariant, "color">) => (
  <FullScreenAlertVariantContext.Provider value={color}>
    <AlertDialogPrimitive.Root {...props} />
  </FullScreenAlertVariantContext.Provider>
);

const Portal = AlertDialogPrimitive.Portal;

const {
  overlay,
  background,
  primitiveContent,
  content,
  header,
  headerMobileBackground,
  footer,
  title,
  description,
  actionButton,
  cancelButton,
} = fullScreenAlertVariant();

const Overlay = forwardRef<
  ElementRef<typeof AlertDialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay>
>(({ className, ...props }, ref) => {
  const color = useContext(FullScreenAlertVariantContext);

  return (
    <AlertDialogPrimitive.Overlay
      className={overlay({ color, className })}
      {...props}
      ref={ref}
    >
      <div className={background({ color })}>
        <Shape7 className="absolute top-1/2 size-[464px] -translate-x-20 -translate-y-full" />
        <Shape11 className="absolute top-1/2 size-[464px] translate-x-48 translate-y-[110px] -scale-x-100" />
      </div>
    </AlertDialogPrimitive.Overlay>
  );
});
Overlay.displayName = AlertDialogPrimitive.Overlay.displayName;

const Content = forwardRef<
  ElementRef<typeof AlertDialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <Portal>
    <Overlay />
    <AlertDialogPrimitive.Content
      ref={ref}
      className={primitiveContent()}
      {...props}
    >
      <ScrollArea>
        <div className={content({ className })}>{children}</div>
      </ScrollArea>
    </AlertDialogPrimitive.Content>
  </Portal>
));
Content.displayName = "FullScreenAlert.Content";

const Header = ({
  className,
  children,
  ...props
}: ComponentPropsWithoutRef<"div">) => {
  const color = useContext(FullScreenAlertVariantContext);
  return (
    <div className={header({ color, className })} {...props}>
      <div className={headerMobileBackground({ color })} />

      {children}
    </div>
  );
};
Header.displayName = "FullScreenAlert.Header";

const Footer = ({ className, ...props }: ComponentPropsWithoutRef<"div">) => (
  <div className={footer({ className })} {...props} />
);
Footer.displayName = "FullScreenAlert.Footer";

const Title = forwardRef<
  ElementRef<typeof AlertDialogPrimitive.Title>,
  ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Title
    ref={ref}
    className={title({ className })}
    {...props}
  />
));
Title.displayName = "FullScreenAlert.Title";

const Description = forwardRef<
  ElementRef<typeof AlertDialogPrimitive.Description>,
  ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Description>
>(({ className, ...props }, ref) => {
  const color = useContext(FullScreenAlertVariantContext);

  return (
    <AlertDialogPrimitive.Description
      ref={ref}
      className={description({ color, className })}
      {...props}
    />
  );
});
Description.displayName = "FullScreenAlert.Description";

const Action = forwardRef<
  ElementRef<typeof AlertDialogPrimitive.Action>,
  ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Action>
>(({ className, ...props }, ref) => (
  <Button asChild size="lg">
    <AlertDialogPrimitive.Action
      ref={ref}
      className={actionButton({ className })}
      {...props}
    />
  </Button>
));
Action.displayName = "FullScreenAlert.Action";

const Cancel = forwardRef<
  ElementRef<typeof AlertDialogPrimitive.Cancel>,
  ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Cancel>
>(({ className, ...props }, ref) => (
  <Button asChild size="lg" variant="secondary">
    <AlertDialogPrimitive.Cancel
      ref={ref}
      className={cancelButton({ className })}
      {...props}
    />
  </Button>
));
Cancel.displayName = "FullScreenAlert.Cancel";

export { Root, Content, Header, Footer, Title, Description, Action, Cancel };
