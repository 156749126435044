import type { PropsWithChildren } from "react";
import { createContext, useContext, useState } from "react";

import { NOW, PROVIDER_PREFERENCE } from "@/shared.constants";
import type {
  ISODate,
  ObjectValue,
  Provider,
  TimeSlot,
  USState,
} from "@/shared.types";
import type { AvailableLanguage } from "@/shared/models";
import { formatToBackendDate } from "@/utils";

const defaultDateFromMonth = formatToBackendDate(
  new Date(NOW.getFullYear(), NOW.getMonth(), 1),
);

interface ScheduleDate {
  dateFromMonth: ISODate;
  appointmentDate: ISODate | null;
  timeSlot: TimeSlot | null;
}

interface ScheduleContextProps {
  state?: USState["value"];
  providerPreference: ProviderPreference;
  provider?: Provider;
  scheduleDate: ScheduleDate;
  language: AvailableLanguage;

  actions: {
    setProviderPreference: (providerPreference: ProviderPreference) => void;
    setProvider: (provider?: Provider) => void;
    setScheduleDate: React.Dispatch<React.SetStateAction<ScheduleDate>>;
    setLanguage: (language: AvailableLanguage) => void;
  };
}

type ProviderPreference = ObjectValue<typeof PROVIDER_PREFERENCE>;

const ScheduleContext = createContext<ScheduleContextProps | null>(null);

export const useScheduleConsultationContext = () => {
  const context = useContext(ScheduleContext);

  if (!context) {
    throw new Error("useSchedule must be used within a <ScheduleProvider />");
  }

  return context;
};

interface ScheduleContextProvider extends PropsWithChildren {
  defaultLanguage: AvailableLanguage;
  state?: USState["value"];
}

export const ScheduleContextProvider = ({
  children,
  defaultLanguage,
  state,
}: ScheduleContextProvider) => {
  const [providerPreference, setProviderPreference] =
    useState<ProviderPreference>(PROVIDER_PREFERENCE.EARLIEST);
  const [provider, setProvider] = useState<Provider>();
  const [scheduleDate, setScheduleDate] = useState<ScheduleDate>({
    dateFromMonth: defaultDateFromMonth,
    appointmentDate: null,
    timeSlot: null,
  });

  const [language, setLanguage] = useState(defaultLanguage);

  return (
    <ScheduleContext.Provider
      value={{
        state,
        providerPreference,
        provider,
        scheduleDate,
        language,
        actions: {
          setProviderPreference,
          setProvider,
          setScheduleDate,
          setLanguage,
        },
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};
