import { useEffect } from "react";
import { t } from "ttag";

import { OnboardingHeader } from "@/domains/onboarding/components";
import { CheckoutForm } from "@/domains/onboarding/sections";
import { handleTheOfferTracking, THE_OFFER_EVENTS } from "@/utils";

export const Payment = () => {
  useEffect(() => {
    handleTheOfferTracking({ event: THE_OFFER_EVENTS.INITIATE_CHECKOUT });
  }, []);

  return (
    <>
      <OnboardingHeader
        title={t`Payment details`}
        description={t`(*) Mandatory field`}
      />

      <CheckoutForm />
    </>
  );
};
