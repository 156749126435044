import type { ComponentPropsWithoutRef } from "react";
import { Fragment } from "react/jsx-runtime";

import { useOnboardingStore } from "@/domains/onboarding/stores";
import { ProductSummary, TotalSummary } from "@/domains/products/components";
import { CardPrimitive as Card } from "@/ui";
import { tw } from "@/utils";

type SelectedProductsProps = ComponentPropsWithoutRef<typeof Card.Root> & {
  readOnly?: boolean;
};

export const SelectedProducts = ({
  readOnly,
  className,
  ...props
}: SelectedProductsProps) => {
  const selectedCategories = useOnboardingStore(
    (state) => state.selectedCategories,
  );

  const setSelectedPrice = useOnboardingStore(
    (state) => state.setSelectedPrice,
  );
  const removeSelectedProduct = useOnboardingStore(
    (state) => state.removeSelectedProduct,
  );
  const coupon = useOnboardingStore((state) => state.coupon);

  return (
    <Card.Root
      className={tw("h-fit w-full max-w-xl bg-salmon-01 p-4 sm:p-5", className)}
      {...props}
    >
      {selectedCategories.map(({ name, selectedProduct }) => (
        <Fragment key={selectedProduct?.id}>
          <Card.Content className="flex flex-col gap-4">
            <Card.Subtitle>{name}</Card.Subtitle>

            <ProductSummary
              coupon={coupon}
              product={selectedProduct}
              readOnly={readOnly}
              onPriceChange={setSelectedPrice}
              onRemove={removeSelectedProduct}
            />
          </Card.Content>

          <Card.Separator className="my-2" />
        </Fragment>
      ))}

      <TotalSummary categories={selectedCategories} coupon={coupon} />
    </Card.Root>
  );
};
