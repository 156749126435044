import type { ComponentPropsWithoutRef } from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { SIZE } from "@/shared.constants";
import {
  CheckCircleIcon,
  CloseCircleIcon,
  InfoCircleIcon,
  WarningCircleIcon,
} from "../icons";
import { IconWrapper } from "./Icons";

// Figma: https://www.figma.com/design/wuTj0UhW8hs2D9S6ubvrSo/Fridays-Components?node-id=508-2701&t=CA2tykRhjBM9V2sc-4

const alertVariance = tv({
  slots: {
    root: "rounded-2xl border p-4 text-left leading-5",
    content: "flex gap-2",
    iconContainer: "flex h-5 items-center",
  },

  variants: {
    type: {
      info: {
        root: "border-nature-10 bg-brown-03 text-nature-10",
      },
      error: {
        root: "border-red-08 bg-red-01 text-red-08",
      },
      warning: {
        root: "border-yellow-10 bg-yellow-02 text-yellow-10",
      },
      success: {
        root: "border-green-10 bg-green-01 text-green-10",
      },
    },

    variant: {
      solid: { root: "border-transparent" },
      outlined: { root: "bg-transparent" },
    },
  },

  defaultVariants: {
    type: "info",
    variant: "solid",
  },
});

const ALERT_ICON = {
  info: <InfoCircleIcon />,
  error: <CloseCircleIcon />,
  warning: <WarningCircleIcon />,
  success: <CheckCircleIcon />,
} as const;

type AlertVariance = VariantProps<typeof alertVariance>;

interface AlertProps extends AlertVariance, ComponentPropsWithoutRef<"div"> {
  customIcon?: JSX.Element;
}

export const Alert = ({
  type = "info",
  variant = "solid",
  children,
  customIcon,
  className,
}: AlertProps) => {
  const { root, content, iconContainer } = alertVariance({ type, variant });

  const ChildrenContainer = typeof children === "string" ? "p" : "div";

  return (
    <div className={root({ variant, className })}>
      <div className={content()}>
        <div className={iconContainer()}>
          <IconWrapper size={SIZE.SMALL}>
            {customIcon ?? ALERT_ICON[type]}
          </IconWrapper>
        </div>

        <ChildrenContainer className="w-full">{children}</ChildrenContainer>
      </div>
    </div>
  );
};
