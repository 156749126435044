import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "ttag";

import type { Plan } from "@/domains/billing/models";
import { PLAN_DURATION } from "@/domains/billing/models";
import { usePatientProductsByCategory } from "@/domains/products/hooks";
import type { Category, Product } from "@/domains/products/models";
import { Tabs } from "@/ui";
import {
  CurrentRequestAlert,
  PortalSubscriptionPlans,
  TabList,
} from "./components";

interface ChangeSubscriptionContentProps {
  category: Pick<Category, "id" | "slug" | "name">;
}

const transformProductsToPlans = (products: Product[]): Plan[] => {
  return products.flatMap((product) =>
    product.prices.map((price) => ({
      name: product.name,
      subscriptionPriceId: price.subscriptionPriceId,
      price: price.price,
      features: product.features,
      isCompound: product.tags.some((tag) => tag.label === "Compounded"),
      description: product.description,
      disclaimer: product.disclaimer,
      thumbnail: product.thumbnail,
      durationInMonths: price.durationInMonths,
      priceId: price.id,
    })),
  );
};

export const ChangeSubscriptionContent = ({
  category,
}: ChangeSubscriptionContentProps) => {
  const { reset } = useFormContext();

  const {
    data: products,
    isSuccess,
    isLoading,
  } = usePatientProductsByCategory(category.id);

  const transformedPlans = useMemo(() => {
    if (isSuccess && products) {
      return transformProductsToPlans(products);
    }
    return [];
  }, [isSuccess, products]);

  return (
    <Tabs.Root
      defaultValue={PLAN_DURATION.MONTHLY.TEXT_VALUE}
      onValueChange={() => reset()}
      variant="secondary"
    >
      <TabList />

      <CurrentRequestAlert />

      <div className="grow pt-0 lg:pb-6">
        <Tabs.Content
          value={PLAN_DURATION.MONTHLY.TEXT_VALUE}
          className="flex flex-col gap-3"
        >
          <p className="-mt-3 text-center text-sm lg:hidden">{t`Get a 1 month supply`}</p>

          <PortalSubscriptionPlans
            isLoading={isLoading}
            plans={transformedPlans.filter(
              (plan) => plan.durationInMonths === PLAN_DURATION.MONTHLY.VALUE,
            )}
          />
        </Tabs.Content>

        <Tabs.Content
          value={PLAN_DURATION.QUARTERLY.TEXT_VALUE}
          className="flex flex-col gap-3"
        >
          <p className="-mt-3 text-center text-sm lg:hidden">
            {t`Cover all 3 months of doses with a single order`}
          </p>

          <PortalSubscriptionPlans
            isLoading={isLoading}
            plans={transformedPlans.filter(
              (plan) => plan.durationInMonths === PLAN_DURATION.QUARTERLY.VALUE,
            )}
          />
        </Tabs.Content>
      </div>
    </Tabs.Root>
  );
};
