import { useId } from "react";

import { ShowcaseCardSkeleton } from "@/ui";
import { ProductItem } from "./ProductItem";
import { ProductList } from "./ProductList";

interface LoadingProductsProps {
  length?: number;
}

export const LoadingProducts = ({ length = 2 }: LoadingProductsProps) => {
  const id = useId();

  return (
    <div className="pointer-events-none pb-10">
      <ProductList>
        {Array.from({ length }, (_, index) => `${id}-${index}`).map((key) => (
          <ProductItem length={length} key={key}>
            <ShowcaseCardSkeleton withThumbnail />
          </ProductItem>
        ))}
      </ProductList>
    </div>
  );
};
