import { t } from "ttag";

import { SIZE } from "@/shared.constants";
import { AlertDialog, Button, illustratedIcons } from "@/ui";

interface PaymentSucceededAlertProps {
  show: boolean;
  onContinue: () => void;
}

export const PaymentSucceededAlert = ({
  show,
  onContinue,
}: PaymentSucceededAlertProps) => {
  return (
    <AlertDialog
      show={show}
      onClose={onContinue}
      icon={<illustratedIcons.Hearts />}
      title={t`Payment successfully processed!`}
      description={
        <span id="payment-successfully-processed">
          {t`Your payment has been processed correctly.`}
          <br />
          {t`You can now create your password to continue`}
        </span>
      }
      className="max-w-152"
      renderActions={({ AlertDialogAction }) => (
        <AlertDialogAction asChild className="self-center">
          <Button
            size={SIZE.LARGE}
            className="w-fit"
            id="payment-successfully-processed-button"
          >{t`Create password`}</Button>
        </AlertDialogAction>
      )}
    />
  );
};
