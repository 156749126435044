import { useMutation } from "@tanstack/react-query";
import { parseAsInteger, useQueryState } from "nuqs";

import { useAnalyticsContext } from "@/contexts";
import {
  analyticsPaymentCompleted,
  analyticsPaymentFailed,
} from "@/domains/analytics/utils";
import { useStripeCreatePaymentMethod } from "@/domains/billing/hooks";
import type { CreatedStripeSubscription } from "@/domains/billing/models";
import { errorToast } from "@/ui";
import { trackConversion } from "@/utils";
import type { CheckoutFormValues, UseCheckoutParams } from "../models";
import { useOnboardingStore } from "../stores";
import { useStripeConfirmCardPayment } from "./useStripeConfirmCardPayment";

type UserLocationData = Pick<
  CheckoutFormValues,
  "city" | "zipCode" | "address" | "apartmentNumber"
>;

export const useConfirmSubscriptionsPayment = ({
  user,
  selectedCategories,
  coupon,
}: UseCheckoutParams) => {
  const { analytics } = useAnalyticsContext();
  const analyticsCustomerId = useOnboardingStore(
    (state) => state.analyticsCustomerId,
  );
  const [everflowOfferId] = useQueryState("oid", parseAsInteger.withDefault(0));

  const { mutateAsync: confirmCardMutateAsync } = useStripeConfirmCardPayment();
  const createPaymentMethod = useStripeCreatePaymentMethod();

  const handleAsyncSubscriptions = async ({
    subscriptions,
    locationData,
  }: {
    subscriptions: CreatedStripeSubscription[];
    locationData: UserLocationData;
  }) => {
    const settledSubscriptions = await Promise.allSettled(
      subscriptions.map((subscription) =>
        confirmCardMutateAsync({ ...subscription, locationData }),
      ),
    );

    if (
      settledSubscriptions.every(
        (subscription) => subscription.status === "rejected",
      )
    ) {
      throw new Error(
        "Error when processing the payment. Please try with another card or contact support",
      );
    }

    return settledSubscriptions;
  };

  const baseAnalyticsData = {
    categories: selectedCategories,
    coupon,
  };

  return useMutation({
    mutationFn: handleAsyncSubscriptions,
    onSuccess: (settledSubscriptions) => {
      const shouldCreatePaymentMethod =
        settledSubscriptions.some(
          (subscription) => subscription.status === "fulfilled",
        ) &&
        !settledSubscriptions.some(
          (subscription) =>
            subscription.status === "fulfilled" &&
            subscription.value.paymentIntentId,
        );

      if (shouldCreatePaymentMethod && user) {
        createPaymentMethod.mutate({ userCode: user.code });
      }

      if (user) {
        const succeededPayments = settledSubscriptions.filter(
          (subscription) => subscription.status === "fulfilled",
        );
        const succeededPaymentIds = succeededPayments
          .filter((subscription) => subscription.value.paymentIntentId)
          .map((subscription) => subscription.value.paymentIntentId!);

        analyticsPaymentCompleted({
          ...baseAnalyticsData,
          user,
          succeededPaymentIds,
          analytics,
          analyticsCustomerId,
        });

        trackConversion({
          ...baseAnalyticsData,
          user,
          succeededPaymentIds,
          everflowOfferId,
        });
      }
    },
    onError: (error) => {
      errorToast(error);
      if (user) {
        analyticsPaymentFailed({
          ...baseAnalyticsData,
          user,
          analytics,
        });
      }
    },
  });
};
