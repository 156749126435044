export const MEDICATION_REQUEST_STATUS = {
  PAID: "paid",
  PENDING: "pending",
  APPROVED: "approved",
  SHIPPED: "shipped",
  ON_ITS_WAY: "on its way",
  CANCELLED: "cancelled",
  DENIED: "denied",
  DELIVERY_UPDATE_REQUESTED: "delivery update requested",
  REFUND_REQUESTED: "refund request",
  PROCESS_COMPLETE: "process complete",
  BRANDED_MEDICATION: "no more data",
  ARCHIVED: "archived",
} as const;
