import { ScreenLayout } from "@/layouts";
import { usePortalUserStore } from "@/stores";
import {
  DocumentCenterDoctorNotes,
  DocumentCenterHeader,
  DocumentCenterLabs,
  DocumentCenterMedicalRecords,
  DocumentCenterPrescriptions,
  FridaysResources,
} from "./components";

export const DocumentCenterOverview = () => {
  const hasActiveWLSubscription = usePortalUserStore(
    (state) => state.hasActiveWLSubscription,
  );

  return (
    <ScreenLayout className="pb-0 md:pb-0">
      <DocumentCenterHeader />

      {hasActiveWLSubscription && <FridaysResources />}

      <section className="grid shrink-0 gap-4 pb-6 md:pb-12 lg:grid-cols-2">
        <DocumentCenterLabs />

        <DocumentCenterPrescriptions />

        <DocumentCenterDoctorNotes />

        <DocumentCenterMedicalRecords />
      </section>
    </ScreenLayout>
  );
};
