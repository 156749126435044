import type {
  ComponentPropsWithoutRef,
  ElementRef,
  KeyboardEvent,
} from "react";
import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import useEmblaCarousel from "embla-carousel-react";
import type { UseEmblaCarouselType } from "embla-carousel-react";
import { tv } from "tailwind-variants";

import { ArrowLeftIcon, ArrowRightIcon } from "@/ui";

const carouselVariants = tv({
  slots: {
    root: "relative",
    content: "flex",
    item: "min-w-0 shrink-0 grow-0 basis-full",
    previousButton:
      "absolute flex items-center justify-center rounded-full bg-brown-03 p-2 text-brown-06 shadow disabled:hidden",
    nextButton:
      "absolute flex items-center justify-center rounded-full bg-brown-03 p-2 text-brown-06 shadow disabled:hidden",
  },
  variants: {
    orientation: {
      horizontal: {
        content: "-ml-4",
        item: "pl-4",
        previousButton: "-left-12 top-1/2 -translate-y-1/2 translate-x-full",
        nextButton: "-right-12 top-1/2 -translate-x-full -translate-y-1/2",
      },
      vertical: {
        content: "-mt-4 flex-col",
        item: "pt-4",
        previousButton: "-top-12 left-1/2 -translate-x-1/2 rotate-90",
        nextButton: "-bottom-12 left-1/2 -translate-x-1/2 rotate-90",
      },
    },
  },
  defaultVariants: {
    orientation: "horizontal",
  },
});

const { root, content, item, previousButton, nextButton } = carouselVariants();

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

interface CarouselProps {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: "horizontal" | "vertical";
  setApi?: (api: CarouselApi) => void;
}

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
} & CarouselProps;

const CarouselContext = createContext<CarouselContextProps | null>(null);

const useCarousel = () => {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel.Root />");
  }

  return context;
};

const Root = forwardRef<
  ElementRef<"div">,
  ComponentPropsWithoutRef<"div"> & CarouselProps
>(
  (
    {
      orientation = "horizontal",
      opts,
      setApi,
      plugins,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        align: "end",
        ...opts,
        axis: orientation === "horizontal" ? "x" : "y",
      },
      plugins,
    );
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(false);

    const onSelect = useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    }, []);

    const scrollPrev = useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext],
    );

    useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    useEffect(() => {
      if (!api) {
        return;
      }

      onSelect(api);
      api.on("reInit", onSelect);
      api.on("select", onSelect);

      return () => {
        api?.off("select", onSelect);
      };
    }, [api, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation:
            orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={root({ className })}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  },
);
Root.displayName = "Carousel.Root";

const Content = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    const { carouselRef, orientation } = useCarousel();

    return (
      <div ref={carouselRef} className="overflow-hidden">
        <div
          ref={ref}
          className={content({ className, orientation })}
          {...props}
        />
      </div>
    );
  },
);
Content.displayName = "Carousel.Content";

const Item = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    const { orientation } = useCarousel();

    return (
      <div
        ref={ref}
        role="group"
        aria-roledescription="slide"
        className={item({ className, orientation })}
        {...props}
      />
    );
  },
);
Item.displayName = "Carousel.Item";

const PreviousButton = forwardRef<
  ElementRef<"button">,
  ComponentPropsWithoutRef<"button">
>(({ className, ...props }, ref) => {
  const { orientation, scrollPrev, canScrollPrev } = useCarousel();

  return (
    <button
      type="button"
      ref={ref}
      className={previousButton({ className, orientation })}
      disabled={!canScrollPrev}
      onClick={scrollPrev}
      {...props}
    >
      <ArrowLeftIcon className="size-4" />
      <span className="sr-only">Previous slide</span>
    </button>
  );
});
PreviousButton.displayName = "Carousel.PreviousButton";

const NextButton = forwardRef<
  ElementRef<"button">,
  ComponentPropsWithoutRef<"button">
>(({ className, ...props }, ref) => {
  const { orientation, scrollNext, canScrollNext } = useCarousel();

  return (
    <button
      type="button"
      ref={ref}
      className={nextButton({ className, orientation })}
      disabled={!canScrollNext}
      onClick={scrollNext}
      {...props}
    >
      <ArrowRightIcon className="size-4" />
      <span className="sr-only">Next slide</span>
    </button>
  );
});
NextButton.displayName = "Carousel.NextButton";

export const Carousel = { Root, Content, Item, PreviousButton, NextButton };
