import { t } from "ttag";

import { tw } from "@/utils";
import { RequestCard } from ".";
import { NewMedicationRequestButton } from "../NewMedicationRequestButton";

interface EmptyMedicationRequestCardProps {
  className?: string;
}

export const EmptyMedicationRequestCard = ({
  className,
}: EmptyMedicationRequestCardProps) => {
  return (
    <RequestCard.Root className={tw("pt-4", className)}>
      <div className="flex flex-col gap-1">
        <RequestCard.Title className="font-normal text-salmon-09">
          {t`Do you need medication?`}
        </RequestCard.Title>

        <RequestCard.Description>
          {t`You don't have an open request`}
        </RequestCard.Description>
      </div>

      <RequestCard.Footer className="flex-col gap-2 pt-2 md:flex-row-reverse md:justify-end md:gap-4">
        <NewMedicationRequestButton />
      </RequestCard.Footer>
    </RequestCard.Root>
  );
};
