import { Navigate } from "react-router-dom";
import { t } from "ttag";

import { OnboardingHeader } from "@/domains/onboarding/components";
import { ONBOARDING_ROUTE } from "@/domains/onboarding/router";
import { PasswordForm } from "@/domains/onboarding/sections";
import { useOnboardingStore } from "@/domains/onboarding/stores";

export const SetPassword = () => {
  const user = useOnboardingStore((state) => state.user);

  if (!user) {
    return <Navigate to={ONBOARDING_ROUTE.MAIN_INFO} />;
  }

  return (
    <>
      <OnboardingHeader
        title={t`Create your password`}
        description={t`You are creating an account for the email ${user.email}`}
      />

      <PasswordForm />
    </>
  );
};
