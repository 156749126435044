import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import { cancelAppointment } from "@/api";
import { CATEGORY_SLUG } from "@/domains/products/models";
import type { Consultation } from "@/shared.types";
import { usePortalUserStore } from "@/stores";
import {
  Alert,
  Badge,
  Button,
  errorToast,
  icons,
  Modal,
  TrashBin2Icon,
  VideoCameraIcon,
} from "@/ui";
import { formatBackendDate, formatConsultationTime, tw } from "@/utils";
import { JoinNowButton } from "./components";
import { useCanJoinConsultation } from "./hooks";

interface ConsultationCardProps {
  consultation: Consultation;
  isFirstConsultation: boolean;
}

export const ConsultationCard = ({
  consultation,
  isFirstConsultation,
}: ConsultationCardProps) => {
  const [show, setShow] = useState(false);
  const [appointmentIdToDelete, setAppointmentIdToDelete] = useState<
    string | null
  >(null);
  const subscriptions =
    usePortalUserStore((state) => state.portalUser?.subscriptions) ?? [];
  const onClose = () => {
    setShow(false);
    setAppointmentIdToDelete(null);
  };
  const deleteAppointment = (id: string) => {
    setAppointmentIdToDelete(id);
    setShow(true);
  };

  const queryClient = useQueryClient();

  const hasOnlyHairGrowthSubs = subscriptions.every(
    (subscription) =>
      subscription.product.category.slug === CATEGORY_SLUG.HAIR_GROWTH,
  );

  const { mutate: cancelAppointmentMutation, isPending } = useMutation({
    mutationFn: cancelAppointment.mutation,
    onSuccess: () => {
      cancelAppointment.invalidates(queryClient);
      onClose();
    },
    onError: (err) => {
      errorToast(err);
    },
  });

  const { canJoin } = useCanJoinConsultation(
    consultation.startTime,
    consultation.endTime,
    isFirstConsultation,
  );

  return (
    <div
      className={tw(
        "relative flex h-full flex-col gap-4 rounded-xl border border-salmon-07 px-9 py-8",
        canJoin && "border-salmon-06 bg-salmon-01",
      )}
    >
      <div
        className={tw(
          "absolute left-0 top-0 size-10 -translate-x-1.5 -translate-y-1.5 rounded-full bg-salmon-06 p-3 text-salmon-01",
          canJoin && "bg-salmon-07 text-brown-01",
        )}
      >
        <VideoCameraIcon />
      </div>

      <div className="grid gap-x-7 gap-y-4 lg:grid-cols-[1fr_auto]">
        <div className="flex flex-1 flex-col gap-4 text-brown-10">
          <h4 className="text-lg font-semibold leading-6">
            {t`Session with ${consultation.provider.fullName}`}
          </h4>

          <div className="flex flex-wrap gap-2">
            <Badge size="md" className="bg-salmon-07 text-salmon-01">
              {formatBackendDate(consultation.startTime)}
            </Badge>
            <Badge variant="outline" size="md" className="truncate">
              {`${formatConsultationTime(
                consultation.startTime,
              )} - ${formatConsultationTime(consultation.endTime)} ${
                consultation.timezone
              }`}
            </Badge>
          </div>
        </div>

        <div className="flex h-full w-full items-end justify-end gap-3 justify-self-end">
          <JoinNowButton canJoin={canJoin} videoUrl={consultation?.videoUrl} />

          {!hasOnlyHairGrowthSubs && (
            <Button
              variant="secondary"
              className="h-11 w-12"
              onClick={() => deleteAppointment(consultation.id)}
            >
              <TrashBin2Icon className="size-6 text-nature-08" />
            </Button>
          )}
        </div>

        {canJoin && (
          <div className="col-span-full row-start-2">
            <Alert className="p-3 sm:p-3">
              {t`When joining, please enter your name exactly as it appears in
              your Fridays profile.`}
            </Alert>
          </div>
        )}
      </div>

      <Modal
        show={show}
        onClose={onClose}
        icon={<icons.DeleteAppointment />}
        title={t`Are you sure you want to cancel your consultation?`}
        description={t`Cancelling your consultation with Dr. ${consultation.provider.fullName} means you'll lose your spot on the schedule. Are you sure you want to do that?`}
        className="w-96 rounded-2xl md:w-auto"
      >
        <div className="flex w-full flex-col-reverse items-stretch justify-center gap-3.5 md:flex-row md:items-center">
          <Button
            disabled={isPending}
            variant="secondary"
            onClick={onClose}
            size="lg"
          >
            {t`Keep Consultation`}
          </Button>
          <Button
            disabled={isPending}
            size="lg"
            onClick={() => cancelAppointmentMutation(appointmentIdToDelete!)}
          >
            {t`Cancel Consultation`}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
