import { Link } from "react-router-dom";
import { t } from "ttag";

import { EXTERNAL_LINK } from "@/router";
import {
  AltArrowLeftIcon,
  FullScreenAlert,
  HeartsCircleFixedIconMono,
  IconWrapper,
} from "@/ui";

interface DQByBMIProps {
  open: boolean;
  onGoBack: () => void;
}

export const DQByBMI = ({ open, onGoBack }: DQByBMIProps) => {
  return (
    <FullScreenAlert.Root open={open}>
      <FullScreenAlert.Content>
        <FullScreenAlert.Header>
          <IconWrapper
            onClick={onGoBack}
            as="button"
            className="absolute left-0 translate-y-5 md:static md:-translate-y-5"
          >
            <AltArrowLeftIcon />
          </IconWrapper>

          <HeartsCircleFixedIconMono className="size-16 md:size-20" />
          <FullScreenAlert.Title>
            {t`It appears that Fridays treatments may not be the best fit for you at the moment`}
          </FullScreenAlert.Title>
        </FullScreenAlert.Header>

        <FullScreenAlert.Description>
          {t`Your journey to wellness is personal and unique. While Fridays may not align with your current needs, it's just the beginning of discovering what works best for you. Keep exploring, and you'll find the perfect fit.`}
        </FullScreenAlert.Description>

        <FullScreenAlert.Footer>
          <FullScreenAlert.Action asChild>
            <Link to={EXTERNAL_LINK.HOME}>{t`Back to Home`}</Link>
          </FullScreenAlert.Action>
        </FullScreenAlert.Footer>
      </FullScreenAlert.Content>
    </FullScreenAlert.Root>
  );
};
