import { useFormContext } from "react-hook-form";
import { t } from "ttag";

import type { CheckoutFormValues } from "@/domains/onboarding/models";
import { Form, Home2Icon, Input } from "@/ui";

export const ShippingFieldset = () => {
  const form = useFormContext<CheckoutFormValues>();

  return (
    <fieldset className="flex flex-col gap-6">
      <h3 className="text-lg font-bold text-brown-09">{t`Shipping address`}</h3>

      <div className="grid gap-x-8 gap-y-1.5 md:grid-cols-2">
        <Form.Field
          control={form.control}
          name="address"
          render={() => (
            <Form.Item>
              <Form.Label aria-required>{t`Address`}</Form.Label>
              <Form.Control>
                <Input
                  {...form.register("address")}
                  placeholder={t`123, Main Street`}
                  left={<Home2Icon />}
                />
              </Form.Control>
              <Form.Message compact={false} />
            </Form.Item>
          )}
        />

        <Form.Field
          control={form.control}
          name="apartmentNumber"
          render={() => (
            <Form.Item>
              <Form.Label>{t`Apartment number`}</Form.Label>
              <Form.Control>
                <Input
                  {...form.register("apartmentNumber")}
                  placeholder={t`1A`}
                  left={<Home2Icon />}
                />
              </Form.Control>
              <Form.Message compact={false} />
            </Form.Item>
          )}
        />

        <Form.Field
          control={form.control}
          name="zipCode"
          render={() => (
            <Form.Item>
              <Form.Label aria-required>{t`Zip code`}</Form.Label>
              <Form.Control>
                <Input
                  {...form.register("zipCode")}
                  placeholder="12345"
                  left={<Home2Icon />}
                />
              </Form.Control>
              <Form.Message compact={false} />
            </Form.Item>
          )}
        />

        <Form.Field
          control={form.control}
          name="city"
          render={() => (
            <Form.Item>
              <Form.Label aria-required>{t`City`}</Form.Label>
              <Form.Control>
                <Input
                  {...form.register("city")}
                  placeholder={t`Phoenix`}
                  left={<Home2Icon />}
                />
              </Form.Control>
              <Form.Message compact={false} />
            </Form.Item>
          )}
        />
      </div>
    </fieldset>
  );
};
