import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation } from "@tanstack/react-query";
import { t } from "ttag";

import type { OnboardingUser } from "@/domains/onboarding/models";
import { useUpdatePaymentMethod } from "./useUpdatePaymentMethod";

interface StripeCreatePaymentMethodParams {
  userCode: OnboardingUser["code"];
}

export const useStripeCreatePaymentMethod = () => {
  const stripe = useStripe();
  const elements = useElements();
  const cardElement = elements?.getElement(CardElement);

  const updatePaymentMethodMutation = useUpdatePaymentMethod();

  return useMutation({
    mutationFn: async ({ userCode }: StripeCreatePaymentMethodParams) => {
      if (!stripe || !cardElement) {
        throw new Error("Stripe.js has not loaded yet");
      }

      const result = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }

      updatePaymentMethodMutation.mutate(
        {
          paymentMethodId: result.paymentMethod.id,
          userCode,
        },
        {
          onError: () => {
            throw new Error(
              t`Error updating payment method, try again later or use a different card.`,
            );
          },
        },
      );
    },
  });
};
