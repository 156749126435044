import { Navigate } from "react-router-dom";
import { t } from "ttag";

import { HealthieForm } from "@/domains/intake-form/components";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { usePatientQuery } from "@/hooks";
import { ROUTES } from "@/router";
import { usePortalUserStore } from "@/stores";
import { AltArrowLeftIcon, AltArrowRightIcon, Button } from "@/ui";

interface HealthieFormProps {
  onCancel: () => void;
  onSubmit: () => void;
}

export const VideoConsultationFormSection = ({
  onCancel,
  onSubmit,
}: HealthieFormProps) => {
  const weightLossSubscription = usePortalUserStore(({ portalUser }) =>
    portalUser?.subscriptions.find(
      (subscription) =>
        subscription.product.category.slug === CATEGORY_SLUG.WEIGHT_LOSS,
    ),
  );

  const { data: patient } = usePatientQuery();

  if (!patient?.id || !weightLossSubscription) {
    return <Navigate to={ROUTES.BASE} replace />;
  }

  return (
    <HealthieForm
      onSubmit={onSubmit}
      params={{
        isAsync: false,
        isRefill: true,
        withRefill: false,
        patientId: patient.id,
        gender: patient.gender,
        productId: weightLossSubscription.product.id,
      }}
    >
      <div className="flex flex-col-reverse items-stretch justify-between gap-4 md:flex-row md:items-center">
        <Button onClick={onCancel} variant="secondary" size="lg">
          <AltArrowLeftIcon className="hidden size-4 md:block" />

          {t`Back`}
        </Button>
        <Button type="submit" size="lg">
          {t`Next`}
          <AltArrowRightIcon className="hidden size-4 md:block" />
        </Button>
      </div>
    </HealthieForm>
  );
};
