import type { StateCreator } from "zustand";

export interface AnalyticsSlice {
  utmCampaign?: string;
  analyticsCustomerId?: string;

  setUtmCampaign: (utmCampaign?: string) => void;
  setAnalyticsCustomerId: (analyticsCustomerId?: string) => void;
}

export const createAnalyticsSlice: StateCreator<
  AnalyticsSlice,
  [],
  [],
  AnalyticsSlice
> = (set) => ({
  setUtmCampaign: (utmCampaign) => set({ utmCampaign }),
  setAnalyticsCustomerId: (analyticsCustomerId) => set({ analyticsCustomerId }),
});
