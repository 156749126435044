import { env } from "@/env";

export const QUORA_EVENTS = {
  PURCHASE: "Purchase",
  ADD_TO_CART: "AddToCart",
  ADD_PAYMENT_INFO: "AddPaymentInfo",
} as const;

export const initQuoraTracking = ({ email }: { email: string }) => {
  try {
    const quoraPixelId = env.VITE_QUORA_PIXEL_ID;

    if (!window.qp || !quoraPixelId) throw new Error("Quora not initialized");

    window.qp("init", env.VITE_QUORA_PIXEL_ID, { email });
  } catch (error) {
    console.error("Error initializing Quora", error);
  }
};

interface QuoraTrackingParams {
  event: (typeof QUORA_EVENTS)[keyof typeof QUORA_EVENTS];
  options?: Record<string, string | number>;
}

export const handleQuoraTracking = ({
  event,
  options,
}: QuoraTrackingParams) => {
  try {
    if (!window.qp) throw new Error("Quora not initialized");

    window.qp("track", event, options);
  } catch (error) {
    console.error(`Error tracking Quora ${event} event`, error);
  }
};
