import type { ForwardedRef } from "react";

import { ORIENTATION } from "@/shared.constants";
import { forwardRef, tw } from "@/utils";
import type { InputGroup } from "./form.types";
import { Label } from "./Label";
import { LegacyCheckbox } from "./LegacyCheckbox";
import { Message } from "./Message";

interface CheckboxGroup extends InputGroup {
  value: string[];
  onChange: (value: string[]) => void;
}

export const CheckboxGroup = forwardRef(
  (
    {
      className,
      compact = true,
      containerClassName,
      disabled,
      error,
      id,
      label,
      message,
      options,
      orientation = ORIENTATION.VERTICAL,
      value = [],
      onChange,
      ...rest
    }: CheckboxGroup,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div className={tw("flex flex-col gap-1.5", containerClassName)}>
        {!!label && (
          <Label htmlFor={id} className="pb-1">
            {label}
          </Label>
        )}
        <div
          ref={ref}
          className={tw(
            "flex",
            orientation === ORIENTATION.VERTICAL && "flex-col gap-2.5",
            orientation === ORIENTATION.HORIZONTAL && "items-center gap-6",
            className,
          )}
          {...rest}
        >
          {options.map((option) => (
            <LegacyCheckbox
              checked={value.includes(option.value)}
              onCheckedChange={(checked) => {
                return checked
                  ? onChange([...value, option.value])
                  : onChange(value.filter((value) => value !== option.value));
              }}
              label={option.label}
              disabled={disabled}
              key={`${id}-${option.value}-${option.label}`}
              value={option.value}
              id={`${id}-${option.value}`}
            />
          ))}
        </div>

        {(!compact || !!message || !!error) && (
          <Message message={message} error={error} />
        )}
      </div>
    );
  },
);
