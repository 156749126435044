import { t } from "ttag";

import {
  ActiveSubscriptionCard,
  SubscriptionsSkeleton,
} from "@/domains/billing/components";
import { useActiveSubscriptions } from "@/domains/billing/hooks";
import { useBoolean } from "@/hooks";
import { EditPaymentMethodModal } from "@/modals";
import { Button, CardPrimitive as Card, CheckCircleIcon, PenIcon } from "@/ui";

export const ActiveSubscriptions = () => {
  const {
    value: showEditPaymentModal,
    setTrue: openEditPaymentModal,
    setFalse: closeEditPaymentModal,
  } = useBoolean(false);

  const {
    data: activeSubscriptions,
    isLoading,
    isSuccess,
  } = useActiveSubscriptions();

  return (
    <Card.Root className="bg-brown-03">
      <Card.Header className="flex-col-reverse gap-5 sm:flex-row sm:justify-between">
        <Card.Title>
          <CheckCircleIcon className="size-4" />
          {t`Your subscriptions`}
        </Card.Title>

        <Button
          onClick={openEditPaymentModal}
          variant="tertiary"
          size="lg"
          className="hidden p-0 sm:flex"
        >
          <PenIcon className="size-4" />
          {t`Edit payment method`}
        </Button>
      </Card.Header>

      <Card.Content>
        {isLoading && <SubscriptionsSkeleton />}

        {isSuccess && (
          <div className="flex flex-col gap-2">
            {activeSubscriptions.map((subscription) => (
              <ActiveSubscriptionCard
                key={subscription.id}
                subscription={subscription}
              />
            ))}
          </div>
        )}
      </Card.Content>

      <Card.Footer className="mt-1 justify-center sm:hidden">
        <Button
          onClick={openEditPaymentModal}
          variant="tertiary"
          size="lg"
          className="p-0"
        >
          <PenIcon className="size-4" />
          {t`Edit payment method`}
        </Button>
      </Card.Footer>

      <EditPaymentMethodModal
        show={showEditPaymentModal}
        onClose={closeEditPaymentModal}
      />
    </Card.Root>
  );
};
