export const AVAILABLE_LANGUAGE = {
  EN: "en",
  ES: "es",
} as const;

export const PORTAL_USER_TYPE = {
  PATIENT: "healthie-patient-user",
  COACHING: "coaching-user",
  ADMIN: "admin-user",
} as const;

export const APPOINTMENT_REQUIRED_STATES = [
  "AR",
  "DC",
  "DE",
  "ID",
  "KS",
  "LA",
  "NM",
  "RI",
  "WV",
] as const;

export const GENDER = {
  MALE: "male",
  FEMALE: "female",
} as const;

export const AGE_GROUP = {
  YOUNGER_THAN_18: "under-18",
  BETWEEN_18_AND_49: "between-18-and-49",
  BETWEEN_50_AND_74: "between-50-and-74",
  BETWEEN_18_AND_74: "between-18-and-74",
  OLDER_THAN_74: "over-74",
} as const;

export const WEIGHT_LOSS_DQ_STATES = new Set(["LA"]);
