import { useFormContext } from "react-hook-form";

import { pricingCardVariants, SubscriptionRadioGroup } from "@/components";
import { useActiveSubscriptions } from "@/domains/billing/hooks";
import type { Plan } from "@/domains/billing/models";
import { Form } from "@/ui";
import type { SubscriptionFormValues } from "../../../shared";

interface PortalSubscriptionPlansProps {
  plans: Plan[];
  isLoading?: boolean;
}

export const PortalSubscriptionPlans = ({
  plans,
  isLoading,
}: PortalSubscriptionPlansProps) => {
  const { control } = useFormContext<SubscriptionFormValues>();

  const radioGroupItemClassName = pricingCardVariants().radioItem();

  const {
    data: activeSubscriptions,
    isLoading: isLoadingActiveSubscriptions,
    isSuccess,
  } = useActiveSubscriptions();

  const pendingSubscription = plans.find((plan) =>
    activeSubscriptions?.some(
      (subscription) =>
        subscription.nextPeriodProduct?.price.subscriptionPriceId ===
        plan.subscriptionPriceId,
    ),
  );

  return (
    <>
      {(isLoading ?? isLoadingActiveSubscriptions) && (
        <div className="flex w-full flex-wrap justify-center gap-4">
          {Array.from({ length: 2 }, (_, index) => `${index}`).map((i) => (
            <SubscriptionRadioGroup.ItemSkeleton
              key={`subscription-card-skeleton-${i}`}
              className="border-brown-04"
            />
          ))}
        </div>
      )}

      {isSuccess && (
        <Form.Field
          control={control}
          name="productPriceId"
          render={({ field: { value, onChange, ...rest } }) => (
            <Form.Item>
              <Form.Control>
                <SubscriptionRadioGroup.Root
                  value={String(value)}
                  onValueChange={(value) => onChange(Number(value))}
                  {...rest}
                >
                  {plans?.map((plan) => {
                    const isCurrentPlan = activeSubscriptions.some(
                      (subscription) =>
                        subscription.currentProduct.price
                          .subscriptionPriceId === plan.subscriptionPriceId,
                    );

                    const isPendingPlan = activeSubscriptions.some(
                      (subscription) =>
                        subscription.nextPeriodProduct?.price
                          .subscriptionPriceId === plan.subscriptionPriceId,
                    );

                    return (
                      <SubscriptionRadioGroup.Item
                        key={plan.subscriptionPriceId}
                        value={String(plan.priceId)}
                        plan={plan}
                        className={radioGroupItemClassName}
                        isCurrentPlan={isCurrentPlan}
                        isPendingPlan={isPendingPlan}
                        disabled={
                          pendingSubscription ? isPendingPlan : isCurrentPlan
                        }
                      />
                    );
                  })}
                </SubscriptionRadioGroup.Root>
              </Form.Control>
              <Form.Message className="text-center" />
            </Form.Item>
          )}
        />
      )}
    </>
  );
};
