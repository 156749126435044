import type { RouteObject } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { RequireLoggedOut } from "@/router";
import { OnboardingScreenLayout } from "../components";
import {
  BMICalculation,
  ChooseCategories,
  ChooseProducts,
  EligibilityQuestions,
  MainInfo,
  Payment,
  SetPassword,
  Summary,
  UserInfo,
} from "../screens";
import { ONBOARDING_ROUTE } from "./constants";

export const onboardingRoute = (): RouteObject => ({
  path: ONBOARDING_ROUTE.BASE,
  element: (
    <RequireLoggedOut>
      <OnboardingScreenLayout />
    </RequireLoggedOut>
  ),

  children: [
    { path: ONBOARDING_ROUTE.MAIN_INFO, element: <MainInfo /> },

    {
      path: ONBOARDING_ROUTE.TREATMENT,

      children: [
        {
          path: ONBOARDING_ROUTE.TREATMENT_CHOOSE_CATEGORIES,
          element: <ChooseCategories />,
        },
        { path: ONBOARDING_ROUTE.TREATMENT_BMI, element: <BMICalculation /> },
        {
          path: ONBOARDING_ROUTE.TREATMENT_HEALTH_ELIGIBILITY_QUESTIONS,
          element: <EligibilityQuestions />,
        },
        {
          path: ONBOARDING_ROUTE.TREATMENT_CHOOSE_PRODUCTS,
          element: <ChooseProducts />,
        },
        { path: ONBOARDING_ROUTE.TREATMENT_SUMMARY, element: <Summary /> },
        {
          index: true,
          element: <Navigate replace to={ONBOARDING_ROUTE.BASE} />,
        },
      ],
    },

    {
      path: ONBOARDING_ROUTE.CHECKOUT,
      children: [
        { index: true, element: <UserInfo /> },
        {
          path: ONBOARDING_ROUTE.CHECKOUT_PAYMENT,
          element: <Payment />,
        },
      ],
    },

    {
      path: ONBOARDING_ROUTE.ACCOUNT,
      children: [{ index: true, element: <SetPassword /> }],
    },

    {
      index: true,
      element: <Navigate replace to={ONBOARDING_ROUTE.MAIN_INFO} />,
    },
    { path: "*", element: <Navigate replace to={ONBOARDING_ROUTE.BASE} /> },
  ],
});
