import type { ServiceResponse } from "@/api";
import { privateAPI } from "@/api";
import type { PortalUser } from "../models";

interface SetUserGenderParams {
  gender: PortalUser["gender"];
}

export const setUserGender = async (params: SetUserGenderParams) => {
  const { data } = await privateAPI.put<ServiceResponse<null>>(
    "/set-gender",
    params,
  );

  return data.data;
};
