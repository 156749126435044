import type { PropsWithChildren } from "react";
import { createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { z } from "zod";

import { ErrorState } from "@/components";
import { categorySchema } from "@/domains/products/models";
import type {
  Category,
  CategoryWithSelectedProduct,
  Price,
  Product,
} from "@/domains/products/models";
import { Layout } from "@/layouts";

interface ExploreSubscriptionsContextProps {
  categories: CategoryWithSelectedProduct[];

  actions: {
    addProduct: (product: Product) => void;
    removeProduct: (product: Product) => void;
    setSelectedPrice: (
      categoryId: Category["id"],
      selectedPrice: Price,
    ) => void;
  };
}

const ExploreSubscriptionsContext =
  createContext<ExploreSubscriptionsContextProps | null>(null);

export const useExploreSubscriptionsContext = () => {
  const context = useContext(ExploreSubscriptionsContext);

  if (!context) {
    throw new Error(
      "useExploreSubscriptionsContext must be used within a <ExploreSubscriptionsContextProvider />",
    );
  }

  return context;
};

export const ExploreSubscriptionsContextProvider = ({
  children,
}: PropsWithChildren) => {
  const location = useLocation();
  const parseCategories = z.array(categorySchema).safeParse(location.state);

  const [categories, setCategories] = useState<CategoryWithSelectedProduct[]>(
    parseCategories.data ?? [],
  );

  if (parseCategories.error && !categories.length) {
    return (
      <Layout>
        <ErrorState className="-translate-y-10 border-none" />
      </Layout>
    );
  }

  const addProduct = (product: Product) => {
    setCategories((prev) =>
      prev?.map((category) =>
        category.id === product.category.id
          ? {
              ...category,
              selectedProduct: {
                ...product,
                selectedPrice: product.prices.at(-1),
              },
            }
          : category,
      ),
    );
  };

  const removeProduct = (product: Product) => {
    setCategories((prev) =>
      prev.map((category) =>
        category.id === product.category?.id
          ? {
              ...category,
              selectedProduct: undefined,
            }
          : category,
      ),
    );
  };

  const setSelectedPrice = (
    categoryId: Category["id"],
    selectedPrice: Price,
  ) => {
    setCategories((prev) =>
      prev.map((category) =>
        category.id === categoryId
          ? {
              ...category,
              selectedProduct: category.selectedProduct && {
                ...category.selectedProduct,
                selectedPrice,
              },
            }
          : category,
      ),
    );
  };

  return (
    <ExploreSubscriptionsContext.Provider
      value={{
        categories,
        actions: { addProduct, removeProduct, setSelectedPrice },
      }}
    >
      {children}
    </ExploreSubscriptionsContext.Provider>
  );
};
