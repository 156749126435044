import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import {
  RefillRequirementsAlert,
  SymptomsForm,
} from "@/domains/consultations/components";
import { medicationRequestSchema } from "@/domains/my-meds/models";
import { MY_MEDS_ROUTE } from "@/domains/my-meds/router";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { ScreenLayout } from "@/layouts";
import {
  FilterForm,
  RefillHealthieForm,
  RefillInjectionsForm,
  RefillRequestHeader,
} from "./sections";
import type { RefillFormStep } from "./utils";
import { getRefillFormHeaderDescription, REFILL_REQUEST_STEP } from "./utils";

export const RefillRequest = () => {
  const location = useLocation();

  const parsedMedicationRequestProduct =
    medicationRequestSchema.shape.product.safeParse(location.state);

  const [step, setStep] = useState<RefillFormStep>(
    parsedMedicationRequestProduct.data?.category.slug !==
      CATEGORY_SLUG.WEIGHT_LOSS
      ? REFILL_REQUEST_STEP.HEALTHIE_FORM
      : REFILL_REQUEST_STEP.SYMPTOMS_FORM,
  );

  if (parsedMedicationRequestProduct.error) {
    return <Navigate replace to={MY_MEDS_ROUTE.BASE} />;
  }

  return (
    <ScreenLayout variant="secondary" className="gap-0">
      <RefillRequestHeader description={getRefillFormHeaderDescription(step)} />

      {step === REFILL_REQUEST_STEP.SYMPTOMS_FORM && (
        <SymptomsForm
          onSubmit={() => setStep(REFILL_REQUEST_STEP.INJECTIONS_FORM)}
        />
      )}

      {step === REFILL_REQUEST_STEP.INJECTIONS_FORM && (
        <RefillInjectionsForm
          onCancel={() => setStep(REFILL_REQUEST_STEP.SYMPTOMS_FORM)}
          onSubmit={() => setStep(REFILL_REQUEST_STEP.FILTER_FORM)}
        />
      )}

      {step === REFILL_REQUEST_STEP.FILTER_FORM && (
        <FilterForm
          onContinue={() => setStep(REFILL_REQUEST_STEP.HEALTHIE_FORM)}
        />
      )}

      {step === REFILL_REQUEST_STEP.HEALTHIE_FORM && (
        <div className="flex max-w-2xl flex-col gap-6">
          <RefillRequirementsAlert />

          <RefillHealthieForm
            productId={parsedMedicationRequestProduct.data.id}
            onCancel={() => setStep(REFILL_REQUEST_STEP.FILTER_FORM)}
          />
        </div>
      )}
    </ScreenLayout>
  );
};
