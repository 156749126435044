import { useId } from "react";
import type { ComponentPropsWithoutRef } from "react";
import type { Control } from "react-hook-form";
import { jt, t } from "ttag";

import { Form } from "@/ui";
import { Checkbox } from "@/ui/form/Checkbox";

interface FieldValue {
  marketingSMSConsent?: boolean;
}

type MarketingSMSConsentControl<T> = T extends FieldValue
  ? Control<T, "marketingSMSConsent">
  : never;

type MarketingSMSConsentFieldProps<T extends FieldValue> =
  ComponentPropsWithoutRef<typeof Form.Item> & {
    control: MarketingSMSConsentControl<T>;
  };
export const MarketingSMSConsentField = <T extends FieldValue>({
  control,
  ...props
}: MarketingSMSConsentFieldProps<T>) => {
  const id = useId();

  const textMessagesBold = (
    <strong key={`${id}-bold-email`}>{t`text messages`}</strong>
  );

  const promotionalContentBold = (
    <strong key={`${id}-bold-promotional-content`}>
      {t`promotional content`}
    </strong>
  );

  const includedContentBold = (
    <strong
      key={`${id}-bold-included-content`}
    >{t`members-only exclusive weight loss updates, special offers, and health tips`}</strong>
  );

  return (
    <Form.Field
      control={control}
      name="marketingSMSConsent"
      render={({ field: { value, onChange, ...rest } }) => (
        <Form.Item {...props}>
          <div className="flex items-start gap-2.5">
            <Form.Control>
              <Checkbox {...rest} checked={value} onCheckedChange={onChange} />
            </Form.Control>

            <Form.Label className="text-brown-08">
              {jt`Send me Fridays ${textMessagesBold} with ${promotionalContentBold}, including ${includedContentBold}. These texts are optional and not a condition of any purchase. Texts may be sent to the number above by automated means. By agreeing to opt in to text messages, you're signing your opt-in (unsubscribe anytime).`}
            </Form.Label>
          </div>

          <Form.Message compact={false} />
        </Form.Item>
      )}
    />
  );
};
