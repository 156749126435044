import type { AnalyticsSlice } from "./analytics-slice";
import { createAnalyticsSlice } from "./analytics-slice";
import type { CheckoutSlice } from "./checkout-slice";
import { createCheckoutSlice } from "./checkout-slice";
import { create } from "./custom-create";
import type { TreatmentSlice } from "./treatment-slice";
import { createTreatmentSlice } from "./treatment-slice";
import type { UserAttributesSlice } from "./user-slice";
import { createUserAttributesSlice } from "./user-slice";

export const useOnboardingStore = create<
  UserAttributesSlice & TreatmentSlice & CheckoutSlice & AnalyticsSlice
>()((...args) => ({
  ...createUserAttributesSlice(...args),
  ...createTreatmentSlice(...args),
  ...createCheckoutSlice(...args),
  ...createAnalyticsSlice(...args),
}));
