import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";
import { t } from "ttag";

import { Button } from "@/ui";
import type { ButtonProps } from "@/ui";

const navigationButtonsVariants = tv({
  slots: {
    root: "flex w-full flex-col-reverse items-center gap-4 pb-8 sm:flex-row sm:justify-center sm:gap-4 md:pb-20",
    cancelButton: "w-full sm:w-fit sm:min-w-32",
    nextButton: "w-full sm:ml-auto sm:w-fit sm:min-w-32",
  },
  variants: {
    variant: {
      primary: {
        root: "sm:justify-between",
      },
      secondary: {
        cancelButton:
          "border-salmon-01 text-salmon-01 hover:border-salmon-03 hover:text-salmon-03",
        nextButton: "bg-salmon-02 text-salmon-10 hover:bg-salmon-03",
      },
    },
  },
});

type NavigationButtonsProps = VariantProps<typeof navigationButtonsVariants> & {
  onCancel?: () => void;
  onContinue: () => void;

  continueLabel?: string;
  continueDisabled?: boolean;
  continueForm?: ButtonProps["form"];
  continueType?: ButtonProps["type"];
  cancelLabel?: string;
};

export const NavigationButtons = ({
  onCancel,
  onContinue,
  variant = "primary",
  continueLabel = t`Next`,
  continueDisabled,
  continueForm,
  continueType = "button",
  cancelLabel = t`Back`,
}: NavigationButtonsProps) => {
  const { root, cancelButton, nextButton } = navigationButtonsVariants({
    variant,
  });

  return (
    <div className={root()}>
      {onCancel && (
        <Button
          onClick={onCancel}
          variant="secondary"
          size="lg"
          className={cancelButton()}
        >
          {cancelLabel}
        </Button>
      )}

      <Button
        disabled={continueDisabled}
        onClick={onContinue}
        size="lg"
        className={nextButton()}
        type={continueType}
        form={continueForm}
      >
        {continueLabel}
      </Button>
    </div>
  );
};
