import type { Control } from "react-hook-form";
import { useWatch } from "react-hook-form";
import { t } from "ttag";

import { Alert } from "@/ui";
import { calculateBMI } from "@/utils";

interface BMIValues {
  heightFeet: number;
  heightInches: number;
  weight: number;
}

interface BMIScoreAlertProps {
  control: Control<BMIValues>;
}

export const BMIScoreAlert = ({ control }: BMIScoreAlertProps) => {
  const measurements = useWatch({ control });

  const bmiValue = calculateBMI(measurements);

  return <Alert>{t`Your BMI Score is ${bmiValue}`}</Alert>;
};
