import { useEffect } from "react";

import { useAnalyticsContext } from "@/contexts";
import { analyticsCheckoutPageViewed } from "@/domains/analytics/utils";
import { useOnboardingStore } from "../stores";

export const useMarkCheckoutVisited = () => {
  const { analytics } = useAnalyticsContext();

  const onboardingUser = useOnboardingStore((state) => state.user);
  const selectedCategories = useOnboardingStore(
    (state) => state.selectedCategories,
  );

  useEffect(() => {
    if (onboardingUser && selectedCategories.length) {
      analyticsCheckoutPageViewed(
        analytics,
        onboardingUser,
        selectedCategories,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
