import type { StateCreator } from "zustand";

import type { BmiValues, UserAttributes } from "@/shared/models";
import type { OnboardingUser } from "../models";

export type OnboardingUserWithPhoneNumber = OnboardingUser & {
  phoneNumber: string;
};

export interface UserAttributesSlice {
  user?: OnboardingUserWithPhoneNumber;
  userAttributes?: UserAttributes;
  bmiValues?: BmiValues;
  healthieUserToken?: string;

  setOnboardingUser: (user: OnboardingUserWithPhoneNumber) => void;
  setUserAttributes: (userAttributes: UserAttributes) => void;
  setBmiValues: (bmiValues: BmiValues) => void;
  setHealthieUserToken: (healthieUserToken: string) => void;
}

export const createUserAttributesSlice: StateCreator<
  UserAttributesSlice,
  [],
  [],
  UserAttributesSlice
> = (set) => ({
  setOnboardingUser: (user) => set({ user }),
  setUserAttributes: (userAttributes) => set({ userAttributes }),
  setBmiValues: (bmiValues) => set({ bmiValues }),
  setHealthieUserToken: (healthieUserToken) => set({ healthieUserToken }),
});
