import { useNavigate } from "react-router";
import { t } from "ttag";

import { CallToAction } from "@/components";
import { NewMedicationRequestButton } from "@/domains/my-meds/components";
import { useCurrentMedicationRequests } from "@/domains/my-meds/hooks";
import { ScreenLayout } from "@/layouts";
import { ROUTES } from "@/router";
import {
  CurrentMedicationRequests,
  MyMedsHeader,
  PastMedicationRequests,
} from "./sections";

export const MyMedsOverview = () => {
  const { data: currentMedicationRequests } = useCurrentMedicationRequests();

  const hasMedicationRequests = Boolean(currentMedicationRequests?.length);

  const navigate = useNavigate();

  return (
    <ScreenLayout>
      <MyMedsHeader />

      <CurrentMedicationRequests />

      <PastMedicationRequests />

      <CallToAction
        title={t`Having any trouble with your medication delivery?`}
        description={t`Get in touch with our support team!`}
        buttonText={t`Get in touch`}
        onClick={() => navigate(ROUTES.MESSAGES)}
      />

      {hasMedicationRequests && (
        <div className="sticky bottom-0 -m-6 mt-1 bg-salmon-01 p-6 py-5 shadow-modal-footer md:hidden">
          <NewMedicationRequestButton />
        </div>
      )}
    </ScreenLayout>
  );
};
