import type { PortalUser } from "@/shared/models";
import { PORTAL_USER_TYPE } from "@/shared/models";
import {
  getAdminNavigation,
  getCoachingNavigation,
  getPatientNavigation,
} from "../navigation";

export const getNavigation = (user: PortalUser | null) => {
  const activeCategories =
    user?.subscriptions.map(
      (subscription) => subscription.product.category.slug,
    ) ?? [];

  switch (user?.type) {
    case PORTAL_USER_TYPE.ADMIN:
      return getAdminNavigation();

    case PORTAL_USER_TYPE.PATIENT:
      return getPatientNavigation().filter((navItem) =>
        navItem.activeCategories.some((category) =>
          activeCategories?.includes(category),
        ),
      );

    case PORTAL_USER_TYPE.COACHING:
      return getCoachingNavigation();
    default:
      return [];
  }
};
