import { Navigate } from "react-router-dom";

import { PORTAL_USER_TYPE } from "@/shared/models";
import { usePortalUserStore } from "@/stores";
import { ROUTES } from "../constants";

export const BaseRouter = () => {
  const portalUserType = usePortalUserStore(
    ({ portalUser }) => portalUser?.type,
  );
  const hasActiveWLSubscription = usePortalUserStore(
    (state) => state.hasActiveWLSubscription,
  );

  if (!portalUserType) {
    return <Navigate replace to={ROUTES.LOGIN} />;
  }
  if (portalUserType === PORTAL_USER_TYPE.PATIENT) {
    return <Navigate replace to={ROUTES.HOME} />;
  }
  if (portalUserType === PORTAL_USER_TYPE.COACHING) {
    if (hasActiveWLSubscription) {
      return <Navigate replace to={ROUTES.COACHING.OVERVIEW} />;
    }
    return <Navigate replace to={ROUTES.BILLING.OVERVIEW} />;
  }
  if (portalUserType === PORTAL_USER_TYPE.ADMIN) {
    return <Navigate replace to={ROUTES.COACHING.OVERVIEW} />;
  }

  return <Navigate replace to={ROUTES.HOME} />;
};
