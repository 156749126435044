import { t } from "ttag";

import type { MedicationRequest } from "@/domains/my-meds/models";
import { CategoryBadge } from "@/domains/products/components";
import { EXTERNAL_LINK } from "@/router";
import { DeliveryIcon, IconWrapper } from "@/ui";
import { formatBackendDate, tw } from "@/utils";
import { RequestCard } from ".";
import { MedicationStatusChip } from "../MedicationStatusChip";
import { getMedRequestDescription } from "./constants";

interface MedicationRequestCardProps {
  className?: string;
  medicationRequest: MedicationRequest;
}

export const MedicationRequestCard = ({
  className,
  medicationRequest,
}: MedicationRequestCardProps) => {
  const hasDrugInfo = Boolean(
    medicationRequest?.drugName ?? medicationRequest?.dose,
  );

  const dateOfRequest = formatBackendDate(medicationRequest?.dateOfRequest);

  return (
    <RequestCard.Root className={tw("gap-4 md:pt-5", className)}>
      <div className="flex items-center gap-2">
        <RequestCard.Title>{medicationRequest.product.name}</RequestCard.Title>
        <CategoryBadge categorySlug={medicationRequest.product.category.slug}>
          {medicationRequest.product.category.name}
        </CategoryBadge>
      </div>

      <div className="flex flex-col gap-1">
        <RequestCard.Title className="font-normal text-salmon-09">
          {t`Request made on ${dateOfRequest}`}
        </RequestCard.Title>

        {hasDrugInfo && (
          <RequestCard.Details className="my-1">
            <div className="flex items-center gap-2">
              <RequestCard.Label>{t`Drug name:`}</RequestCard.Label>
              <RequestCard.Description className="text-sm">
                {medicationRequest?.drugName ?? "-"}
              </RequestCard.Description>
            </div>

            <div className="mx-1 my-1 hidden border-x border-salmon-07 md:block" />

            <div className="flex items-center gap-2">
              <RequestCard.Label>{t`Dose:`}</RequestCard.Label>
              <RequestCard.Description className="text-sm">
                {medicationRequest?.dose ?? "-"}
              </RequestCard.Description>
            </div>
          </RequestCard.Details>
        )}

        <RequestCard.Description>
          {getMedRequestDescription(medicationRequest.status)}
        </RequestCard.Description>
      </div>

      <RequestCard.Footer>
        {medicationRequest?.trackingNumber && (
          <RequestCard.Link
            href={`${EXTERNAL_LINK.TRACKING}${medicationRequest.trackingNumber}`}
          >
            <IconWrapper size="sm">
              <DeliveryIcon />
            </IconWrapper>
            {t`Tracking #${medicationRequest.trackingNumber}`}
          </RequestCard.Link>
        )}
      </RequestCard.Footer>

      {medicationRequest && (
        <MedicationStatusChip
          status={medicationRequest.status}
          className="absolute -right-1 -top-1.5"
        />
      )}
    </RequestCard.Root>
  );
};
