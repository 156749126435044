import { useOnboardingStore } from "@/domains/onboarding/stores";
import { calculateTotal } from "@/domains/products/utils";
import { formatPrice } from "@/shared/utils";
import { Accordion, AltArrowDownIcon, CardPrimitive } from "@/ui";
import { SelectedProducts } from "../treatment";

export const PaymentTotal = () => {
  const selectedCategories = useOnboardingStore(
    (state) => state.selectedCategories,
  );
  const coupon = useOnboardingStore((state) => state.coupon);

  const isCouponValid = coupon?.isValid;

  const total = calculateTotal(selectedCategories);
  const totalWithDiscount = isCouponValid
    ? calculateTotal(selectedCategories, { coupon })
    : total;

  return (
    <CardPrimitive.Root className="rounded bg-salmon-01 p-4">
      <Accordion.Root type="single" collapsible>
        <Accordion.Item value="total" className="flex flex-col gap-1">
          <Accordion.Trigger className="group w-full justify-between [&[data-state=open]>svg]:rotate-180">
            <CardPrimitive.Description className="text-base font-medium text-salmon-07 transition-opacity duration-200 group-data-[state=open]:opacity-0">
              Total
            </CardPrimitive.Description>

            <div className="flex items-center gap-2">
              {isCouponValid ? (
                <div className="flex items-center gap-1.5 transition-opacity duration-200 group-data-[state=open]:opacity-0">
                  <CardPrimitive.Title className="text-salmon-07 line-through">
                    {formatPrice(total)}
                  </CardPrimitive.Title>
                  <CardPrimitive.Title>
                    {formatPrice(totalWithDiscount)}
                  </CardPrimitive.Title>
                </div>
              ) : (
                <CardPrimitive.Title className="transition-opacity duration-200 group-data-[state=open]:opacity-0">
                  {formatPrice(total)}
                </CardPrimitive.Title>
              )}

              <AltArrowDownIcon className="size-4 transition-transform duration-200 group-data-[state=open]:rotate-180" />
            </div>
          </Accordion.Trigger>

          <Accordion.Content>
            <SelectedProducts readOnly className="max-w-full p-1" />
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </CardPrimitive.Root>
  );
};
