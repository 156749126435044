import { Route, Routes } from "react-router-dom";

import { ConsultationsRouter } from "@/domains";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { usePortalUserStore } from "@/stores";
import { BASE_ROUTE } from "../constants";
import { LegacyRouter } from "./LegacyRouter";

export const PatientUserRouter = () => {
  const hasActiveWLSubscriptionAndIntakeFormCompleted = usePortalUserStore(
    ({ portalUser, hasActiveWLSubscription }) =>
      portalUser &&
      hasActiveWLSubscription &&
      portalUser.subscriptions.some(
        (subscription) =>
          subscription.product.category.slug === CATEGORY_SLUG.WEIGHT_LOSS &&
          subscription.intakeFormCompleted,
      ),
  );

  return (
    <Routes>
      <Route path="*" element={<LegacyRouter />} />

      {hasActiveWLSubscriptionAndIntakeFormCompleted && (
        <Route
          path={`${BASE_ROUTE.CONSULTATIONS}/*`}
          element={<ConsultationsRouter />}
        />
      )}
    </Routes>
  );
};
