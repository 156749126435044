import { t } from "ttag";

import type { SubscriptionStatus } from "@/domains/billing/models";
import { SUBSCRIPTION_STATUS } from "@/domains/billing/models";
import type { Category } from "@/domains/products/models";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { ROUTES } from "@/router";
import type { DomainRoute } from "@/shared.types";

interface NavigationItem {
  path: DomainRoute;
  label: string;
  subscriptionStatus: SubscriptionStatus[];
  needsIntakeForm?: boolean;
  disabled?: boolean;
  comingSoon?: boolean;
  activeCategories: Category["slug"][];
}

const allSubscriptions = Object.values(SUBSCRIPTION_STATUS);
const allProductCategories = Object.values(CATEGORY_SLUG);

export const getAdminNavigation = () =>
  [
    {
      path: ROUTES.COACHING.OVERVIEW,
      label: t`Coaching lab`,
      subscriptionStatus: allSubscriptions,
      activeCategories: allProductCategories,
    },
  ] as NavigationItem[];

export const getCoachingNavigation = () =>
  [
    {
      path: ROUTES.COACHING.OVERVIEW,
      label: t`Coaching hub`,
      subscriptionStatus: [SUBSCRIPTION_STATUS.ACTIVE],
      activeCategories: allProductCategories,
    },
    {
      path: ROUTES.MESSAGES,
      label: t`Messages`,
      subscriptionStatus: allSubscriptions,
      activeCategories: allProductCategories,
    },
    {
      path: ROUTES.BILLING.OVERVIEW,
      label: t`Billing`,
      subscriptionStatus: allSubscriptions,
      activeCategories: allProductCategories,
    },
  ] as NavigationItem[];

export const getPatientNavigation = () =>
  [
    {
      path: ROUTES.HOME,
      label: t`Home`,
      subscriptionStatus: allSubscriptions,
      activeCategories: allProductCategories,
    },
    {
      path: ROUTES.MY_MEDS,
      label: t`My meds`,
      subscriptionStatus: [
        SUBSCRIPTION_STATUS.ACTIVE,
        SUBSCRIPTION_STATUS.PAID_IN_HEALTHIE,
        SUBSCRIPTION_STATUS.EMBEDDABLES,
      ],
      activeCategories: allProductCategories,
      needsIntakeForm: true,
    },
    {
      path: ROUTES.CONSULTATIONS.OVERVIEW,
      label: t`Consultations`,
      subscriptionStatus: [
        SUBSCRIPTION_STATUS.ACTIVE,
        SUBSCRIPTION_STATUS.PAID_IN_HEALTHIE,
        SUBSCRIPTION_STATUS.EMBEDDABLES,
      ],
      activeCategories: [CATEGORY_SLUG.WEIGHT_LOSS],
      needsIntakeForm: true,
    },
    {
      path: ROUTES.COACHING.OVERVIEW,
      label: t`Coaching hub`,
      subscriptionStatus: [
        SUBSCRIPTION_STATUS.ACTIVE,
        SUBSCRIPTION_STATUS.PAID_IN_HEALTHIE,
        SUBSCRIPTION_STATUS.EMBEDDABLES,
      ],
      activeCategories: [CATEGORY_SLUG.WEIGHT_LOSS],
    },
    {
      path: ROUTES.DOCUMENT_CENTER.OVERVIEW,
      label: t`Document center`,
      subscriptionStatus: allSubscriptions,
      activeCategories: allProductCategories,
      needsIntakeForm: false,
    },
    {
      path: ROUTES.MESSAGES,
      label: t`Messages`,
      subscriptionStatus: allSubscriptions,
      activeCategories: allProductCategories,
    },
    {
      path: ROUTES.BILLING.OVERVIEW,
      label: t`Billing`,
      subscriptionStatus: allSubscriptions,
      activeCategories: allProductCategories,
    },
  ] as NavigationItem[];
