import { z } from "zod";

import type { ServiceResponse } from "@/api";
import { privateAPI, publicAPI } from "@/api";
import type { UserAttributes } from "@/shared/models";
import { categorySchema, productSchema } from "../models";
import type { Category, Product } from "../models";

export const getProductCategories = async (params: UserAttributes) => {
  const { data } = await publicAPI.get<ServiceResponse<Category[]>>(
    "/product-categories",
    { params },
  );

  return z.array(categorySchema).parse(data.data);
};

export const getPatientCategories = async () => {
  const { data } = await privateAPI.get<ServiceResponse<Category[]>>(
    "patients/product-categories",
  );

  return z.array(categorySchema).parse(data.data);
};

export const getProductsByCategory = async (
  categoryId: Category["id"],
  params: UserAttributes,
) => {
  const { data } = await publicAPI.get<ServiceResponse<Product[]>>(
    `/product-categories/${categoryId}/products`,
    { params },
  );

  return z.array(productSchema).parse(data.data);
};

export const getPatientProductsByCategory = async (id: Category["id"]) => {
  const { data } = await privateAPI.get<ServiceResponse<Product[]>>(
    `patients/product-categories/${id}/products`,
  );

  return z.array(productSchema).parse(data.data);
};
