import type { PropsWithChildren } from "react";
import { Navigate, Outlet, Routes } from "react-router-dom";

import { LoadingScreen } from "@/components";
import { Layout } from "@/layouts";
import { ROUTES, useSetPortalUser } from "@/router";
import { useAuthStore, usePortalUserStore } from "@/stores";
import type { PortalUser } from "../models";

const RequireLoggedIn = ({ children }: PropsWithChildren) => {
  const isLoggedIn = useAuthStore((state) => Boolean(state.token));

  if (!isLoggedIn) {
    return <Navigate replace to={ROUTES.LOGIN} />;
  }

  return <>{children}</>;
};

type RequireUserTypesProps = PropsWithChildren & {
  allowedUserTypes: PortalUser["type"][];
};

export const RequireUserType = ({
  children,
  allowedUserTypes,
}: RequireUserTypesProps) => {
  const userType = usePortalUserStore((state) => state.portalUser?.type);

  if (allowedUserTypes && userType && !allowedUserTypes.includes(userType)) {
    return <Navigate replace to={ROUTES.BASE} />;
  }

  return (
    <>
      {children}
      <Outlet />
    </>
  );
};

export const PortalRoutesWrapper = ({ children }: PropsWithChildren) => {
  const { isLoading } = useSetPortalUser();
  const hasPortalUser = usePortalUserStore((state) =>
    Boolean(state.portalUser),
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <RequireLoggedIn>
      {hasPortalUser && (
        <Layout>
          {children && <Routes>{children}</Routes>}
          <Outlet />
        </Layout>
      )}
    </RequireLoggedIn>
  );
};
