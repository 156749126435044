import { useId } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import { useAnalyticsContext } from "@/contexts/AnalyticsContext";
import { analyticsIdentifyOnboardingUser } from "@/domains/analytics/utils";
import { NavigationButtons } from "@/domains/onboarding/components";
import { useCreateUser, useCreateUserForm } from "@/domains/onboarding/hooks";
import { ONBOARDING_ROUTE } from "@/domains/onboarding/router";
import { useOnboardingStore } from "@/domains/onboarding/stores";
import {
  DateOfBirthField,
  MarketingEmailConsentField,
  MarketingSMSConsentField,
  TermsAndConditionsField,
} from "@/shared/components";
import { Form, Input, LetterIcon, SmartphoneIcon, UserIcon } from "@/ui";
import { formatDOB, handleAxiosFieldErrors } from "@/utils";

export const UserForm = () => {
  const formId = useId();
  const { analytics } = useAnalyticsContext();
  const userAttributes = useOnboardingStore((state) => state.userAttributes);
  const bmiValues = useOnboardingStore((state) => state.bmiValues);
  const utmCampaign = useOnboardingStore((state) => state.utmCampaign);
  const setOnboardingUser = useOnboardingStore(
    (state) => state.setOnboardingUser,
  );

  const form = useCreateUserForm();

  const createUserMutation = useCreateUser();

  const navigate = useNavigate();

  if (!userAttributes) {
    return <Navigate to={ONBOARDING_ROUTE.MAIN_INFO} />;
  }

  const handleBack = () => {
    navigate(ONBOARDING_ROUTE.TREATMENT_SUMMARY);
  };

  const handleNext = form.handleSubmit((values) => {
    const dateOfBirth = new Date(formatDOB(values.dateOfBirth));

    const userData = {
      ...values,
      ...bmiValues,
      dateOfBirth,
      gender: userAttributes.gender,
      state: userAttributes.state,
    };

    createUserMutation.mutate(userData, {
      onSuccess: (user) => {
        setOnboardingUser({
          ...user,
          phoneNumber: values.phoneNumber,
          marketingSMSConsent: values.marketingSMSConsent,
        });
        analyticsIdentifyOnboardingUser(
          analytics,
          userData,
          userAttributes.state,
          utmCampaign,
        );
        navigate(ONBOARDING_ROUTE.CHECKOUT_PAYMENT);
      },
      onError: (error) => handleAxiosFieldErrors(error, form.setError),
    });
  });

  return (
    <>
      <Form.Provider {...form}>
        <form
          id={formId}
          className="grid grow content-start gap-x-8 gap-y-1.5 md:grid-cols-2"
        >
          <Form.Field
            control={form.control}
            name="firstName"
            render={() => (
              <Form.Item>
                <Form.Label aria-required>{t`First name`}</Form.Label>
                <Form.Control>
                  <Input
                    {...form.register("firstName")}
                    placeholder={t`E.g. John`}
                    left={<UserIcon />}
                  />
                </Form.Control>
                <Form.Message compact={false} />
              </Form.Item>
            )}
          />

          <Form.Field
            control={form.control}
            name="lastName"
            render={() => (
              <Form.Item>
                <Form.Label aria-required>{t`Last name`}</Form.Label>
                <Form.Control>
                  <Input
                    {...form.register("lastName")}
                    placeholder={t`E.g. Doe`}
                    left={<UserIcon />}
                  />
                </Form.Control>
                <Form.Message compact={false} />
              </Form.Item>
            )}
          />

          <Form.Field
            control={form.control}
            name="email"
            render={() => (
              <Form.Item>
                <Form.Label aria-required>{t`Email`}</Form.Label>
                <Form.Control>
                  <Input
                    {...form.register("email")}
                    type="email"
                    placeholder={t`E.g. example@example.com`}
                    left={<LetterIcon />}
                  />
                </Form.Control>
                <Form.Message compact={false} />
              </Form.Item>
            )}
          />

          <Form.Field
            control={form.control}
            name="emailConfirmation"
            render={() => (
              <Form.Item>
                <Form.Label aria-required>{t`Confirm email`}</Form.Label>
                <Form.Control>
                  <Input
                    {...form.register("emailConfirmation")}
                    type="email"
                    placeholder={t`E.g. example@example.com`}
                    left={<LetterIcon />}
                  />
                </Form.Control>
                <Form.Message compact={false} />
              </Form.Item>
            )}
          />

          <Form.Field
            control={form.control}
            name="phoneNumber"
            render={() => (
              <Form.Item>
                <Form.Label aria-required>{t`Phone number`}</Form.Label>
                <Form.Control>
                  <Input
                    {...form.register("phoneNumber")}
                    type="tel"
                    placeholder={t`E.g. (123) 456-7890`}
                    left={<SmartphoneIcon />}
                  />
                </Form.Control>
                <Form.Message compact={false} />
              </Form.Item>
            )}
          />

          <DateOfBirthField control={form.control} register={form.register} />

          <MarketingEmailConsentField
            control={form.control}
            className="col-span-full"
          />

          <MarketingSMSConsentField
            control={form.control}
            className="col-span-full"
          />

          <TermsAndConditionsField
            control={form.control}
            className="col-span-full"
          />
        </form>

        <NavigationButtons
          onCancel={handleBack}
          onContinue={handleNext}
          continueDisabled={createUserMutation.isPending}
          continueType="submit"
          continueForm={formId}
        />
      </Form.Provider>

      {createUserMutation.isPending && <FullScreenLoader />}
    </>
  );
};
