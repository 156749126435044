import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";

import { AVAILABLE_LANGUAGE } from "@/shared/models";
import { useAppSettingsStore } from "@/stores";
import { tw } from "@/utils";

const LanguageSwitch = forwardRef<
  ElementRef<typeof SwitchPrimitives.Root>,
  ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, onCheckedChange, ...props }, ref) => {
  const localeCode = useAppSettingsStore((state) => state.language);
  const setLanguage = useAppSettingsStore((state) => state.setLanguage);

  const handleOnCheckedChange = (checked: boolean) => {
    setLanguage(checked ? AVAILABLE_LANGUAGE.ES : AVAILABLE_LANGUAGE.EN);
    onCheckedChange?.(checked);
  };

  return (
    <SwitchPrimitives.Root
      {...props}
      ref={ref}
      checked={localeCode === AVAILABLE_LANGUAGE.ES}
      onCheckedChange={handleOnCheckedChange}
      className={tw(
        "relative inline-flex shrink-0 cursor-pointer items-center rounded-lg border border-transparent bg-brown-04 shadow-sm transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-nature-03 focus-visible:ring-offset-2 focus-visible:ring-offset-nature-06 disabled:cursor-not-allowed [&>*]:disabled:opacity-50 [&>div>p:first-child]:data-[state=checked]:text-brown-05 [&>div>p:last-child]:data-[state=unchecked]:text-brown-05",
        className,
      )}
    >
      <div className="z-10 flex gap-6 px-3 py-2.5 font-medium text-brown-08">
        <p className="transition-colors duration-500">EN</p>
        <p className="transition-colors duration-500">ES</p>
      </div>

      <SwitchPrimitives.Thumb className="pointer-events-none absolute block size-11 translate-x-full rounded-lg bg-salmon-01 mix-blend-plus-lighter shadow-lg ring-0 transition-all duration-500 data-[state=checked]:right-11 data-[state=unchecked]:right-full" />
    </SwitchPrimitives.Root>
  );
});
LanguageSwitch.displayName = "LanguageSwitch";

export { LanguageSwitch };
