import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { MY_MEDS_ROUTE } from "@/domains/my-meds/router";
import { DocumentAddIcon, Header, UserIcon } from "@/ui";

interface RefillRequestHeaderProps {
  description: string;
}

export const RefillRequestHeader = ({
  description,
}: RefillRequestHeaderProps) => {
  const navigate = useNavigate();

  return (
    <Header.Root variant="secondary" className="max-w-2xl">
      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: MY_MEDS_ROUTE.BASE,
              icon: UserIcon,
              label: t`My meds`,
            },
            {
              href: MY_MEDS_ROUTE.REFILL_REQUEST,
              icon: DocumentAddIcon,
              label: t`New medication request`,
            },
          ]}
        />

        <div className="flex items-center gap-2">
          <Header.GoBackButton
            onClick={() => navigate(MY_MEDS_ROUTE.BASE)}
            className="md:hidden"
          />
          <Header.Title>{t`Medication refill request`}</Header.Title>
        </div>

        <Header.Description>{description}</Header.Description>
      </Header.Content>
    </Header.Root>
  );
};
