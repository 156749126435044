import type { Answer } from "@/shared/models";

export const extractAnswerDetails = (answer: Answer) => {
  const clarificationRegex = /\(([^)]+)\)/;
  const match = clarificationRegex.exec(answer.answer);
  const clarification = match?.[0].trim() ?? "";
  const label = answer.answer
    .replace(clarification, "")
    .trim()
    .replace(" .", ".");

  return { clarification, label };
};
