import { northbeamInformNewSubscription } from "@/domains/analytics/api";
import type { Coupon } from "@/domains/billing/models";
import type { OnboardingUserWithPhoneNumber } from "@/domains/onboarding/stores";
import { CATEGORY_SLUG } from "@/domains/products/models";
import type {
  CategoryWithSelectedProduct,
  CategoryWithSelectedProductRequired,
} from "@/domains/products/models";
import { calculateTotal } from "@/domains/products/utils";
import { handleEverflowConvertEvent } from "./everflow";
import {
  GOOGLE_TAG_MANAGER_EVENTS,
  handleGoogleTagManagerTracking,
} from "./googleTagManager";
import { handleKatalysConvertEvent } from "./katalys";
import { handleNorthbeamConvertEvent } from "./northbeam";
import { handlePixelConvertEvent } from "./pixel";
import { handleQuoraTracking, QUORA_EVENTS } from "./quora";
import { handleTatariConvertEvent, TATARI_EVENT } from "./tatari";
import { handleTheOfferTracking, THE_OFFER_EVENTS } from "./theOffer";

interface TrackConversionParams {
  categories: CategoryWithSelectedProduct[];
  coupon?: Coupon;
  succeededPaymentIds: string[];
  everflowOfferId?: number;
  user: OnboardingUserWithPhoneNumber;
}

export const trackConversion = ({
  categories,
  coupon,
  succeededPaymentIds,
  everflowOfferId,
  user,
}: TrackConversionParams) => {
  const orderId = succeededPaymentIds.join("-");
  const totalPrice = coupon?.isValid
    ? calculateTotal(categories, { coupon })
    : calculateTotal(categories);

  handleEverflowConvertEvent({
    amount: totalPrice,
    offerId: everflowOfferId,
  });

  handlePixelConvertEvent({
    value: totalPrice,
  });

  handleQuoraTracking({
    event: QUORA_EVENTS.PURCHASE,
    options: { value: totalPrice },
  });

  handleNorthbeamConvertEvent(user.code);

  handleTatariConvertEvent(TATARI_EVENT.PURCHASE, { value: totalPrice });

  const categoriesWithSelectedPrice = categories.filter(
    (category) => category.selectedProduct?.selectedPrice,
  ) as CategoryWithSelectedProductRequired[];

  void northbeamInformNewSubscription({
    orderId,
    customerId: user.code,
    purchaseTotal: totalPrice,
    products: categoriesWithSelectedPrice.map((category) => ({
      id: category.selectedProduct.selectedPrice.subscriptionPriceId,
      name: category.selectedProduct.name,
      price: category.selectedProduct.selectedPrice.price,
      quantity: 1,
    })),
  });

  handleTheOfferTracking({
    event: THE_OFFER_EVENTS.PURCHASE,
    payload: {
      order_id: orderId,
      amount: totalPrice,
    },
  });

  handleGoogleTagManagerTracking({
    event: GOOGLE_TAG_MANAGER_EVENTS.PURCHASE,
    options: {
      value: totalPrice,
      currency: "USD",
      transaction_id: orderId,
      coupon: coupon?.code,
      items: categoriesWithSelectedPrice.map((category) => ({
        item_id: category.selectedProduct.selectedPrice.subscriptionPriceId,
        item_name: category.selectedProduct.name,
        price: category.selectedProduct.selectedPrice.price,
        quantity: 1,
        discount:
          category.slug === CATEGORY_SLUG.WEIGHT_LOSS
            ? coupon?.amountOff
            : undefined,
        coupon: coupon?.code,
      })),
    },
  });

  handleKatalysConvertEvent({
    orderId,
    items: categoriesWithSelectedPrice.map((category) => ({
      name: category.selectedProduct.name,
      sku: category.selectedProduct.selectedPrice.subscriptionPriceId,
      price: category.selectedProduct.selectedPrice.price,
      discount:
        coupon && category.slug === CATEGORY_SLUG.WEIGHT_LOSS
          ? {
              code: coupon.code,
              amount: coupon.amountOff,
              percent: coupon.percentOff,
            }
          : undefined,
    })),
  });
};
