import type { PropsWithChildren } from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { PendingRefillRequestModal } from "@/domains/my-meds/components";
import {
  ChangePlanFridaysModal,
  ChangePlanOpenLoopModal,
  ChangeToTirzepatideMonthlyModal,
} from "@/modals";
import { MissingGenderModal } from "@/shared/components";
import { NatureBackground, ScrollArea } from "@/ui";

const layoutVariance = tv({
  slots: {
    base: "flex flex-col bg-salmon-01",
    container: "flex h-full grow flex-col",
    content: "flex grow flex-col gap-7 p-6 md:px-16 md:py-12",
    header: "pt-14 text-center font-serif text-3xl text-brown-10",
    paragraph:
      "pt-2 text-center text-sm font-medium text-brown-08 sm:text-lg sm:leading-6",
  },
  variants: {
    variant: {
      secondary: { base: "bg-brown-02" },
      nature: {
        base: "z-10 bg-nature-10",
        content: "grow",
        header: "px-12 font-serif text-6xl text-nature-01",
      },
    },
    hasCardHeader: {
      false: {
        base: "md:pt-3",
        header: "pt-6 md:pt-14",
      },
    },
  },
  compoundVariants: [
    {
      variant: "nature",
      hasCardHeader: false,
      className: {
        header: "mx-auto max-w-3xl px-6 text-4xl md:px-12 md:text-6xl",
      },
    },
  ],
  defaultVariants: {
    hasCardHeader: true,
  },
});

type LayoutVariance = VariantProps<typeof layoutVariance>;

interface ScreenLayoutProps extends PropsWithChildren, LayoutVariance {
  className?: string;
  title?: string;
  description?: string;
}

export const ScreenLayout = ({
  children,
  className,
  variant,
  title,
  description,
  hasCardHeader,
}: ScreenLayoutProps) => {
  const { base, header, paragraph, content, container } = layoutVariance({
    variant,
    hasCardHeader,
  });
  return (
    <ScrollArea className={base()}>
      {variant === "nature" && <NatureBackground />}

      <div className={container()}>
        {Boolean(title ?? description) && (
          <div>
            <h2 className={header()}>{title}</h2>
            <p className={paragraph()}>{description}</p>
          </div>
        )}

        <div className={content({ className })}>{children}</div>
      </div>

      <ChangePlanOpenLoopModal />
      <ChangePlanFridaysModal />
      <ChangeToTirzepatideMonthlyModal />
      <PendingRefillRequestModal />
      <MissingGenderModal />
      <ChangeToTirzepatideMonthlyModal />
    </ScrollArea>
  );
};
