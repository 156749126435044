import type { CategoryWithSelectedProduct } from "@/domains/products/models";

export const getPricesIds = (
  selectedCategories: CategoryWithSelectedProduct[],
) => {
  return selectedCategories
    .filter((category) => category.selectedProduct?.selectedPrice)
    .map(
      (category) =>
        category.selectedProduct?.selectedPrice?.subscriptionPriceId ?? "",
    );
};
