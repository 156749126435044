import { t } from "ttag";

import type { Coupon } from "@/domains/billing/models";
import type { CategoryWithSelectedProduct } from "@/domains/products/models";
import { calculateTotal } from "@/domains/products/utils";
import { formatPrice } from "@/shared/utils";
import { CardPrimitive } from "@/ui";

interface TotalSummaryProp {
  categories: CategoryWithSelectedProduct[];
  coupon?: Coupon;
}

export const TotalSummary = ({ categories, coupon }: TotalSummaryProp) => {
  const isCouponValid = coupon?.isValid;

  const total = calculateTotal(categories);
  const totalWithDiscount = isCouponValid
    ? calculateTotal(categories, { coupon })
    : total;

  return (
    <CardPrimitive.Root className="flex-col gap-0 rounded bg-brown-02 p-3">
      <CardPrimitive.Content className="flex items-center justify-between gap-1">
        <CardPrimitive.Description className="text-base font-medium">
          {t`Total`}
        </CardPrimitive.Description>

        <div className="flex items-center gap-2">
          {isCouponValid ? (
            <>
              <CardPrimitive.Title className="text-salmon-07 line-through">
                {formatPrice(total)}
              </CardPrimitive.Title>

              <CardPrimitive.Title>
                {formatPrice(totalWithDiscount)}
              </CardPrimitive.Title>
            </>
          ) : (
            <CardPrimitive.Title>{formatPrice(total)}</CardPrimitive.Title>
          )}
        </div>
      </CardPrimitive.Content>

      <CardPrimitive.Footer className="justify-between">
        <CardPrimitive.Description className="text-xs text-salmon-08">
          {t`You pay today`}
        </CardPrimitive.Description>

        {isCouponValid && (
          <CardPrimitive.Description className="text-xs text-salmon-08">
            {t`You save`} {formatPrice(total - totalWithDiscount)}
          </CardPrimitive.Description>
        )}
      </CardPrimitive.Footer>
    </CardPrimitive.Root>
  );
};
