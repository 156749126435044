import { Link } from "react-router-dom";
import { t } from "ttag";

import { CategoryBadge } from "@/domains/products/components";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { useBoolean } from "@/hooks";
import { CancelSubscriptionModal } from "@/modals";
import { useIsMobile } from "@/shared/hooks";
import { formatPrice } from "@/shared/utils";
import { Button, DetailCard } from "@/ui";
import { tw } from "@/utils";
import type { DetailedSubscription } from "../models";
import { BILLING_ROUTE } from "../router";

interface ActiveSubscriptionCardProps {
  subscription: DetailedSubscription;
}

export const ActiveSubscriptionCard = ({
  subscription,
}: ActiveSubscriptionCardProps) => {
  const { currentProduct: product } = subscription;

  const isMobile = useIsMobile();

  const {
    value: showCancelSubscriptionModal,
    setTrue: openCancelSubscriptionModal,
    setFalse: closeCancelSubscriptionModal,
  } = useBoolean(false);

  const isWeightLoss = product.category.slug === CATEGORY_SLUG.WEIGHT_LOSS;

  return (
    <DetailCard.Root
      variant="borderLess"
      className="grid grid-cols-2 gap-y-2 bg-brown-02 md:gap-y-4"
    >
      <DetailCard.Header className="col-span-full items-center justify-between gap-x-2 pr-0 md:col-span-1 md:justify-normal">
        <DetailCard.Title>{product.name}</DetailCard.Title>
        <CategoryBadge categorySlug={product.category.slug}>
          {product.category.name}
        </CategoryBadge>
      </DetailCard.Header>

      <DetailCard.Footer className="col-span-2 row-start-3 mt-2 flex flex-col-reverse gap-2 px-0 md:col-start-2 md:row-start-1 md:mt-0 md:flex-row md:gap-3 md:justify-self-end">
        <Button
          onClick={openCancelSubscriptionModal}
          variant={isMobile ? "secondary" : "tertiary"}
          color="red"
          size="lg"
          className={tw(!isMobile && "p-0")}
        >
          {t`Cancel`}
        </Button>

        {isWeightLoss && (
          <Button
            variant={isMobile ? "secondary" : "tertiary"}
            size="lg"
            className={tw(!isMobile && "p-0")}
            asChild
          >
            <Link
              to={BILLING_ROUTE.CHANGE_SUBSCRIPTION}
              state={product.category}
            >{t`Change`}</Link>
          </Button>
        )}
      </DetailCard.Footer>

      <DetailCard.Content className="col-span-full flex-row justify-between px-0">
        <DetailCard.Description>{product.description}</DetailCard.Description>
        <DetailCard.Title>{formatPrice(product.price.price)}</DetailCard.Title>
      </DetailCard.Content>

      <CancelSubscriptionModal
        show={showCancelSubscriptionModal}
        onClose={closeCancelSubscriptionModal}
      />
    </DetailCard.Root>
  );
};
