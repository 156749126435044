import { useQuery } from "@tanstack/react-query";

import type { UserAttributes } from "@/shared/models";
import { productsQueriesStore } from "../api";

export const useProductCategories = (userAttributes?: UserAttributes) => {
  return useQuery(
    productsQueriesStore.products.category._ctx.list(userAttributes),
  );
};
