import type { ComponentPropsWithoutRef } from "react";
import type { Control, UseFormRegister } from "react-hook-form";
import { t } from "ttag";

import type { DateOfBirthValues } from "@/shared.types";
import { DateOfBirthInputs, Form } from "@/ui";

interface FieldValue {
  dateOfBirth: DateOfBirthValues;
}

type DateOfBirthControl<T> = T extends FieldValue
  ? Control<T, "dateOfBirth">
  : never;
type DateOfBirthRegister<T> = T extends FieldValue ? UseFormRegister<T> : never;

type DateOfBirthFieldProps<T extends FieldValue> = ComponentPropsWithoutRef<
  typeof Form.Item
> & {
  control: DateOfBirthControl<T>;
  register: DateOfBirthRegister<T>;
};

export const DateOfBirthField = <T extends FieldValue>({
  control,
  register,
  ...props
}: DateOfBirthFieldProps<T>) => {
  return (
    <Form.Field
      control={control}
      name="dateOfBirth"
      render={({ formState: { errors } }) => (
        <Form.Item {...props}>
          <Form.Label>{t`Date of birth *`}</Form.Label>
          <Form.Control>
            <DateOfBirthInputs register={register} />
          </Form.Control>
          <Form.Message compact={false}>
            {errors.dateOfBirth?.month?.message ??
              errors.dateOfBirth?.day?.message ??
              errors.dateOfBirth?.year?.message ??
              errors.dateOfBirth?.root?.message}
          </Form.Message>
        </Form.Item>
      )}
    />
  );
};
