import type { ServiceResponse } from "@/api";
import { publicAPI } from "@/api";
import type { HealthieForm, HealthieFormParams } from "../models";

export const getHealthieFormFields = async (params: HealthieFormParams) => {
  const { data } = await publicAPI.get<ServiceResponse<HealthieForm>>(
    "/forms",
    { params },
  );

  return data.data;
};

interface SubmitFormParams {
  formId: HealthieForm["formId"];
  patientId: HealthieFormParams["patientId"];
  productId: HealthieFormParams["productId"];
  answers: Record<string, string | string[]>;
}

type FieldValues = Record<string, string | string[]>;
interface SubmitField {
  value: string | string[] | undefined;
  fieldId: string;
}

const parseFormFields = (params: FieldValues): SubmitField[] => {
  return Object.keys(params)
    .map((key) => ({
      value: Array.isArray(params[key]) ? params[key].join("\n") : params[key],
      fieldId: key,
    }))
    .filter((field) => !!field.value);
};

export const submitHealthieForm = async ({
  answers,
  ...params
}: SubmitFormParams) => {
  const { data } = await publicAPI.post<ServiceResponse<unknown>>(
    "/forms",
    {
      ...params,
      answers: parseFormFields(answers),
    },
    { headers: { "Content-Type": "multipart/form-data" } },
  );

  return data.data;
};
