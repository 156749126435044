import { usePortalUserStore } from "@/stores";
import { isInactiveSubscription } from "@/utils";
import {
  HomeTabs,
  InactiveSubscriptionAlert,
  IncompleteIntakeFormAlert,
} from "./components";

export const HomeContent = () => {
  const portalUser = usePortalUserStore((state) => state.portalUser);
  const inactiveSubscriptions =
    portalUser?.subscriptions.filter((subscription) =>
      isInactiveSubscription(subscription.status),
    ) ?? [];

  if (!portalUser) {
    return null;
  }

  const activeSubscriptionsWithIncompletedIntakeForm =
    portalUser.subscriptions.filter(
      ({ intakeFormCompleted, status }) =>
        !intakeFormCompleted && !isInactiveSubscription(status),
    );

  if (
    activeSubscriptionsWithIncompletedIntakeForm.length ||
    inactiveSubscriptions.length
  ) {
    return (
      <div className="flex flex-col gap-6">
        {inactiveSubscriptions.map((subscription) => (
          <InactiveSubscriptionAlert
            key={subscription.id}
            inactiveSubscription={subscription}
          />
        ))}

        <IncompleteIntakeFormAlert
          subscriptions={activeSubscriptionsWithIncompletedIntakeForm}
        />
      </div>
    );
  }

  return <HomeTabs />;
};
