import type { InternalAxiosRequestConfig } from "axios";

import { AVAILABLE_LANGUAGE } from "@/shared/models";
import { useAppSettingsStore } from "@/stores";

export const languageInterceptor = (config: InternalAxiosRequestConfig) => {
  const language = useAppSettingsStore.getState().language;

  config.headers.set(
    "Accept-Language",
    language === AVAILABLE_LANGUAGE.ES
      ? AVAILABLE_LANGUAGE.ES
      : AVAILABLE_LANGUAGE.EN,
  );

  return config;
};
