import { Link } from "react-router-dom";
import { jt, t } from "ttag";

import { FullScreenLoader } from "@/components";
import {
  useActiveSubscriptions,
  useSetSubscriptionDowngrade,
} from "@/domains/billing/hooks";
import { DURATION_IN_MONTHS } from "@/domains/billing/models";
import { WL_PRODUCT_SLUG } from "@/domains/products/models";
import { useBoolean } from "@/hooks";
import { ROUTES } from "@/router";
import { SUPPORT_EMAIL } from "@/shared.constants";
import { Button, Dialog, ShieldWarningIconMono } from "@/ui";

export const ChangeToTirzepatideMonthlyModal = () => {
  const { data: activeSubscriptions, isSuccess: isSuccessActiveSubscriptions } =
    useActiveSubscriptions();

  const setSubscriptionDowngradeMutation = useSetSubscriptionDowngrade();

  const tirzepatideQuarterlySubscription = activeSubscriptions?.find(
    (subscription) =>
      subscription.currentProduct.slug === WL_PRODUCT_SLUG.TIRZEPATIDE &&
      subscription.currentProduct.price.durationInMonths ===
        DURATION_IN_MONTHS.QUARTERLY,
  );

  const hasTirzepatideQuarterlySubscription = Boolean(
    tirzepatideQuarterlySubscription,
  );
  const hasNextPeriodTirzepatideQuarterlySubscription =
    tirzepatideQuarterlySubscription?.nextPeriodProduct?.slug ===
      WL_PRODUCT_SLUG.TIRZEPATIDE &&
    tirzepatideQuarterlySubscription?.nextPeriodProduct?.price
      .durationInMonths === DURATION_IN_MONTHS.QUARTERLY;

  const { value: showModal, setFalse: onClose } = useBoolean(true);

  const shouldShowModal =
    isSuccessActiveSubscriptions &&
    hasTirzepatideQuarterlySubscription &&
    (!tirzepatideQuarterlySubscription?.nextPeriodProduct ||
      hasNextPeriodTirzepatideQuarterlySubscription) &&
    showModal;

  const handleAcceptSubscriptionUpdate = () => {
    if (!tirzepatideQuarterlySubscription?.id) {
      return;
    }

    setSubscriptionDowngradeMutation.mutate(
      tirzepatideQuarterlySubscription?.id,
      { onSuccess: onClose },
    );
  };

  const supportEmail = (
    <a
      key="support-email"
      href="mailto:support@joinfridays.com"
      className="font-semibold text-nature-08"
    >
      {SUPPORT_EMAIL}
    </a>
  );

  return (
    <>
      <Dialog.Root open={shouldShowModal} onOpenChange={onClose}>
        <Dialog.Content size="modal" className="h-fit">
          <div className="flex h-full flex-col gap-6">
            <Dialog.Header>
              <Dialog.Icon>
                <ShieldWarningIconMono color="salmon" />
              </Dialog.Icon>
              <Dialog.Title>{t`Important update to your subscription`}</Dialog.Title>
              <Dialog.Description>
                {t`The 3-month tirzepatide subscription option is not currently available. To continue your treatment without interruption, please click 'Accept subscription update' to schedule your subscription change to monthly for the next billing period.`}
              </Dialog.Description>
              <Dialog.Description>
                {jt`If you have any questions you can message our team in the Messages tab or email us at ${supportEmail}.`}
              </Dialog.Description>
            </Dialog.Header>

            <Dialog.Footer variant="sticky">
              <Button size="lg" variant="secondary" asChild>
                <Link to={ROUTES.MESSAGES}>{t`Contact customer support`}</Link>
              </Button>
              <Button
                onClick={handleAcceptSubscriptionUpdate}
                size="lg"
              >{t`Accept subscription update`}</Button>
            </Dialog.Footer>
          </div>

          {setSubscriptionDowngradeMutation.isPending && <FullScreenLoader />}
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
