import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PORTAL_DOMAIN } from "@/api";
import { errorToast } from "@/ui";
import { intakeFormQueriesStore, submitHealthieForm } from "../api";

export const useSubmitHealthieForm = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: submitHealthieForm,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [PORTAL_DOMAIN] });
      void queryClient.invalidateQueries({
        queryKey: intakeFormQueriesStore.healthie.form._def,
      });
    },
    onError: errorToast,
  });
};
