import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { createContext, forwardRef, useContext } from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { tw } from "@/utils";
import { CardPrimitive, Skeleton as SkeletonPrimitive } from "../common";
import { Label as LabelPrimitive } from "./Label";
import { RadioIndicator } from "./RadioIndicator";

const radioGroupCardsVariants = tv({
  slots: {
    root: "grid gap-2",
    item: "relative flex cursor-pointer flex-col rounded-2xl border p-4 pr-12 text-left shadow-sm focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 [&_*]:disabled:cursor-not-allowed",
    label: "cursor-pointer font-bold leading-5",
  },
  variants: {
    color: {
      nature: {
        item: "border-brown-04 bg-brown-01 focus-visible:ring-nature-03 data-[state=checked]:border-nature-04 data-[state=checked]:bg-nature-02",
      },
      brown: {
        item: "border-brown-04 bg-salmon-01 focus-visible:ring-brown-05 data-[state=checked]:border-brown-05 data-[state=checked]:bg-brown-03",
      },
    },
    size: {
      sm: {
        item: "rounded-lg py-3 pl-2",
      },
    },
    borderLess: {
      true: "border-0",
    },
  },

  compoundVariants: [
    {
      color: "nature",
      borderLess: true,
      className: {
        item: "border-transparent bg-nature-01",
      },
    },
  ],

  defaultVariants: {
    color: "nature",
  },
});

const VariantsContext = createContext<
  VariantProps<typeof radioGroupCardsVariants>
>({ color: "nature" });

const useVariantsContext = () => {
  const context = useContext(VariantsContext);

  if (!context) {
    throw new Error(
      "useVariantsContext must be used within a VariantsContext.Provider",
    );
  }
  return { color: context.color, ...radioGroupCardsVariants(context) };
};

const ItemIndicator = forwardRef<
  ElementRef<typeof RadioIndicator>,
  ComponentPropsWithoutRef<typeof RadioIndicator>
>((props, ref) => {
  const { color } = useVariantsContext();

  return <RadioIndicator color={color} ref={ref} {...props} />;
});
ItemIndicator.displayName = "RadioGroupCards.ItemIndicator";
const ItemDescription = CardPrimitive.Description;

type RootProps = ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> &
  VariantProps<typeof radioGroupCardsVariants>;

const Root = forwardRef<ElementRef<typeof RadioGroupPrimitive.Root>, RootProps>(
  ({ className, color, size, borderLess, ...props }, ref) => {
    const { root } = useVariantsContext();

    return (
      <VariantsContext.Provider value={{ color, size, borderLess }}>
        <RadioGroupPrimitive.Root
          className={root({ className })}
          ref={ref}
          {...props}
        />
      </VariantsContext.Provider>
    );
  },
);
Root.displayName = RadioGroupPrimitive.Root.displayName;

const Item = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Item>,
  ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  const { item } = useVariantsContext();

  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={item({ className })}
      {...props}
    />
  );
});
Item.displayName = RadioGroupPrimitive.Item.displayName;

const ItemLabel = forwardRef<
  ElementRef<typeof LabelPrimitive>,
  ComponentPropsWithoutRef<typeof LabelPrimitive>
>(({ className, ...props }, ref) => {
  const { label } = useVariantsContext();

  return (
    <LabelPrimitive
      ref={ref}
      size="md"
      className={label({ className })}
      {...props}
    />
  );
});
ItemLabel.displayName = LabelPrimitive.displayName;

const Skeleton = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    return (
      <div ref={ref} className={tw("grid gap-2", className)} {...props}>
        {Array.from({ length: 3 }, (_, index) => `${index}`).map((i) => (
          <div
            key={`radio-group-skeleton-${i}`}
            className="flex flex-col gap-3 rounded-2xl border border-brown-04 bg-brown-01 p-4 shadow-sm"
          >
            <div className="flex items-center justify-between gap-4">
              <SkeletonPrimitive className="h-4 w-2/5" />
              <SkeletonPrimitive className="size-5" />
            </div>

            <SkeletonPrimitive className="h-3 w-3/5" />
          </div>
        ))}
      </div>
    );
  },
);
Skeleton.displayName = "RadioGroupCards.Skeleton";

export { Root, Item, ItemIndicator, ItemLabel, ItemDescription, Skeleton };
