import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import Markdown from "react-markdown";
import { t } from "ttag";

import type { Product } from "@/domains/products/models";
import {
  Accordion,
  AltArrowDownIcon,
  Label,
  RadioIndicator,
  ShowcaseCard,
} from "@/ui";

type ProductCardProps = ComponentPropsWithoutRef<typeof ShowcaseCard.Root> & {
  product: Product;
};

export const ProductCard = forwardRef<
  ElementRef<typeof ShowcaseCard.Root>,
  ProductCardProps
>(({ product, ...props }, ref) => {
  return (
    <ShowcaseCard.Root ref={ref} {...props}>
      <ShowcaseCard.Inset className="relative">
        <ShowcaseCard.Thumbnail src={product.thumbnail} />

        <ShowcaseCard.Badges className="absolute left-4 top-4 w-2/3">
          {product.tags.map((tag) => (
            <ShowcaseCard.Badge key={tag.label}>{tag.label}</ShowcaseCard.Badge>
          ))}

          {product.priceTag && (
            <ShowcaseCard.Badge className="border-brown-05 font-bold">
              {product.priceTag}
            </ShowcaseCard.Badge>
          )}
        </ShowcaseCard.Badges>

        <RadioIndicator className="absolute right-4 top-4" />
      </ShowcaseCard.Inset>

      <ShowcaseCard.Header>
        <ShowcaseCard.Title>{product.name}</ShowcaseCard.Title>
        <ShowcaseCard.Description className="text-brown-07">
          {product.description}
        </ShowcaseCard.Description>
      </ShowcaseCard.Header>

      {product.disclaimer && (
        <ShowcaseCard.Description>
          {product.disclaimer}
        </ShowcaseCard.Description>
      )}

      {product.features && (
        <ShowcaseCard.Content>
          <Accordion.Root type="single" className="w-full" collapsible>
            <Accordion.Item
              value="features"
              onClick={(event) => event.stopPropagation()}
              className="flex flex-col gap-2"
            >
              <Accordion.Trigger className="justify-between [&[data-state=open]>svg]:rotate-180">
                <Label
                  size="sm"
                  htmlFor={`${product.id}-treatments`}
                  className="cursor-pointer text-left font-bold text-brown-08"
                >
                  {t`Here's what you need to know`}
                </Label>

                <AltArrowDownIcon className="size-4 transition-transform duration-200" />
              </Accordion.Trigger>

              <Accordion.Content>
                <Markdown
                  components={{
                    ul: (props) => (
                      <ShowcaseCard.List
                        id={`${product.id}-treatments`}
                        {...props}
                        className="text-brown-08"
                      />
                    ),
                  }}
                >
                  {product.features.map((feature) => `- ${feature}`).join("\n")}
                </Markdown>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        </ShowcaseCard.Content>
      )}
    </ShowcaseCard.Root>
  );
});
ProductCard.displayName = "ProductCard";
