import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getDocumentsQuery } from "@/api";
import { UploadedDocument } from "@/components";
import { ScreenLayout } from "@/layouts";
import { MODAL_ROUTES, useNavigateModal } from "@/router";
import { DOCUMENT_FOLDER } from "@/shared.constants";
import { usePortalUserStore } from "@/stores";
import {
  Button,
  EmptyState,
  FolderCheckIcon,
  IconWrapper,
  UploadedFilesSkeleton,
} from "@/ui";
import { MedicalRecordsDetailsHeader } from "./components";

const FOLDER = DOCUMENT_FOLDER.OTHER;

export const MedicalRecordsDetails = () => {
  const { data, isLoading } = useQuery(getDocumentsQuery(FOLDER));
  const navigateModal = useNavigateModal();

  const hasActiveWLSubscription = usePortalUserStore(
    (state) => state.hasActiveWLSubscription,
  );

  return (
    <ScreenLayout>
      <MedicalRecordsDetailsHeader />

      {isLoading && <UploadedFilesSkeleton />}

      <div className="flex grow flex-col">
        {!!data?.length && (
          <div className="flex flex-col gap-4">
            {data.map((file) => (
              <UploadedDocument key={file.id} folder={FOLDER} file={file} />
            ))}
          </div>
        )}

        {!data?.length && !isLoading && (
          <EmptyState.Root>
            <EmptyState.Icon />
            <EmptyState.Description>
              {t`No documents found in this folder.`}
              <br />
              {t`You can upload new documents using the button above.`}
            </EmptyState.Description>
          </EmptyState.Root>
        )}
      </div>

      {hasActiveWLSubscription && (
        <div className="sticky bottom-0 -m-6 bg-salmon-01 p-6 py-5 md:hidden">
          <Button
            size="lg"
            onClick={() =>
              navigateModal(MODAL_ROUTES.DOCUMENT_CENTER.MEDICAL_RECORDS)
            }
            className="w-full"
          >
            <IconWrapper size="sm">
              <FolderCheckIcon />
            </IconWrapper>
            {t`Add medical records`}
          </Button>
        </div>
      )}
    </ScreenLayout>
  );
};
