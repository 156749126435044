import type { PortalUser } from "@/shared/models";

export const handleNorthbeamConvertEvent = (userCode?: PortalUser["code"]) => {
  try {
    if (!window?.Northbeam) {
      throw new Error("Northbeam not loaded");
    }

    if (!userCode) {
      throw new Error("User code is not set");
    }

    const identifyEmail = `${userCode}@identify.com`;

    void window.Northbeam.identify("email", identifyEmail);
  } catch (error) {
    console.error("Error tracking Northbeam event", error);
  }
};
