import type { ComponentPropsWithoutRef } from "react";
import { Fragment } from "react/jsx-runtime";

import { useExploreSubscriptionsContext } from "@/domains/billing/context";
import { ProductSummary, TotalSummary } from "@/domains/products/components";
import { CardPrimitive as Card } from "@/ui";
import { tw } from "@/utils";

type ProductsSummaryProps = ComponentPropsWithoutRef<typeof Card.Root> & {
  readOnly?: boolean;
  onEmptyProducts: () => void;
};

export const ProductsSummary = ({
  readOnly,
  className,
  onEmptyProducts,
  ...props
}: ProductsSummaryProps) => {
  const {
    categories,
    actions: { setSelectedPrice, removeProduct },
  } = useExploreSubscriptionsContext();

  const categoriesWithProducts = categories.filter((category) =>
    Boolean(category.selectedProduct),
  );

  if (!categoriesWithProducts.length) {
    onEmptyProducts();
  }

  return (
    <Card.Root
      className={tw("mx-auto h-fit w-full max-w-xl bg-white p-5", className)}
      {...props}
    >
      {categoriesWithProducts.map(({ name, selectedProduct }) => (
        <Fragment key={selectedProduct?.id}>
          <Card.Content className="flex flex-col gap-4">
            <Card.Subtitle>{name}</Card.Subtitle>

            <ProductSummary
              product={selectedProduct}
              readOnly={readOnly}
              onPriceChange={setSelectedPrice}
              onRemove={removeProduct}
            />
          </Card.Content>

          <Card.Separator className="my-2" />
        </Fragment>
      ))}

      <TotalSummary categories={categories} />
    </Card.Root>
  );
};
