import { publicAPI } from "@/api";
import type { ServiceResponse } from "@/api";
import type { AnalyticsSlice } from "@/domains/onboarding/stores";
import type { OnboardingUser } from "@/shared.types";

interface CustomerMergeData {
  userCode: OnboardingUser["code"];
  previousCustomerIoId: AnalyticsSlice["analyticsCustomerId"];
}

export const mergeCustomerIoProfiles = async ({
  userCode,
  previousCustomerIoId,
}: CustomerMergeData) => {
  return await publicAPI.post<ServiceResponse<void>>(
    `/patients/${userCode}/analytics/merge`,
    {
      previousCustomerId: previousCustomerIoId,
    },
  );
};
