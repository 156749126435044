import { useQuery } from "@tanstack/react-query";

import type { UserAttributes } from "@/shared/models";
import { productsQueriesStore } from "../api";
import type { Category } from "../models";

export const useProductsByCategory = (
  categoryId?: Category["id"],
  userAttributes?: UserAttributes,
) => {
  return useQuery(
    productsQueriesStore.products.category._ctx.products(
      categoryId,
      userAttributes,
    ),
  );
};
