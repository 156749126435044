import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import {
  EMBEDDABLES_ONBOARDING_ROUTE,
  requiresMainInfoData,
  requiresMeasurementsData,
} from "@/domains/embeddables-onboarding/router";

export const RequireFormData = () => {
  const { pathname } = useLocation();
  const { mainInfoFormValues, measurementsFormValues } = useEmbeddablesForms();

  if (requiresMainInfoData(pathname) && !mainInfoFormValues) {
    return <Navigate replace to={EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO} />;
  }

  if (requiresMeasurementsData(pathname) && !measurementsFormValues) {
    return (
      <Navigate
        replace
        to={EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO_MEASUREMENTS}
      />
    );
  }

  return <Outlet />;
};
