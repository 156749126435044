import { useState } from "react";
import { t } from "ttag";

import { ErrorState } from "@/components";
import { useExploreSubscriptionsContext } from "@/domains/billing/context";
import { LoadingProducts } from "@/domains/products/components";
import { usePatientProductsByCategory } from "@/domains/products/hooks";
import { Badge, Button, EmptyState } from "@/ui";
import { ProductSelection } from "./ProductSelection";

export const ExploreSubscriptionsChooseProducts = () => {
  const { categories } = useExploreSubscriptionsContext();

  const [selectedCategoryId, setSelectedCategoryId] = useState(
    categories[0]?.id,
  );

  const {
    data: products,
    isSuccess,
    isLoading,
    isError,
  } = usePatientProductsByCategory(selectedCategoryId);

  if (isError) {
    return <ErrorState className="grow-0 border-none" />;
  }

  return (
    <>
      <div className="flex grow flex-col gap-6">
        <div className="flex flex-wrap items-center justify-center gap-2.5">
          {categories?.map((sessionCategory) => {
            return (
              <Badge
                key={sessionCategory.id}
                asChild
                rounded="3xl"
                size="xl"
                variant="secondary"
                clickable
                selected={selectedCategoryId === sessionCategory.id}
              >
                <Button
                  onClick={() => setSelectedCategoryId(sessionCategory.id)}
                >
                  {sessionCategory.name}
                </Button>
              </Badge>
            );
          })}
        </div>

        {isLoading && <LoadingProducts />}

        {isSuccess && Boolean(products.length) && (
          <ProductSelection key={selectedCategoryId} products={products} />
        )}

        {(!selectedCategoryId || (isSuccess && !products.length)) && (
          <div className="flex grow items-center justify-center">
            <EmptyState.Root className="-translate-y-10 border-transparent md:-translate-y-20">
              <EmptyState.Icon />
              <EmptyState.Title className="text-brown-05">{t`Oops!`}</EmptyState.Title>
              <EmptyState.Description>
                {t`Looks like there are no products available in this category.`}
              </EmptyState.Description>
            </EmptyState.Root>
          </div>
        )}
      </div>
    </>
  );
};
