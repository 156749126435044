import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { ErrorState } from "@/components";
import { categorySchema } from "@/domains/products/models";
import { ScreenLayout } from "@/layouts";
import { Form } from "@/ui";
import {
  ChangeSubscriptionContent,
  ChangeSubscriptionFooter,
  ChangeSubscriptionHeader,
} from "./sections";
import { getSubscriptionSchema } from "./shared";

export const ChangeSubscription = () => {
  const form = useForm({
    resolver: zodResolver(getSubscriptionSchema()),
    defaultValues: {
      hasConsent: false,
    },
  });

  const location = useLocation();
  const parsedCategory = categorySchema
    .pick({ id: true, slug: true, name: true })
    .safeParse(location.state);

  if (parsedCategory.error) {
    return <ErrorState className="-translate-y-10 border-none" />;
  }

  return (
    <ScreenLayout className="lg:gap-1">
      <ChangeSubscriptionHeader />

      <Form.Provider {...form}>
        <div className="flex grow flex-col gap-6 lg:gap-0">
          <ChangeSubscriptionContent category={parsedCategory.data} />

          <ChangeSubscriptionFooter />
        </div>
      </Form.Provider>
    </ScreenLayout>
  );
};
