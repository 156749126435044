import type { Coupon } from "@/domains/billing/models";
import { CATEGORY_SLUG } from "../models";
import type { CategoryWithSelectedProduct } from "../models";

interface CalculateTotalOptions {
  coupon?: Coupon;
}

export const calculateTotal = (
  selectedCategories: CategoryWithSelectedProduct[],
  options: CalculateTotalOptions = {},
) => {
  const { coupon } = options;

  return selectedCategories.reduce((acc, category) => {
    const selectedProductPrice = category.selectedProduct?.selectedPrice;

    if (!selectedProductPrice) {
      return acc;
    }

    let price = selectedProductPrice.price;

    if (coupon?.isValid && category.slug === CATEGORY_SLUG.WEIGHT_LOSS) {
      price = coupon.priceAfterDiscount;
    }

    return acc + price;
  }, 0);
};
