import type { ComponentPropsWithoutRef, ForwardedRef } from "react";
import { t } from "ttag";

import { forwardRef } from "@/utils";
import { SelectPrimitive } from ".";

interface SelectOption<T> {
  value: T;
  label: string;
  disabled?: boolean;
}

type SelectTrigger = ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>;

type SelectProps<T extends SelectTrigger["value"] | number> = Omit<
  SelectTrigger,
  "onValueChange" | "value" | "onChange"
> & {
  options?: readonly SelectOption<T>[];
  placeholder?: string;
  value?: T;
  onChange: (value: T) => void;
  isLoading?: boolean;
};

export const Select = forwardRef(
  <T extends string | number>(
    {
      options = [],
      placeholder,
      value,
      onChange,
      className,
      isLoading,
      ...props
    }: SelectProps<T>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const handleChange = (value: T) => {
      if (typeof options?.[0]?.value === "number") {
        return onChange(Number(value) as T);
      }

      onChange(value);
    };

    return (
      <SelectPrimitive.Root
        value={value as never}
        onValueChange={(value) => handleChange(value as T)}
      >
        <SelectPrimitive.Trigger
          isLoading={isLoading}
          className={className}
          ref={ref}
          {...props}
        >
          <div className="flex grow items-center justify-between truncate">
            <span className="truncate">
              <SelectPrimitive.Value
                placeholder={
                  <span className="text-brown-06">
                    {isLoading ? t`Loading...` : (placeholder ?? t`Select...`)}
                  </span>
                }
              />
            </span>
          </div>
        </SelectPrimitive.Trigger>

        <SelectPrimitive.Content className="max-h-60 overflow-y-auto sm:max-h-112">
          {!options.length && (
            <div className="py-6 text-center" role="presentation">
              {t`No options available`}
            </div>
          )}
          {options.map((option) => (
            <SelectPrimitive.Item
              key={option.value}
              value={option.value as never}
              disabled={option.disabled}
            >
              {option.label}
            </SelectPrimitive.Item>
          ))}
        </SelectPrimitive.Content>
      </SelectPrimitive.Root>
    );
  },
);
