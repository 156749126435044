import { jt, t } from "ttag";

import { useActiveSubscriptions } from "@/domains/billing/hooks";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { Alert, LikeIcon } from "@/ui";

export const CurrentRequestAlert = () => {
  const { data, isSuccess } = useActiveSubscriptions();

  const wlNexPeriodProduct = data?.find(
    (subscription) =>
      subscription.nextPeriodProduct?.category.slug ===
      CATEGORY_SLUG.WEIGHT_LOSS,
  )?.nextPeriodProduct;

  if (!isSuccess || !wlNexPeriodProduct) {
    return null;
  }

  const subscriptionType =
    Number(wlNexPeriodProduct.price.durationInMonths) > 1
      ? t`bundle`
      : t`monthly`;

  const nextPeriodPlanChangeStrong = (
    <strong key="next-period-plan-alert-strong">
      {t`change to a ${subscriptionType} ${wlNexPeriodProduct.name} subscription`}
    </strong>
  );

  return (
    <Alert customIcon={<LikeIcon />}>
      {jt`You've already requested a ${nextPeriodPlanChangeStrong}. If you'd like to make another change, just go ahead!`}
    </Alert>
  );
};
