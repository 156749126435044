import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PORTAL_DOMAIN } from "@/api";
import { billingQueriesStore, setSubscriptionDowngrade } from "../api";

export const useSetSubscriptionDowngrade = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: setSubscriptionDowngrade,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [billingQueriesStore.stripe._def, PORTAL_DOMAIN],
      });
    },
  });
};
