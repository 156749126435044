import { t } from "ttag";

import { WeightProgress } from "@/components";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { NextStepsButton } from "@/screens/Home/components";
import type { ObjectValue } from "@/shared.types";
import { usePortalUserStore } from "@/stores";
import { GraphIcon, JarOfPillsIcon, Tabs } from "@/ui";
import { MyAgenda } from "./MyAgenda";

const HOME_TAB = {
  MY_AGENDA: "my-agenda",
  PROGRESS_TRACKER: "progress-tracker",
} as const;
type HomeTab = ObjectValue<typeof HOME_TAB>;

const HOME_TAB_ICON = {
  [HOME_TAB.MY_AGENDA]: <JarOfPillsIcon className="size-4" />,
  [HOME_TAB.PROGRESS_TRACKER]: <GraphIcon className="size-4" />,
} as const;

const getHomeTabLabel = (homeTab: HomeTab) => {
  const HOME_TAB_LABEL = {
    [HOME_TAB.MY_AGENDA]: t`My agenda`,
    [HOME_TAB.PROGRESS_TRACKER]: t`Progress tracker`,
  } as const;

  return HOME_TAB_LABEL[homeTab];
};

export const HomeTabs = () => {
  const hasWeightLossSubscription = usePortalUserStore(({ portalUser }) =>
    portalUser?.subscriptions.some(
      (subscription) =>
        subscription.product.category.slug === CATEGORY_SLUG.WEIGHT_LOSS,
    ),
  );

  return (
    <Tabs.Root
      defaultValue={HOME_TAB.MY_AGENDA}
      className="flex h-full flex-col"
    >
      <Tabs.List>
        <Tabs.Trigger value={HOME_TAB.MY_AGENDA} className="gap-x-2">
          {HOME_TAB_ICON[HOME_TAB.MY_AGENDA]}
          {getHomeTabLabel(HOME_TAB.MY_AGENDA)}
        </Tabs.Trigger>

        {hasWeightLossSubscription && (
          <Tabs.Trigger value={HOME_TAB.PROGRESS_TRACKER} className="gap-x-2">
            {HOME_TAB_ICON[HOME_TAB.PROGRESS_TRACKER]}
            {getHomeTabLabel(HOME_TAB.PROGRESS_TRACKER)}
          </Tabs.Trigger>
        )}
      </Tabs.List>

      <div className="grow py-6">
        <Tabs.Content value={HOME_TAB.MY_AGENDA}>
          <MyAgenda />
        </Tabs.Content>

        {hasWeightLossSubscription && (
          <Tabs.Content value={HOME_TAB.PROGRESS_TRACKER}>
            <WeightProgress />
          </Tabs.Content>
        )}
      </div>

      <NextStepsButton />
    </Tabs.Root>
  );
};
