import type { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

import { EmbeddablesFormContextProvider } from "@/domains/embeddables-onboarding/context";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { ROUTES } from "@/router";
import { useAuthStore } from "@/stores";
import { Loading } from "@/ui";
import { usePortalUserQuery } from "./hooks";

export const RequireEmbeddablesHealthieAccount: FC<PropsWithChildren> = () => {
  const { data: portalUser, isLoading, isError } = usePortalUserQuery();

  const isLoggedIn = useAuthStore((state) => Boolean(state.token));

  if (isLoading) {
    return <Loading />;
  }

  const hasIncompletedWLIntakeForm = Boolean(
    portalUser?.subscriptions.some(
      ({ intakeFormCompleted, product }) =>
        !intakeFormCompleted &&
        product.category.slug === CATEGORY_SLUG.WEIGHT_LOSS,
    ),
  );

  const isEmbeddablesHealthieAccount =
    portalUser?.isFromEmbeddables && portalUser.patientId;

  if (
    !isEmbeddablesHealthieAccount ||
    isError ||
    !isLoggedIn ||
    !hasIncompletedWLIntakeForm
  ) {
    return <Navigate replace to={ROUTES.BASE} />;
  }

  return <EmbeddablesFormContextProvider />;
};
