const BASE_ROUTE = "/onboarding";

export const ONBOARDING_ROUTE = {
  BASE: BASE_ROUTE,
  MAIN_INFO: `${BASE_ROUTE}/main-info`,
  TREATMENT: `${BASE_ROUTE}/treatment`,
  TREATMENT_CHOOSE_CATEGORIES: `${BASE_ROUTE}/treatment/choose-categories`,
  TREATMENT_BMI: `${BASE_ROUTE}/treatment/bmi`,
  TREATMENT_HEALTH_ELIGIBILITY_QUESTIONS: `${BASE_ROUTE}/treatment/eligibility-questions`,
  TREATMENT_CHOOSE_PRODUCTS: `${BASE_ROUTE}/treatment/choose-products`,
  TREATMENT_SUMMARY: `${BASE_ROUTE}/treatment/summary`,
  CHECKOUT: `${BASE_ROUTE}/checkout`,
  CHECKOUT_PAYMENT: `${BASE_ROUTE}/checkout/payment`,
  ACCOUNT: `${BASE_ROUTE}/account`,
} as const;
