import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";
import { Outlet } from "react-router-dom";

import { FullScreenErrorAlert, LoadingScreen } from "@/components";
import { useActiveSubscriptions } from "@/domains/billing/hooks";
import type { DetailedSubscription } from "@/domains/billing/models";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { usePatientQuery } from "@/hooks";
import type { Patient } from "@/shared.types";
import type { MainInfo, Measurements } from "../models";

interface EmbeddablesForms {
  patient: Patient;
  patientWLSubscription?: DetailedSubscription;
  mainInfoFormValues?: MainInfo;
  measurementsFormValues?: Measurements;

  actions: {
    setMainInfoFormValues: Dispatch<SetStateAction<MainInfo | undefined>>;
    setMeasurementsFormValues: Dispatch<
      SetStateAction<Measurements | undefined>
    >;
  };
}

export const EmbeddablesFormsContext = createContext<EmbeddablesForms | null>(
  null,
);

export const EmbeddablesFormContextProvider = () => {
  const [mainInfoFormValues, setMainInfoFormValues] = useState<MainInfo>();
  const [measurementsFormValues, setMeasurementsFormValues] =
    useState<Measurements>();

  const { data: patient, isLoading, isSuccess, isError } = usePatientQuery();

  const {
    data: patientSubscription,
    isLoading: isLoadingPatientSubscription,
    isSuccess: isSuccessPatientSubscription,
    isError: isErrorPatientSubscription,
  } = useActiveSubscriptions();

  const patientWLSubscription = patientSubscription?.find(
    (subscription) =>
      subscription.currentProduct.category.slug === CATEGORY_SLUG.WEIGHT_LOSS,
  );

  if (isLoading || isLoadingPatientSubscription) {
    return <LoadingScreen />;
  }

  if (isError || isErrorPatientSubscription) {
    return <FullScreenErrorAlert />;
  }

  if (isSuccess && isSuccessPatientSubscription) {
    return (
      <EmbeddablesFormsContext.Provider
        value={{
          patient,
          patientWLSubscription,
          mainInfoFormValues,
          measurementsFormValues,

          actions: {
            setMainInfoFormValues,
            setMeasurementsFormValues,
          },
        }}
      >
        <Outlet />
      </EmbeddablesFormsContext.Provider>
    );
  }

  return null;
};

export const useEmbeddablesForms = () => {
  const context = useContext(EmbeddablesFormsContext);

  if (!context) {
    throw new Error(
      "useEmbeddablesForms must be used within a <EmbeddablesFormsContext.Provider />",
    );
  }

  return context;
};
