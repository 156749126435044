import { isAfter } from "date-fns";
import { t } from "ttag";

import {
  EmptyMedicationRequestCard,
  MedicationRequestCard,
} from "@/domains/my-meds/components";
import { useCurrentMedicationRequests } from "@/domains/my-meds/hooks";
import { usePatientQuery } from "@/hooks";
import { Alert, CardPrimitive as Card, CardSkeleton, PillIcon } from "@/ui";
import { formatBackendDateToVerbose } from "@/utils";

export const CurrentMedicationRequests = () => {
  const {
    data: patient,
    isLoading: isLoadingPatient,
    isSuccess: isSuccessPatient,
  } = usePatientQuery();

  const hasUpcomingThirdInjection =
    patient?.thirdInjectionDate &&
    isAfter(new Date(patient.thirdInjectionDate), new Date());

  const {
    data: currentRequests,
    isLoading: isLoadingCurrentRequests,
    isSuccess: isSuccessCurrentRequests,
  } = useCurrentMedicationRequests();

  if (hasUpcomingThirdInjection) {
    const thirdInjectionDate = formatBackendDateToVerbose(
      patient.thirdInjectionDate,
    );

    return (
      <Alert>
        {t`You will be able to order medication after your third injection date
  on ${thirdInjectionDate}`}
      </Alert>
    );
  }

  const isLoading = isLoadingPatient || isLoadingCurrentRequests;
  const isSuccess = isSuccessPatient && isSuccessCurrentRequests;

  return (
    <Card.Root>
      <Card.Header>
        <Card.Title>
          <PillIcon className="size-4" />
          {t`Current medication requests`}
        </Card.Title>
        <Card.Description>
          {t`You wont be able to request medication until your current request has been delivered.`}
        </Card.Description>
      </Card.Header>

      <Card.Content>
        {isLoading && <CardSkeleton className="bg-salmon-01 px-2" />}

        {isSuccess && (
          <div className="flex flex-col gap-3">
            {currentRequests.length ? (
              currentRequests.map((request) => (
                <MedicationRequestCard
                  key={request.subscription.id}
                  className="border-transparent"
                  medicationRequest={request}
                />
              ))
            ) : (
              <EmptyMedicationRequestCard />
            )}
          </div>
        )}
      </Card.Content>
    </Card.Root>
  );
};
