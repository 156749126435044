import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { AnswerCheckboxGroup, AnswerRadioGroup } from "@/components";
import { NavigationButtons } from "@/domains/onboarding/components";
import { FIELD_TYPE } from "@/shared.constants";
import type { Answer, Question } from "@/shared/models";
import { extractAnswerDetails, tw } from "@/utils";

interface QuestionFormProps {
  question: Question;
  onAnswer: (answers: Answer["id"][]) => void;
}

export const QuestionForm = ({ question, onAnswer }: QuestionFormProps) => {
  const [answers, setAnswers] = useState<Answer["id"][]>([]);

  const navigate = useNavigate();

  const answerOptions = question.onboardingAnswers.map((answer) => ({
    ...extractAnswerDetails(answer),
    value: answer.id,
    unique: answer.unique,
  }));

  const handleNext = () => {
    onAnswer(answers);
    setAnswers([]);
  };

  return (
    <>
      <form className="flex grow flex-col gap-10 md:gap-12">
        <div className="flex grow flex-col gap-1.5">
          {question.type === FIELD_TYPE.CHECKBOX && (
            <AnswerCheckboxGroup
              id={`${question.id}-${question.type}`}
              options={answerOptions}
              value={answers}
              onChange={(value) => setAnswers(value)}
              className={tw(answerOptions.length < 4 && "xl:grid-cols-3")}
            />
          )}

          {question.type === FIELD_TYPE.RADIO && (
            <AnswerRadioGroup
              id={`${question.id}-${question.type}`}
              options={answerOptions}
              value={answers[0]}
              onValueChange={(value) => setAnswers([value])}
              className={tw(
                answerOptions.length < 4 && "xl:grid-cols-3",
                answerOptions.length === 2 && "xl:grid-cols-2",
              )}
            />
          )}
        </div>
      </form>

      <NavigationButtons
        onCancel={() => navigate(-1)}
        onContinue={handleNext}
        continueDisabled={!answers.length}
      />
    </>
  );
};
