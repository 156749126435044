import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import { skipToken } from "@tanstack/react-query";

import { STRIPE_DOMAIN } from "@/api";
import type { Subscription } from "@/domains/billing/models";
import type { PlansParams } from "../models";
import { getCoachingPlans, getOnboardingPatientPlans } from "./plans";
import {
  getPatientCurrentSubscriptions,
  getSubscriptionDowngrade,
} from "./subscriptions";

export const billingQueriesStore = createQueryKeyStore({
  [STRIPE_DOMAIN]: {
    subscriptions: {
      queryKey: null,
      contextQueries: {
        active: {
          queryKey: null,
          queryFn: getPatientCurrentSubscriptions,
        },
        downgrade: (subscriptionId?: Subscription["id"]) => ({
          queryKey: [subscriptionId],
          queryFn: subscriptionId
            ? () => getSubscriptionDowngrade(subscriptionId)
            : (skipToken as never),
        }),
      },
    },

    plans: {
      queryKey: null,
      contextQueries: {
        portal: {
          queryKey: null,
          contextQueries: {
            coaching: () => ({
              queryKey: ["getCoachingPlans"],
              queryFn: getCoachingPlans,
            }),
          },
        },

        onboarding: {
          queryKey: null,
          contextQueries: {
            patient: (params: PlansParams) => ({
              queryKey: [params],
              queryFn: () => getOnboardingPatientPlans(params),
            }),
          },
        },
      },
    },
  },
});
