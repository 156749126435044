import { t } from "ttag";

import { getSubscriptionDisclaimerText } from "@/domains/billing/utils";
import { useOnboardingStore } from "@/domains/onboarding/stores";
import { SUBSCRIPTION_CANCELLATION_EMAIL } from "@/shared.constants";
import { Alert, LightbulbIcon } from "@/ui";

export const PaymentDetails = () => {
  const selectedCategories = useOnboardingStore(
    (state) => state.selectedCategories,
  );
  const coupon = useOnboardingStore((state) => state.coupon);

  return (
    <Alert
      variant="outlined"
      customIcon={<LightbulbIcon />}
      className="text-brown-07"
    >
      <h4 className="mb-1 text-brown-10">{t`By selecting “Pay”, you agree to the following:`}</h4>

      <ul className="list-inside list-disc pl-2">
        {selectedCategories.map((category) => {
          const text = getSubscriptionDisclaimerText(category, coupon);

          return <li key={category.id}>{text}</li>;
        })}

        <li>{t`Charges will appear as "Fridays", "Fridays Health", or "Thrive Health Group Inc." on your bank statement.`}</li>
        <li>{t`Branded medication and lab test costs are not included in the subscription fee.`}</li>
        <li>{t`No refunds or credits for partial plan periods are given unless required by law.`}</li>
        <li>
          {t`You can cancel at any time by contacting `}
          <a
            href={`mailto:${SUBSCRIPTION_CANCELLATION_EMAIL}`}
            className="text-nature-08"
          >
            {SUBSCRIPTION_CANCELLATION_EMAIL}
          </a>
        </li>
      </ul>
    </Alert>
  );
};
