import type { StateCreator } from "zustand";

import type {
  Category,
  CategoryWithSelectedProduct,
  Price,
  Product,
} from "@/domains/products/models";

export interface TreatmentSlice {
  selectedCategories: CategoryWithSelectedProduct[];
  setSelectedCategories: (categories: Category[]) => void;
  addSelectedProduct: (product: Product) => void;
  removeSelectedProduct: (product: Product) => void;
  setSelectedPrice: (categoryId: Category["id"], selectedPrice: Price) => void;
}

export const createTreatmentSlice: StateCreator<
  TreatmentSlice,
  [],
  [],
  TreatmentSlice
> = (set) => ({
  selectedCategories: [],

  setSelectedCategories: (selectedCategories) =>
    set({ selectedCategories: selectedCategories.sort((a, b) => a.id - b.id) }),

  addSelectedProduct: (product) =>
    set(({ selectedCategories }) => ({
      selectedCategories: selectedCategories.map((category) =>
        category.id === product.category?.id
          ? {
              ...category,
              selectedProduct: {
                ...product,
                selectedPrice: product.prices.at(-1),
              },
            }
          : category,
      ),
    })),

  removeSelectedProduct: (product) =>
    set(({ selectedCategories }) => ({
      selectedCategories: selectedCategories.map((category) =>
        category.id === product.category?.id
          ? {
              ...category,
              selectedProduct: undefined,
            }
          : category,
      ),
    })),

  setSelectedPrice: (categoryId, selectedPrice) =>
    set(({ selectedCategories }) => ({
      selectedCategories: selectedCategories.map((category) =>
        category.id === categoryId
          ? {
              ...category,
              selectedProduct: category.selectedProduct && {
                ...category.selectedProduct,
                selectedPrice,
              },
            }
          : category,
      ),
    })),
});
