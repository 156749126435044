import { t } from "ttag";

import type { Coupon } from "@/domains/billing/models";
import type {
  Category,
  CategoryWithSelectedProduct,
  Price,
  Product,
} from "@/domains/products/models";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { formatPrice } from "@/shared/utils";
import { Badge, Button, DetailCard, RadioGroupCards } from "@/ui";

interface ProductSummaryProps {
  product: CategoryWithSelectedProduct["selectedProduct"];
  readOnly?: boolean;
  coupon?: Coupon;
  onPriceChange: (categoryId: Category["id"], price: Price) => void;
  onRemove: (product: Product) => void;
}

export const ProductSummary = ({
  product,
  readOnly,
  coupon,
  onPriceChange,
  onRemove,
}: ProductSummaryProps) => {
  if (!product) {
    return null;
  }

  const hasMultiplePrices = product.prices.length > 1;
  const hasDiscount = coupon?.isValid;

  const handlePriceChange = (subscriptionPriceId: string) => {
    const selectedPrice = product.prices.find(
      (price) => price.subscriptionPriceId === subscriptionPriceId,
    );

    if (selectedPrice) {
      onPriceChange(product.category.id, selectedPrice);
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <DetailCard.Root
        variant="borderLess"
        className="flex-row gap-2.5 sm:gap-4"
      >
        <DetailCard.Inset>
          <DetailCard.Thumbnail
            src={product.thumbnail}
            className="max-w-20 rounded-lg sm:max-w-24 sm:rounded-none"
          />
        </DetailCard.Inset>

        <div className="flex grow flex-col gap-2">
          <div>
            <DetailCard.Title>{product.name}</DetailCard.Title>
            <DetailCard.Description className="text-xs sm:text-sm">
              {product.description}
            </DetailCard.Description>
          </div>

          {!readOnly && (
            <Button
              onClick={() => onRemove(product)}
              variant="tertiary"
              className="mt-auto self-start p-0"
            >
              {t`Remove`}
            </Button>
          )}
        </div>

        {!hasMultiplePrices && product.prices[0] && !readOnly && (
          <div className="flex max-w-20 flex-col gap-3 text-right">
            <DetailCard.Title>
              {formatPrice(product.prices[0].price)}
            </DetailCard.Title>

            <DetailCard.Description className="mt-auto text-xs sm:text-sm">
              {t`Renews in`}
              <br />
              {product.prices[0].durationInMonths * 30} {t`days`}
            </DetailCard.Description>
          </div>
        )}

        {readOnly && (
          <div className="flex flex-col gap-3 text-right">
            {hasDiscount &&
            product.category.slug === CATEGORY_SLUG.WEIGHT_LOSS ? (
              <div className="flex items-center gap-1.5">
                <DetailCard.Title className="text-salmon-07 line-through">
                  {formatPrice(product.selectedPrice?.price)}
                </DetailCard.Title>

                <DetailCard.Title>
                  {formatPrice(coupon.priceAfterDiscount)}
                </DetailCard.Title>
              </div>
            ) : (
              <DetailCard.Title>
                {formatPrice(product.selectedPrice?.price)}
              </DetailCard.Title>
            )}

            <DetailCard.Description className="mt-auto">
              {t`Renews in`}
              <br />
              {(product.selectedPrice?.durationInMonths ?? NaN) * 30} {t`days`}
            </DetailCard.Description>
          </div>
        )}
      </DetailCard.Root>

      {hasMultiplePrices && !readOnly && (
        <RadioGroupCards.Root
          size="sm"
          borderLess
          defaultValue={product.selectedPrice?.subscriptionPriceId}
          onValueChange={handlePriceChange}
        >
          {product.prices.map(
            ({ id, subscriptionPriceId, price, durationInMonths, tags }) => (
              <RadioGroupCards.Item
                key={id}
                value={subscriptionPriceId}
                className="flex-row gap-2.5 pr-28"
              >
                <RadioGroupCards.ItemIndicator className="mt-0.5" />
                <span className="flex flex-wrap items-center gap-1">
                  <RadioGroupCards.ItemLabel>
                    {formatPrice(price)}
                  </RadioGroupCards.ItemLabel>

                  <RadioGroupCards.ItemDescription>
                    {`/ ${durationInMonths} ${durationInMonths > 1 ? t`months` : t`month`}`}
                  </RadioGroupCards.ItemDescription>

                  {durationInMonths > 1 && (
                    <RadioGroupCards.ItemDescription className="inline-flex gap-1 text-nature-07">
                      {t`for`}
                      <strong>{formatPrice(price / durationInMonths)}</strong>
                      {"/ "}
                      {t`month`}
                    </RadioGroupCards.ItemDescription>
                  )}
                </span>

                <span className="absolute right-4">
                  {tags.map((tag) => (
                    <Badge
                      key={tag.label}
                      rounded="3xl"
                      className="border-nature-07 bg-nature-07 text-xs text-nature-01"
                    >
                      {tag.label}
                    </Badge>
                  ))}
                </span>
              </RadioGroupCards.Item>
            ),
          )}
        </RadioGroupCards.Root>
      )}
    </div>
  );
};
