import { Navigate, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import { useScheduleAppointmentMutation } from "@/domains/consultations/hooks";
import { ScreenLayout } from "@/layouts";
import { ROUTES } from "@/router";
import { AltArrowLeftIcon, AltArrowRightIcon, Button } from "@/ui";
import { HealthieForm } from "../components";
import { useIntakeFormContext } from "../context";
import { useJourneyRequirements } from "../hooks";
import { INTAKE_FORM_ROUTE } from "../router";

export const AssessmentForm = () => {
  const { patient, isAsync, videoConsultationSchedule, subscription } =
    useIntakeFormContext();

  const navigate = useNavigate();

  const setAppointmentMutation = useScheduleAppointmentMutation();

  const { hasPreviousRequestFilter } = useJourneyRequirements();

  if (isAsync === undefined || !subscription?.product.id) {
    return <Navigate to={ROUTES.BASE} />;
  }

  const handleBack = () => {
    if (!isAsync) {
      return navigate(INTAKE_FORM_ROUTE.SCHEDULE_VIDEO_CONSULTATION);
    }

    if (hasPreviousRequestFilter) {
      return navigate(INTAKE_FORM_ROUTE.CHOOSE_JOURNEY);
    }

    return navigate(INTAKE_FORM_ROUTE.MEDICATION_REQUEST);
  };

  const onSubmit = () => {
    if (!isAsync && videoConsultationSchedule) {
      // TODO: how to manage the onSuccess, onError callbacks?
      setAppointmentMutation.mutate(
        videoConsultationSchedule,
        // { onSettled: goToSuccessScreen },
      );
    }

    navigate(INTAKE_FORM_ROUTE.SUCCESS);
  };

  return (
    <ScreenLayout
      hasCardHeader={false}
      title={t`Intake form questions`}
      description={t`(*) Mandatory field`}
    >
      <HealthieForm
        onSubmit={onSubmit}
        params={{
          patientId: patient.id,
          isAsync,
          isRefill: false,
          withRefill: isAsync,
          productId: subscription.product.id,
          gender: patient.gender,
        }}
        className="self-center"
      >
        <div className="flex w-full flex-col-reverse items-center gap-y-4 sm:flex-row sm:justify-between">
          <Button
            onClick={handleBack}
            variant="secondary"
            size="lg"
            className="w-full sm:w-fit"
          >
            <AltArrowLeftIcon className="hidden size-4 sm:block" />
            {t`Back`}
          </Button>

          <Button type="submit" size="lg" className="w-full sm:w-fit">
            {t`Next`}
            <AltArrowRightIcon className="hidden size-4 sm:block" />
          </Button>
        </div>
      </HealthieForm>

      {setAppointmentMutation.isPending && <FullScreenLoader />}
    </ScreenLayout>
  );
};
