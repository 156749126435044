import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { BILLING_ROUTE } from "@/domains/billing/router";
import { DollarMinimalisticIcon, Header, HeartIcon } from "@/ui";

export const ExploreSubscriptionsHeader = () => {
  const navigate = useNavigate();

  return (
    <Header.Root variant="secondary">
      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: BILLING_ROUTE.BASE,
              icon: DollarMinimalisticIcon,
              label: t`Billing`,
            },
            {
              href: BILLING_ROUTE.EXPLORE_SUBSCRIPTIONS,
              icon: HeartIcon,
              label: t`Explore subscriptions`,
            },
          ]}
        />

        <div className="flex items-center gap-2">
          <Header.GoBackButton
            onClick={() => navigate(BILLING_ROUTE.BASE)}
            className="md:hidden"
          />
          <Header.Title>{t`Explore subscriptions`}</Header.Title>
        </div>
      </Header.Content>
    </Header.Root>
  );
};
