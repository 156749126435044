import { t } from "ttag";
import { z } from "zod";

import { productSchema } from "@/domains/products/models";
import type { InferSchema } from "@/shared.types";

export const getSubscriptionSchema = () =>
  z.object({
    productPriceId: productSchema.shape.prices.element.shape.id,

    hasConsent: z.literal<boolean>(true, {
      errorMap: () => ({
        message: t`To continue, please check the "I agree" box`,
      }),
    }),
  });

export type SubscriptionFormValues = InferSchema<typeof getSubscriptionSchema>;
