import { t } from "ttag";

import { OnboardingHeader } from "@/domains/onboarding/components";
import { UserForm } from "@/domains/onboarding/sections";

export const UserInfo = () => {
  return (
    <>
      <OnboardingHeader
        title={t`Your contact info`}
        description={t`(*) Mandatory field`}
      />
      <UserForm />
    </>
  );
};
