import { t } from "ttag";

import type { DowngradeSubscription } from "@/domains/billing/models";
import { DetailCard, Label, PillIcon, Skeleton } from "@/ui";

interface NewSubscriptionCardProps {
  newSubscription?: DowngradeSubscription;
}

export const NewSubscriptionCard = ({
  newSubscription,
}: NewSubscriptionCardProps) => {
  if (!newSubscription) {
    return null;
  }

  return (
    <DetailCard.Root
      variant="borderLess"
      className="border border-brown-05 bg-transparent"
    >
      <div className="flex gap-1">
        <Label
          htmlFor="new-period-plan"
          className="inline-flex items-center gap-1 text-brown-07"
        >
          <PillIcon className="size-4" />
          {t`Changing to:`}
        </Label>

        {newSubscription ? (
          <DetailCard.Description
            id="new-period-plan"
            className="flex items-center gap-1.5 font-medium"
          >
            {newSubscription?.name}{" "}
            <span className="text-xs text-brown-05">|</span>
            <span>{newSubscription?.price.label}</span>
          </DetailCard.Description>
        ) : (
          <Skeleton className="h-3 w-28 self-center" />
        )}
      </div>
    </DetailCard.Root>
  );
};
