import type { ComponentPropsWithoutRef } from "react";
import type { Control } from "react-hook-form";
import { t } from "ttag";

import { Button, DollarMinimalisticIcon, Form, Input, Loader } from "@/ui";

interface FieldValue {
  couponCode?: string;
}

type CouponCodeControl<T> = T extends FieldValue
  ? Control<T, "couponCode">
  : never;

type CouponCodeFieldProps<T extends FieldValue> = ComponentPropsWithoutRef<
  typeof Form.Item
> & {
  control: CouponCodeControl<T>;
  onApply: () => void;
  disabled?: boolean;
  busy?: boolean;
};
export const CouponCodeField = <T extends FieldValue>({
  control,
  onApply,
  disabled,
  busy,
  ...props
}: CouponCodeFieldProps<T>) => {
  return (
    <Form.Field
      control={control}
      name="couponCode"
      render={({ field }) => (
        <Form.Item {...props}>
          <Form.Label>{t`Coupon code`}</Form.Label>

          <div className="flex gap-2">
            <Form.Control>
              <Input
                {...field}
                placeholder={t`Optional coupon code`}
                left={<DollarMinimalisticIcon />}
                containerClassName="grow"
              />
            </Form.Control>

            <Button size="lg" onClick={onApply} disabled={disabled}>
              {t`Apply`}
              {busy && <Loader />}
            </Button>
          </div>

          <Form.Message compact={false} />
        </Form.Item>
      )}
    />
  );
};
