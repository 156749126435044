import { useLocation } from "react-router-dom";

import {
  DURATION_IN_MONTHS,
  subscriptionSchema,
} from "@/domains/billing/models";
import { SENIOR_AGE_THRESHOLD } from "@/shared.constants";
import type { AppointmentRequiredState } from "@/shared/models";
import { APPOINTMENT_REQUIRED_STATES } from "@/shared/models";
import { calculateAge } from "@/utils";
import { useIntakeFormContext } from "../context";

export const usePatientConditions = () => {
  const location = useLocation();
  const parsedSubscription = subscriptionSchema.safeParse(location.state);

  const { patient, subscription } = useIntakeFormContext();

  const userAge = patient?.dateOfBirth ? calculateAge(patient?.dateOfBirth) : 0;
  const state = patient?.location?.state;

  const isOverAge = userAge >= SENIOR_AGE_THRESHOLD;

  const hasAppointmentRequiredState =
    !state ||
    APPOINTMENT_REQUIRED_STATES.includes(state as AppointmentRequiredState);

  const hasBundleSubscription = parsedSubscription.success
    ? parsedSubscription.data.price.durationInMonths ===
      DURATION_IN_MONTHS.QUARTERLY
    : subscription?.price.durationInMonths === DURATION_IN_MONTHS.QUARTERLY;

  return { isOverAge, hasAppointmentRequiredState, hasBundleSubscription };
};
