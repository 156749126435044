import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { onboardingQueriesStore } from "@/domains/onboarding/api";
import type { CategoryWithSelectedProduct } from "@/domains/products/models";
import type { Answer } from "@/shared/models";

interface UseEligibilityQuestionsParams {
  selectedCategories: CategoryWithSelectedProduct[];
  onTotalDQ: () => void;
  onPartialDQ: () => void;
  onNoDQ: () => void;
}

export const useEligibilityQuestions = ({
  selectedCategories,
  onTotalDQ,
  onPartialDQ,
  onNoDQ,
}: UseEligibilityQuestionsParams) => {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [disqualifiedCategories, setDisqualifiedCategories] = useState<
    CategoryWithSelectedProduct[]
  >([]);

  const currentCategory = selectedCategories[currentCategoryIndex];

  const { data: questions, isLoading } = useQuery(
    onboardingQueriesStore.onboarding.filteringQuestions._ctx.category(
      currentCategory?.id,
    ),
  );

  useEffect(() => {
    if (questions?.length === 0) {
      moveToNextCategory(disqualifiedCategories);
    }
  }, [questions]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAnswers = (answers: Answer["id"][]) => {
    if (!questions || !answers.length) {
      return;
    }

    const currentQuestion = questions[currentQuestionIndex];

    const isDisqualified = answers.some((answer) => {
      const selectedAnswer = currentQuestion?.onboardingAnswers.find(
        (a) => a.id === answer,
      );
      return selectedAnswer?.disqualifier;
    });

    if (isDisqualified) {
      if (currentCategory) {
        const updatedDisqualifiedCategories = [
          ...disqualifiedCategories,
          currentCategory,
        ];
        setDisqualifiedCategories(updatedDisqualifiedCategories);
        return moveToNextCategory(updatedDisqualifiedCategories);
      }
    }

    if (currentQuestionIndex + 1 < questions.length) {
      setCurrentQuestionIndex((prev) => prev + 1);
    } else {
      moveToNextCategory(disqualifiedCategories);
    }
  };

  const moveToNextCategory = (dqCategories: CategoryWithSelectedProduct[]) => {
    if (currentCategoryIndex + 1 < selectedCategories.length) {
      setCurrentCategoryIndex((prev) => prev + 1);
      setCurrentQuestionIndex(0);
    } else {
      if (dqCategories.length === 0) {
        return onNoDQ();
      }
      if (dqCategories.length === selectedCategories.length) {
        return onTotalDQ();
      }
      return onPartialDQ();
    }
  };

  return {
    currentQuestion: questions?.[currentQuestionIndex],
    disqualifiedCategories,
    isLoading,
    handleAnswers,
  };
};
