import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { t } from "ttag";
import { z } from "zod";

import { categorySchema } from "@/domains/products/models";
import { useOnboardingStore } from "../stores";

export const useCategoriesForm = () => {
  const selectedCategories = useOnboardingStore(
    (state) => state.selectedCategories,
  );

  const formSchema = z.object({
    categoryIds: z
      .array(categorySchema.shape.id)
      .refine((value) => value.some((plan) => plan), {
        message: t`Please choose a plan before continuing.`,
      }),
  });

  return useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      categoryIds: selectedCategories.map((category) => category.id),
    },
  });
};
