import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "ttag";

import { HookedCreditCardInput } from "@/components";
import { useValidateCouponCode } from "@/domains/billing/hooks";
import type { CheckoutFormValues } from "@/domains/onboarding/models";
import { useOnboardingStore } from "@/domains/onboarding/stores";
import { getPricesIds } from "@/domains/onboarding/utils";
import {
  CouponCodeField,
  CreditCardAuthorizationField,
} from "@/shared/components";
import { getCouponCode } from "@/utils";

export const PaymentFieldset = () => {
  const selectedCategories = useOnboardingStore(
    (state) => state.selectedCategories,
  );
  const setCoupon = useOnboardingStore((state) => state.setCoupon);

  const form = useFormContext<CheckoutFormValues>();

  const validateCouponCodeMutation = useValidateCouponCode();

  useEffect(() => {
    const rewardfulCoupon = getCouponCode();
    if (rewardfulCoupon) {
      form.setValue("couponCode", rewardfulCoupon);
      handleValidateCouponCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValidateCouponCode = () => {
    const couponCode = form.getValues("couponCode");
    const pricesIds = getPricesIds(selectedCategories);

    if (!couponCode || !pricesIds.length) {
      return;
    }

    validateCouponCodeMutation.mutate(
      { couponCode, pricesIds },
      {
        onSuccess: (coupon) => {
          if (!coupon.isValid) {
            form.setError("couponCode", {
              message: t`Invalid coupon. Please check and try again.`,
            });
          } else {
            form.clearErrors("couponCode");
          }

          setCoupon(coupon);
        },
        onError: (error) => {
          form.setError("couponCode", {
            message: error.message,
          });
        },
      },
    );
  };

  return (
    <fieldset className="flex flex-col gap-6">
      <h3 className="text-lg font-bold text-brown-09">{t`Payment method`}</h3>

      <div className="grid gap-x-8 gap-y-1.5 md:grid-cols-2">
        <div className="flex flex-col gap-2">
          <HookedCreditCardInput
            id="onboarding-cc-number"
            name="creditCard"
            label={t`Card number *`}
            control={form.control}
            error={form.formState.errors.creditCard?.message}
            className="bg-salmon-01 p-3.5"
            compact={true}
          />

          <CreditCardAuthorizationField control={form.control} />
        </div>

        <CouponCodeField
          control={form.control}
          onApply={handleValidateCouponCode}
          disabled={
            validateCouponCodeMutation.isPending || !form.watch("couponCode")
          }
          busy={validateCouponCodeMutation.isPending}
        />
      </div>
    </fieldset>
  );
};
