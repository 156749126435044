import { parseAsStringEnum, useQueryState } from "nuqs";

import { CATEGORY_SLUG } from "@/domains/products/models";

const categories = Object.values(CATEGORY_SLUG);

export const useProductCategory = () => {
  const [category, setCategory] = useQueryState(
    "category",
    parseAsStringEnum(categories).withDefault(CATEGORY_SLUG.WEIGHT_LOSS),
  );

  return { category, actions: { setCategory } };
};
