import { useEffect } from "react";
import { parseAsString, useQueryStates } from "nuqs";

import { useOnboardingStore } from "@/domains/onboarding/stores";

export const useSetAnalyticsQueryParamData = () => {
  const [queryParams] = useQueryStates({
    utm_campaign: parseAsString,
    cio_id: parseAsString,
  });

  const { utm_campaign: utmCampaign, cio_id: analyticsCustomerId } =
    queryParams;

  const setUtmCampaign = useOnboardingStore((state) => state.setUtmCampaign);
  const setAnalyticsCustomerId = useOnboardingStore(
    (state) => state.setAnalyticsCustomerId,
  );

  useEffect(() => {
    if (utmCampaign) {
      setUtmCampaign(utmCampaign);
    }
    if (analyticsCustomerId) {
      setAnalyticsCustomerId(analyticsCustomerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
