import { useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { subscriptionSchema } from "@/domains/billing/models";
import { CATEGORY_SLUG } from "@/domains/products/models";
import { ROUTES } from "@/router";
import { useIntakeFormContext } from "../context";
import { INTAKE_FORM_ROUTE } from "../router";
import { usePatientConditions } from "./usePatientConditions";

export const useInitialSubscription = () => {
  const location = useLocation();
  const parsedState = subscriptionSchema.safeParse(location.state);
  const { hasAppointmentRequiredState } = usePatientConditions();

  const {
    subscription,
    actions: { setSubscription, setIsAsync },
  } = useIntakeFormContext();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (parsedState.error && !subscription) {
      return navigate(ROUTES.BASE);
    }

    if (parsedState.success) {
      setSubscription(parsedState.data);

      if (
        parsedState.data.product.category.slug === CATEGORY_SLUG.HAIR_GROWTH &&
        !hasAppointmentRequiredState
      ) {
        setIsAsync(true);
        navigate(INTAKE_FORM_ROUTE.ASSESSMENT_FORM);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return parsedState.data ?? subscription;
};
