import { useMutation, useQueryClient } from "@tanstack/react-query";

import { STRIPE_DOMAIN } from "@/api";
import { updatePaymentMethod } from "../api";

export const useUpdatePaymentMethod = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePaymentMethod,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [STRIPE_DOMAIN] });
    },
  });
};
