import { t } from "ttag";

import type { Consultation } from "@/shared.types";
import {
  icons,
  IconWrapper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/ui";
import { formatBackendDate, formatConsultationTime, tw } from "@/utils";

interface PastConsultationsTableProps {
  consultations: Consultation[];
  setDocumentId: (documentId: Consultation["documentId"]) => void;
}

export const PastConsultationsTable = ({
  consultations,
  setDocumentId,
}: PastConsultationsTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t`Provider`}</TableHead>
          <TableHead>{t`Date`}</TableHead>
          <TableHead>{t`Time`}</TableHead>
          <TableHead className="w-44">{t`Status`}</TableHead>
          <TableHead className="w-24">{t`Notes`}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {consultations.map((consultation) => (
          <TableRow key={`past-consultation-${consultation.id}`}>
            <TableCell>{consultation.provider.fullName}</TableCell>
            <TableCell>{formatBackendDate(consultation.startTime)}</TableCell>
            <TableCell>
              {`${formatConsultationTime(
                consultation.startTime,
              )} - ${formatConsultationTime(consultation.endTime)} ${
                consultation.timezone
              }`}
            </TableCell>
            <TableCell
              className={tw(
                "font-bold",
                consultation.pmStatus === "Cancelled" && "text-red-07",
              )}
            >
              {consultation.pmStatus}
            </TableCell>
            <TableCell>
              {consultation.documentId && (
                <IconWrapper
                  size="xl"
                  className="cursor-pointer rounded-full bg-salmon-06 fill-nature-01 p-2"
                >
                  <icons.Document
                    onClick={() => setDocumentId(consultation.documentId)}
                  />
                </IconWrapper>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
