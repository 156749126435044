import { useMutation } from "@tanstack/react-query";

import { errorToast } from "@/ui";
import { convert } from "@/utils";
import { createHealthieUser } from "../api";
import type { UseCheckoutParams } from "../models";
import { useOnboardingStore } from "../stores";

export const useCreateHealthieUser = ({
  user,
  coupon,
}: Omit<UseCheckoutParams, "selectedCategories">) => {
  const setHealthieUserToken = useOnboardingStore(
    (state) => state.setHealthieUserToken,
  );

  return useMutation({
    mutationFn: createHealthieUser,
    onSuccess: ({ token }) => {
      setHealthieUserToken(token);

      if (!coupon?.isValid && user) {
        convert(user.email);
      }
    },
    onError: errorToast,
  });
};
