import { z } from "zod";

import { privateAPI } from "@/api";
import type { ServiceResponse } from "@/api";
import { optionSchema } from "../models";
import type { Option } from "../models";

export const getStateOptions = async () => {
  const { data } = await privateAPI.get<ServiceResponse<Option[]>>(`/states`);

  return z.array(optionSchema).parse(data.data);
};
