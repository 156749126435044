import { zodResolver } from "@hookform/resolvers/zod";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useExploreSubscriptionsContext } from "@/domains/billing/context";
import {
  ProductCard,
  ProductItem,
  ProductList,
} from "@/domains/products/components";
import { productSchema } from "@/domains/products/models";
import type { Product } from "@/domains/products/models";
import { Form, showcaseCardVariants } from "@/ui";

const radioGroupItemClassName = showcaseCardVariants().clickableRoot();

interface ProductSelectionProps {
  products: Product[];
}

const formSchema = z.object({
  productId: productSchema.shape.id,
});

export const ProductSelection = ({ products }: ProductSelectionProps) => {
  const {
    categories,
    actions: { addProduct },
  } = useExploreSubscriptionsContext();

  const currentCategoryId = products[0]?.category.id;

  const productId = categories.find(
    (category) => category.id === currentCategoryId,
  )?.selectedProduct?.id;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { productId },
  });

  const handleProductSelect = (productId: Product["id"]) => {
    const selectedProduct = products.find(
      (product) => product.id === productId,
    );

    if (selectedProduct) {
      addProduct(selectedProduct);
    }
  };

  return (
    <Form.Provider {...form}>
      <Form.Field
        control={form.control}
        name="productId"
        render={({ field: { value, onChange, ...rest } }) => (
          <RadioGroup.Root
            {...rest}
            defaultValue={String(value)}
            onValueChange={(v) => {
              onChange(v);
              handleProductSelect(Number(v));
            }}
            className="grow"
          >
            <ProductList>
              {products.map((product, _, array) => (
                <ProductItem key={product.id} length={array.length}>
                  <RadioGroup.Item
                    value={String(product.id)}
                    className={radioGroupItemClassName}
                    disabled={product.prices.some(
                      (price) => price.isSubscribed,
                    )}
                  >
                    <ProductCard product={product} />
                  </RadioGroup.Item>
                </ProductItem>
              ))}
            </ProductList>
          </RadioGroup.Root>
        )}
      />
    </Form.Provider>
  );
};
