import type { ServiceResponse } from "@/api";
import { publicAPI } from "@/api";
import type { Price, Product } from "@/domains/products/models";

interface NorthbeamSubscriptionPayload {
  orderId: string;
  customerId: string;
  purchaseTotal: number;
  products: {
    id: Price["subscriptionPriceId"];
    name: Product["name"];
    price: Price["price"];
    quantity: number;
  }[];
}

export const northbeamInformNewSubscription = async (
  payload: NorthbeamSubscriptionPayload,
) => {
  return await publicAPI.post<ServiceResponse<void>>(
    "/subscriptions/inform-new",
    payload,
  );
};
