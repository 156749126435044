import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { CheckboxIndicator } from "./CheckboxIndicator";

const checkboxVariants = tv({
  slots: {
    root: "mt-0.5 rounded-[0.25rem] focus:outline-none focus:ring-1 focus:ring-nature-08 disabled:cursor-not-allowed disabled:opacity-50",
  },
  variants: {
    variant: { secondary: "bg-transparent" },
  },
});

type CheckboxVariants = VariantProps<typeof checkboxVariants>;

const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & CheckboxVariants
>(({ className, variant, ...props }, ref) => {
  const { root } = checkboxVariants({ className, variant });
  return (
    <CheckboxPrimitive.Root ref={ref} className={root()} {...props}>
      {/* TODO: Update CheckboxIndicator with tv */}
      <CheckboxIndicator variant={variant} />
    </CheckboxPrimitive.Root>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
