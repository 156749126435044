import type { ServiceResponse } from "@/api";
import { privateAPI, publicAPI, STRIPE_DOMAIN } from "@/api";
import type { Payment, PaymentMethod } from "@/shared.types";
import type { UpdatePaymentMethodParams } from "../models";

// TODO: add to query store
export const getPaymentHistoryQuery = (lastPayment: string | undefined) => ({
  queryKey: [STRIPE_DOMAIN, "getPaymentHistoryQuery", lastPayment],
  queryFn: async () => {
    const response = await privateAPI.get<ServiceResponse<Payment[]>>(
      `/patients/payment-information`,
      { params: { startAfter: lastPayment } },
    );

    return response.data;
  },
});

// // TODO: add to query store
export const getPaymentMethodQuery = () => ({
  queryKey: [STRIPE_DOMAIN, "getPaymentMethodQuery"],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<PaymentMethod>>(
      `/patients/payment-method`,
    );

    return data.data;
  },
});

export const updatePaymentMethod = async ({
  paymentMethodId,
  userCode,
}: UpdatePaymentMethodParams) => {
  const { data } = await publicAPI.put<ServiceResponse<void>>(
    `/users/payment-method/${userCode}`,
    {
      paymentMethodId,
    },
  );

  return data.data;
};
