import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";
import { z } from "zod";

import { ErrorModal } from "@/modals";
import { ROUTES } from "@/router";
import type { ModalProps } from "@/shared.types";
import { Button, Dialog, Form, PillsFixedIcon, RadioGroupCards } from "@/ui";
import { useAvailableRefillRequests } from "../hooks";
import { MY_MEDS_ROUTE } from "../router";
import { RefillOptionsRadioGroup } from "./RefillOptionsRadioGroup";

export const SelectRefillModal = ({ show, onClose }: ModalProps) => {
  const {
    data: availableRefillRequests,
    isSuccess,
    isLoading,
    isError,
  } = useAvailableRefillRequests();

  const formSchema = z.object({
    requestProductSlug: z
      .string()
      .min(1, { message: t`Please select a refillable product to proceed` }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const navigate = useNavigate();

  const onSubmit = form.handleSubmit(({ requestProductSlug }) => {
    const selectedRequest = availableRefillRequests?.find(
      (request) => request.product.slug === requestProductSlug,
    );

    if (selectedRequest) {
      navigate(MY_MEDS_ROUTE.REFILL_REQUEST, {
        state: selectedRequest.product,
      });
    }
  });

  const canConfirmAndContinue = availableRefillRequests?.some(
    (availableRefillRequest) =>
      availableRefillRequest.isRefillable &&
      !availableRefillRequest.hasUpcomingAppointment,
  );

  if (isError) {
    return <ErrorModal show={show} onClose={onClose} />;
  }

  return (
    <Form.Provider {...form}>
      <Dialog.Root open={show} onOpenChange={onClose}>
        <Dialog.Content size="modal">
          <form onSubmit={onSubmit} className="flex h-full flex-col gap-6">
            <Dialog.Header>
              <Dialog.Icon>
                <PillsFixedIcon />
              </Dialog.Icon>

              <Dialog.Title>{t`Need a refill?`}</Dialog.Title>

              <Dialog.Description>
                {t`You can only pick one med at a time. Once you're done, you can come back and grab another if you need to!`}
              </Dialog.Description>
            </Dialog.Header>

            {isLoading && <RadioGroupCards.Skeleton />}

            {isSuccess && (
              <Form.Field
                control={form.control}
                defaultValue=""
                name="requestProductSlug"
                render={({ field: { onChange, ...field } }) => (
                  <Form.Item>
                    <Form.Control>
                      <RefillOptionsRadioGroup
                        onValueChange={onChange}
                        refillRequests={availableRefillRequests}
                        {...field}
                      />
                    </Form.Control>
                    <Form.Message compact={false} />
                  </Form.Item>
                )}
              />
            )}

            <Dialog.Footer variant="sticky">
              {isError ? (
                <Button
                  onClick={() => navigate(ROUTES.MESSAGES)}
                  size="lg"
                  className="justify-self-end"
                >
                  {t`Contact support`}
                </Button>
              ) : (
                <>
                  <Button
                    size="lg"
                    variant="secondary"
                    onClick={onClose}
                  >{t`Cancel request`}</Button>
                  <Button
                    type="submit"
                    size="lg"
                    disabled={!canConfirmAndContinue}
                  >
                    {t`Confirm and continue`}
                  </Button>
                </>
              )}
            </Dialog.Footer>
          </form>
        </Dialog.Content>
      </Dialog.Root>
    </Form.Provider>
  );
};
