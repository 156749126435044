import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { SubscriptionDowngradeModal } from "@/domains/billing/components";
import { useBoolean } from "@/hooks";
import { ScreenLayout } from "@/layouts";
import { ROUTES } from "@/router";
import { AltArrowLeftIcon, AltArrowRightIcon, Button } from "@/ui";
import { useIntakeFormContext } from "../context";
import { usePatientConditions } from "../hooks";
import { INTAKE_FORM_ROUTE } from "../router";

export const JourneyRestricted = () => {
  const { isOverAge, hasBundleSubscription } = usePatientConditions();

  const {
    subscription,
    actions: { setIsAsync },
  } = useIntakeFormContext();

  const {
    value: showSubscriptionDowngradeModal,
    setTrue: openSubscriptionDowngradeModal,
    setFalse: closeSubscriptionDowngradeModal,
  } = useBoolean(false);

  const navigate = useNavigate();

  const handleContinue = () => {
    setIsAsync(false);
    navigate(INTAKE_FORM_ROUTE.SCHEDULE_VIDEO_CONSULTATION);
  };

  const handleNext = () => {
    if (isOverAge && hasBundleSubscription) {
      return openSubscriptionDowngradeModal();
    }

    handleContinue();
  };

  if (!subscription) {
    return null;
  }

  return (
    <ScreenLayout
      hasCardHeader={false}
      title={t`Begin your journey`}
      variant="nature"
      className="flex h-full flex-col items-center gap-14"
    >
      <div className="flex w-full max-w-xl grow flex-col gap-6">
        <div className="flex flex-col gap-5 text-center text-nature-02 sm:text-xl">
          <p>
            {t`Unlock the door to your health journey with our platform. Schedule your inaugural video call with a medical professional, marking the beginning of your personalized treatment plan. Let's embark together on this path towards improved well-being and vitality.`}
          </p>
        </div>
      </div>

      <div className="mt-auto flex w-full flex-col-reverse items-center gap-y-4 sm:flex-row sm:justify-between">
        <Button
          onClick={() => navigate(ROUTES.BASE)}
          variant="cover-secondary"
          size="lg"
        >
          <AltArrowLeftIcon className="hidden size-4 sm:block" />
          {t`Back`}
        </Button>

        <Button onClick={handleNext} size="lg" variant="cover-primary">
          {t`Next`}
          <AltArrowRightIcon className="hidden size-4 sm:block" />
        </Button>
      </div>

      <SubscriptionDowngradeModal
        subscriptionId={subscription.id}
        show={showSubscriptionDowngradeModal}
        onClose={closeSubscriptionDowngradeModal}
        onContinue={handleContinue}
      />
    </ScreenLayout>
  );
};
