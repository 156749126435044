import { jt, t } from "ttag";

import { FullScreenAlert, HeartsCircleFixedIconMono } from "@/ui";

interface DQWithCoachingOptionProps {
  open: boolean;
  onGoBack: () => void;
}

export const DQWithCoachingOption = ({
  open,
  onGoBack,
}: DQWithCoachingOptionProps) => {
  const fridaysCoachingStrong = (
    <strong key="fridays-coaching-age-dq">{t`Fridays Coaching`}</strong>
  );

  const handleExploreFridaysCoaching = () => {
    console.log("TODO: define coaching flow");
  };

  return (
    <FullScreenAlert.Root open={open}>
      <FullScreenAlert.Content>
        <FullScreenAlert.Header>
          <HeartsCircleFixedIconMono className="size-16 md:size-20" />
          <FullScreenAlert.Title>
            {t`It appears that Fridays treatments may not be the best fit for you at the moment`}
          </FullScreenAlert.Title>
        </FullScreenAlert.Header>

        <FullScreenAlert.Description>
          {jt`Your wellness journey is personal and unique. While Fridays treatments may not align with your current needs, if weight loss is a focus, ${fridaysCoachingStrong} could be a perfect fit. Our personalized programs are designed to support your goals with expert guidance and sustainable strategies.`}
        </FullScreenAlert.Description>

        <FullScreenAlert.Footer>
          <FullScreenAlert.Cancel onClick={onGoBack}>
            {t`Go back`}
          </FullScreenAlert.Cancel>

          <FullScreenAlert.Action onClick={handleExploreFridaysCoaching}>
            {t`Explore Fridays Coaching`}
          </FullScreenAlert.Action>
        </FullScreenAlert.Footer>
      </FullScreenAlert.Content>
    </FullScreenAlert.Root>
  );
};
