import { z } from "zod";

import { privateAPI } from "@/api";
import type { ServiceResponse } from "@/api";
import type { PaginationParams } from "@/shared/models";
import { getAdminSessionSchema } from "../models";
import type { AdminSession, CreateSession, SessionsFilter } from "../models";

const ADMIN_SESSION_ENDPOINT = "/coaching-admin/sessions";

export const createSession = async (session: CreateSession) => {
  const { data } = await privateAPI.post<ServiceResponse<AdminSession>>(
    ADMIN_SESSION_ENDPOINT,
    session,
  );

  return data.data;
};

export const getAdminSession = async (id: AdminSession["id"]) => {
  const { data } = await privateAPI.get<ServiceResponse<AdminSession>>(
    `${ADMIN_SESSION_ENDPOINT}/${id}`,
  );

  const parsedData = getAdminSessionSchema()
    .omit({ coach: true })
    .safeParse(data.data);

  if (parsedData.success) {
    return parsedData.data;
  } else {
    throw new Error(parsedData.error.name);
  }
};

interface GetAdminSessionsParams {
  filter: SessionsFilter;
  pagination?: PaginationParams;
}

export const getAdminSessions = async ({
  filter,
  pagination,
}: GetAdminSessionsParams) => {
  const { data } = await privateAPI.get<ServiceResponse<AdminSession[]>>(
    ADMIN_SESSION_ENDPOINT,
    { params: { filter, ...pagination } },
  );

  const parsedData = z.array(getAdminSessionSchema()).safeParse(data.data);

  if (parsedData.success) {
    return { ...data, data: parsedData.data };
  } else {
    throw new Error(parsedData.error.name);
  }
};

export const updateSession = async (session: Omit<AdminSession, "coach">) => {
  const { id, ...values } = session;
  const { data } = await privateAPI.put<ServiceResponse<AdminSession>>(
    `${ADMIN_SESSION_ENDPOINT}/${id}`,
    values,
  );

  return data.data;
};

export const deleteSession = async (id: AdminSession["id"]) => {
  const { data } = await privateAPI.delete<ServiceResponse<null>>(
    `${ADMIN_SESSION_ENDPOINT}/${id}`,
  );

  return data.data;
};
