import { z } from "zod";

import { privateAPI } from "@/api";
import type { ServiceResponse } from "@/api";
import type { PaginationParams } from "@/shared/models";
import { getSessionSchema } from "../models";
import type { Session, SessionsFilter } from "../models";

const SESSION_ENDPOINT = "/sessions";

export const getSession = async (id: Session["id"]) => {
  const { data } = await privateAPI.get<ServiceResponse<Session>>(
    `${SESSION_ENDPOINT}/${id}`,
  );

  const parsedData = getSessionSchema()
    .omit({ coach: true })
    .safeParse(data.data);

  if (parsedData.success) {
    return parsedData.data;
  } else {
    throw new Error(parsedData.error.name);
  }
};

interface GetSessionParams {
  filter: SessionsFilter;
  pagination?: Partial<PaginationParams>;
}

export const getSessions = async ({ filter, pagination }: GetSessionParams) => {
  const { data } = await privateAPI.get<ServiceResponse<Session[]>>(
    SESSION_ENDPOINT,
    { params: { filter, ...pagination } },
  );

  const parsedData = z.array(getSessionSchema()).safeParse(data.data);

  if (parsedData.success) {
    return { ...data, data: parsedData.data };
  } else {
    throw new Error(parsedData.error.name);
  }
};

export const subscribeToSession = async (id: Session["id"]) => {
  const { data } = await privateAPI.post<ServiceResponse<Session>>(
    `${SESSION_ENDPOINT}/${id}/subscription`,
  );

  return data.data;
};

export const getUpcomingSessions = async () => {
  const { data } = await privateAPI.get<ServiceResponse<Session[]>>(
    `${SESSION_ENDPOINT}/subscribed`,
  );

  const parsedData = z
    .array(
      getSessionSchema().omit({
        isSubscribable: true,
        reasonToBlockSubscription: true,
      }),
    )
    .safeParse(data.data);

  if (parsedData.success) {
    return parsedData.data;
  } else {
    throw new Error(parsedData.error.name);
  }
};

export const unsubscribeToSession = async (id: Session["id"]) => {
  const { data } = await privateAPI.delete<ServiceResponse<Session>>(
    `${SESSION_ENDPOINT}/${id}/subscription`,
  );

  return data.data;
};
