import { useNavigate } from "react-router-dom";

import { useSetAnalyticsQueryParamData } from "@/domains/legacy-onboarding/hooks";
import { useUserAttributesForm } from "@/domains/onboarding//hooks";
import { NavigationButtons } from "@/domains/onboarding/components";
import { ONBOARDING_ROUTE } from "@/domains/onboarding/router";
import { useOnboardingStore } from "@/domains/onboarding/stores";
import { useBoolean } from "@/hooks";
import { ROUTES } from "@/router";
import { AgeGroupField, GenderField, StateField } from "@/shared/components";
import { AGE_GROUP, WEIGHT_LOSS_DQ_STATES } from "@/shared/models";
import { Form } from "@/ui";
import { DQByAge } from "./DQByAge";
import { WeightLossDQModal } from "./WeightLossDQModal";

export const MainInfoForm = () => {
  const setUserAttributes = useOnboardingStore(
    (state) => state.setUserAttributes,
  );

  useSetAnalyticsQueryParamData();

  const {
    value: showDQAlert,
    setTrue: openDQAlert,
    setFalse: closeDQAlert,
  } = useBoolean(false);
  const {
    value: showWeightLossDQModal,
    setTrue: openWeightLossDQModal,
    setFalse: closeWeightLossDQModal,
  } = useBoolean(false);

  const form = useUserAttributesForm();

  const navigate = useNavigate();

  const handleContinue = form.handleSubmit((values) => {
    setUserAttributes(values);

    if (
      values.ageGroup === AGE_GROUP.YOUNGER_THAN_18 ||
      values.ageGroup === AGE_GROUP.OLDER_THAN_74
    ) {
      return openDQAlert();
    }

    if (WEIGHT_LOSS_DQ_STATES.has(values.state)) {
      return openWeightLossDQModal();
    }

    navigate(ONBOARDING_ROUTE.TREATMENT_CHOOSE_CATEGORIES);
  });

  const handleBack = () => {
    navigate(ROUTES.LOGIN);
  };

  return (
    <Form.Provider {...form}>
      <form className="flex grow flex-col gap-10 md:gap-12">
        <div className="grid grow content-start gap-x-8 gap-y-1.5 md:grid-cols-2">
          <StateField control={form.control} />

          <GenderField
            control={form.control}
            onGenderChange={() => {
              form.resetField("ageGroup");
            }}
          />

          <AgeGroupField control={form.control} className="col-span-full" />
        </div>

        <NavigationButtons onCancel={handleBack} onContinue={handleContinue} />
      </form>

      <DQByAge
        open={showDQAlert}
        ageGroup={form.watch("ageGroup")}
        onBack={closeDQAlert}
      />

      <WeightLossDQModal
        open={showWeightLossDQModal}
        onGoBack={closeWeightLossDQModal}
        onContinue={() =>
          navigate(ONBOARDING_ROUTE.TREATMENT_CHOOSE_CATEGORIES)
        }
      />
    </Form.Provider>
  );
};
