import type { MedicationRequest } from "@/domains/my-meds/models";
import {
  CalendarMinimalisticIcon,
  DocumentMedicineIcon,
  InfoCircleIcon,
  PlusIcon,
  TestTubeIcon,
} from "@/ui";
import { VideoCameraIcon } from "@/ui/icons/video";

const ICONS_MAP = {
  video: <VideoCameraIcon />,
  labs: <TestTubeIcon />,
  visit: <CalendarMinimalisticIcon />,
  request: <DocumentMedicineIcon />,
  expired: <CalendarMinimalisticIcon />,
  duplicate: <PlusIcon />,
} as const;
type IconWord = keyof typeof ICONS_MAP;

export const ResolutionIcon = ({
  resolution,
}: {
  resolution: MedicationRequest["resolution"];
}) => {
  for (const word in ICONS_MAP) {
    if (resolution?.toLowerCase().includes(word)) {
      return ICONS_MAP[word as IconWord];
    }
  }

  return <InfoCircleIcon />;
};
