import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { t } from "ttag";

import { FullScreenAlert } from "@/components";
import { HealthieForm } from "@/domains/intake-form/components";
import { medicationRequestQueriesStore } from "@/domains/my-meds/api";
import type { Product } from "@/domains/products/models";
import { useBoolean, usePatientQuery } from "@/hooks";
import { ROUTES } from "@/router";
import {
  AltArrowLeftIcon,
  AltArrowRightIcon,
  Button,
  illustratedIcons,
} from "@/ui";

interface RefillHealthieFormProps {
  productId: Product["id"];
  onCancel: () => void;
}
export const RefillHealthieForm = ({
  productId,
  onCancel,
}: RefillHealthieFormProps) => {
  const { value: showSuccessAlert, setTrue: openSuccessAlert } =
    useBoolean(false);

  const queryClient = useQueryClient();

  const { data: patient } = usePatientQuery();

  if (!patient?.id) {
    return null;
  }

  return (
    <>
      <HealthieForm
        onSubmit={openSuccessAlert}
        params={{
          isAsync: true,
          isRefill: true,
          withRefill: true,
          patientId: patient.id,
          gender: patient.gender,
          productId,
        }}
      >
        <div className="flex flex-col-reverse items-stretch justify-between gap-4 md:flex-row md:items-center">
          <Button onClick={onCancel} variant="secondary" size="lg">
            <AltArrowLeftIcon className="hidden size-4 md:block" />

            {t`Back`}
          </Button>
          <Button type="submit" size="lg">
            {t`Next`}
            <AltArrowRightIcon className="hidden size-4 md:block" />
          </Button>
        </div>
      </HealthieForm>

      {showSuccessAlert && (
        <FullScreenAlert
          customIcon={<illustratedIcons.Success />}
          title={t`Submission Successful!`}
          description={t`We have received your medication request. Please allow 2-3 business days for processing. If we need any more information, our team will be in touch!`}
          customAction={
            <Button
              asChild
              size="lg"
              className="md:w-fit"
              onClick={() =>
                void queryClient.invalidateQueries({
                  queryKey:
                    medicationRequestQueriesStore.medicationRequest._def,
                })
              }
            >
              <Link to={ROUTES.MY_MEDS}>{t`Back to home`}</Link>
            </Button>
          }
        />
      )}
    </>
  );
};
