import type { ServiceResponse } from "@/api";
import { privateAPI, publicAPI } from "@/api";
import type { Plan, PlansParams } from "../models";

export const getOnboardingPatientPlans = async (params: PlansParams) => {
  const { data } = await publicAPI.get<ServiceResponse<Plan[]>>(`/plans`, {
    params,
  });

  return data.data;
};

export const getCoachingPlans = async () => {
  const { data } =
    await privateAPI.get<ServiceResponse<Plan[]>>(`/coaching-plans`);

  return data.data;
};
