import type { CategoryWithSelectedProduct } from "@/domains/products/models";
import type { CustomerIoProduct } from "../models";

export const categoriesToCustomerIoProducts = (
  categories: CategoryWithSelectedProduct[],
): CustomerIoProduct[] => {
  return categories
    .map((category) => {
      if (!category.selectedProduct?.selectedPrice) {
        return;
      }

      const { subscriptionPriceId, durationInMonths, price } =
        category.selectedProduct.selectedPrice;

      return {
        productId: subscriptionPriceId,
        productCategory: category.slug,
        productName: category.selectedProduct?.slug,
        productFrequency: durationInMonths,
        price,
      };
    })
    .filter((product) => !!product);
};
